import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {makeStyles} from "@material-ui/core/styles";
import React, {useContext} from "react";
import {filterContext, SET_GROUP_BY} from "../../_context/filterContext";
import CompareIcon from "@material-ui/icons/Compare";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  compareButton: {
    position: "absolute",
    right: 5
  }
}));

const OrderListGroupBy = ({
  setIsActiveCompareTimeLabel,
  isActiveCompareTimeLabel = false,
  isActiveCompareTimeButton = false,
  setIsActiveCompareTimeButton,
  cleanDataCompare
}) => {
  const classes = useStyles();
  const {
    state: { groupBy },
    dispatch
  } = useContext(filterContext);

  const toggleActive = (value, e) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch({ type: SET_GROUP_BY, payload: value });
  };

  const buttons = groupBy.map(it => {
    return it.isActive ? (
      <Button
        variant="contained"
        onClick={e => toggleActive(it, e)}
        key={it.value}
        className={classes.button}
      >
        {it.name}
      </Button>
    ) : (
      <Button
        variant="outlined"
        onClick={e => toggleActive(it, e)}
        key={it.value}
        className={classes.button}
      >
        {it.name}
      </Button>
    );
  });

  return (
    <div className={classes.root}>
      <ButtonGroup
        color="primary"
        disableRipple
        aria-label="outlined primary button group"
      >
        {buttons}
      </ButtonGroup>
      <div className={classes.compareButton}>
        {isActiveCompareTimeButton ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsActiveCompareTimeButton(false);
              cleanDataCompare();
            }}
          >
            <CompareIcon />
            ZAKOŃCZ PORÓWNYWANIE
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsActiveCompareTimeLabel(prevState => !prevState);
            }}
          >
            <CompareIcon />
            Porównaj
          </Button>
        )}
      </div>
    </div>
  );
};

export default OrderListGroupBy;
