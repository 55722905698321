import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import SortRoundedIcon from "@material-ui/icons/SortRounded";
import {Tooltip} from "@material-ui/core";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    disableRipple: true
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.border}`,
    boxShadow: "-6px 0 23px 0 rgba(90, 90, 90, 0.25)",
    height: `calc(100vh - ${theme.headerHeight})`,
    position: "fixed",
    backgroundColor: theme.palette.common.white
  },
  tab: {
    minWidth: theme.leftMenuWidth,
    borderBottom: `1px solid ${theme.palette.border}`,
    "&:hover": {
      backgroundColor: theme.palette.darkGray
    }
  },
  tabIcon: {
    zIndex: "9999"
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: "5px",
    borderBottom: `1px solid ${theme.palette.border}`,
    textTransform: "uppercase",
    fontWeight: 400
  }
}));

export default function LeftMenu({ value, handleChange }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="left menu"
        className={classes.tabs}
        classes={{ root: classes.tabsRoot }}
      >
        <Tooltip
          title="Filtrowanie"
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <Tab
            icon={<SearchRoundedIcon className={classes.tabIcon} />}
            classes={{ root: classes.tab }}
            {...a11yProps(0)}
          />
        </Tooltip>
        <Tooltip
          title="Produkty"
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <Tab
            icon={<SortRoundedIcon className={classes.tabIcon} />}
            classes={{ root: classes.tab }}
            {...a11yProps(1)}
          />
        </Tooltip>
      </Tabs>
    </React.Fragment>
  );
}
