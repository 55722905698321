import React from "react";
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {Indicator} from "../Indicator.jsx";

function AuthGuard({ children }) {
  const { isLoggedIn } = useSelector(state => state.auth);

  return isLoggedIn === true ? (
    <>{children}</>
  ) : isLoggedIn === false ? (
    <Redirect to="/login" />
  ) : (
    <Indicator centerVertically height="100vh"></Indicator>
  );
}

export default AuthGuard;
