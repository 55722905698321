import {makeStyles} from "@material-ui/core";
import React from "react";
import LeftMenu, {TabPanel} from "../LeftMenu";
import RealTimeList from "./RealTimeList";
import {FilterProvider} from "../../_context/filterContext";
import {FiltersComponent} from "../Filters/FiltersComponent";
import {Helmet} from "react-helmet";
import DispatchRealTimeStartFilters from "./DispatchRealTimeStartFilters";
import {SelectedSygnaturySprzedazProvider} from "./Sprzedaze/SelectedSygnaturySprzedazContext";
import {SelectedSygnaturyZwrotyProvider} from "./Zwroty/SelectedSygnaturyZwrotyContext";
import {ColumnsSortProvider} from "./ColumnSortContext";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    minHeight: `calc(100vh - ${theme.headerHeight})`
  },
  tabPanel: {
    width: `calc(100vw - ${theme.leftMenuWidth})`,
    marginLeft: theme.leftMenuWidth,
    padding: "12px 12px 40px",
    backgroundColor: theme.palette.gray
  }
}));

export const RealTimeComponent = () => {
  const classes = useStyles();

  const [view, setView] = React.useState(1);

  const handleChangeView = (event, view) => {
    setView(view);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>RealTime</title>
      </Helmet>
      <FilterProvider>
        <DispatchRealTimeStartFilters>
          <LeftMenu value={view} handleChange={handleChangeView}></LeftMenu>
          <TabPanel value={view} index={0} className={classes.tabPanel}>
            <FiltersComponent setView={setView}></FiltersComponent>
          </TabPanel>
          <TabPanel value={view} index={1} className={classes.tabPanel}>
            <ColumnsSortProvider>
              <SelectedSygnaturySprzedazProvider>
                <SelectedSygnaturyZwrotyProvider>
                  <RealTimeList setView={setView}></RealTimeList>
                </SelectedSygnaturyZwrotyProvider>
              </SelectedSygnaturySprzedazProvider>
            </ColumnsSortProvider>
          </TabPanel>
        </DispatchRealTimeStartFilters>
      </FilterProvider>
    </div>
  );
};