import React from "react";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";
import TableComponent from "../../../Bestseller/components/TableComponent";
import NoItemAlert from "./NoItemAlert";

export const GET_DOSTAWY_BYID = gql`
  query Dostawies($id: [Int]) {
    dostawies(towarGrupowanie_id_list: $id) {
      documentId
      documentNmr
      polkaId
      qty
      towar {
        rozmiar
      }
      dataDostawy
      wartoscDostawyNetto
    }
  }
`;

const DevstatShowDostawy = ({ id }) => {
  const { loading, error, data } = useQuery(GET_DOSTAWY_BYID, {
    variables: { id }
  });

  if (error) return `Error! ${error}`;
  if (loading) return <></>;

  const headers = [
    {
      id: "documentNmr",
      numeric: false,
      disablePadding: true,
      label: "DocumentNmr"
    },
    {
      id: "documentId",
      numeric: true,
      disablePadding: true,
      label: "DocumentId"
    },
    {
      id: "polkaId",
      numeric: true,
      disablePadding: true,
      label: "PolkaId"
    },
    {
      id: "qty",
      numeric: true,
      disablePadding: true,
      label: "Ilość"
    },
    {
      id: "rozmiar",
      numeric: true,
      disablePadding: true,
      label: "Rozmiar"
    },
    {
      id: "dataDostawy",
      numeric: true,
      disablePadding: true,
      label: "DataDostawy"
    },
    {
      id: "wartoscDostawyNetto",
      numeric: true,
      disablePadding: true,
      label: "WartoscDostawyNetto"
    }
  ];

  function createData(
    documentNmr,
    documentId,
    polkaId,
    qty,
    rozmiar,
    dataDostawy,
    wartoscDostawyNetto
  ) {
    return {
      documentNmr,
      documentId,
      polkaId,
      qty,
      rozmiar,
      dataDostawy,
      wartoscDostawyNetto
    };
  }

  const rows = data.dostawies.map(it =>
    createData(
      it.documentNmr,
      it.documentId,
      it.polkaId,
      it.qty,
      it.towar.rozmiar,
      it.dataDostawy,
      it.wartoscDostawyNetto
    )
  );

  return (
    <>
      {data.dostawies.length ? (
        <TableComponent
          headCellsData={headers}
          rowsData={rows}
        ></TableComponent>
      ) : (
        <NoItemAlert text="brak danych dla dostaw" />
      )}
    </>
  );
};

export default DevstatShowDostawy;
