import { createStore } from "redux";
import RootReducer from "../_reducers/RootReducers";

const Store = createStore(
  RootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
);

export default Store;
