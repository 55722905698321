import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { GET_USERS } from "../Users/UsersComponent";
import Button from "@material-ui/core/Button";
import ChangePasswordDialog from "../Users/ChangePasswordDialog";

const UPDATE_USER_ACTIVITY = gql`
  mutation UpdateUser($id: ID!, $isActive: Boolean) {
    updateUser(input: { id: $id, isActive: $isActive }) {
      user {
        id
        isActive
      }
    }
  }
`;

const UPDATE_USER_DEVSTAT = gql`
  mutation UpdateUser($id: ID!, $isDevStat: Boolean) {
    updateUser(input: { id: $id, isDevStat: $isDevStat }) {
      user {
        id
        isDevStat
      }
    }
  }
`;

function createData(id, name, email, isActive, roles, isDevStat) {
  return { id, name, email, isActive, roles, isDevStat };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    label: "Name",
    style: { width: "20%", minWidth: "200px" }
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
    style: { width: "80%", minWidth: "200px" }
  },
  { id: "isDevStat", numeric: true, label: "Is DevStat" },
  { id: "isActive", numeric: true, label: "Is Active" },
  { id: "role", numeric: true, label: "Role" },
  {
    id: "password",
    numeric: true,
    label: "Password Action"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableHead}
            style={headCell.style}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: theme.tablePaper,
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableHead: {
    whiteSpace: "nowrap"
  }
}));

export default function EnhancedTable({ data }) {
  const rows = data.users.map(it =>
    createData(it.id, it.name, it.email, it.isActive, it.roles, it.isDevStat)
  );
  const classes = useStyles();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("email");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [openDialog, setOpenDialog] = useState(false);

  const [item, setItem] = useState(null);
  const changePasswordDialogBox = (
    <ChangePasswordDialog
      open={openDialog}
      setOpen={setOpenDialog}
      item={item}
    />
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [updateUser] = useMutation(UPDATE_USER_ACTIVITY, {
    update(cache, { data }) {
      const { users } = cache.readQuery({ query: GET_USERS });

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: users.map(it => {
            if (it.id === data.updateUser.user.id) {
              return data.updateUser.user;
            } else {
              return it;
            }
          })
        }
      });
    }
  });
  const handleToggle = item => {
    updateUser({
      variables: {
        id: item.id,
        isActive: !item.isActive
      }
    })
      .then(it => console.log("Success"))
      .catch(err => console.error(err));
  };

  const Switch = ({ isOn, item }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={() => handleToggle(item)} //call handleToggle using id
          id={`react-switch-new${item.id}`} // Make it unique by adding id
          type="checkbox"
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new${item.id}`} // Make it unique by adding id
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };

  const handleToggleIsDevStat = item => {
    updateUserIsDevStat({
      variables: {
        id: item.id,
        isDevStat: !item.isDevStat
      }
    })
      .then(it => console.log("Success"))
      .catch(err => console.error(err));
  };

  const SwitchDevStat = ({ isOn, item }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={() => handleToggleIsDevStat(item)} //call handleToggle using id
          id={`react-switch-new${item.id}`} // Make it unique by adding id
          type="checkbox"
        />
        <label
          className="react-switch-label"
          htmlFor={`react-switch-new${item.id}`} // Make it unique by adding id
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };

  const [updateUserIsDevStat] = useMutation(UPDATE_USER_DEVSTAT, {
    update(cache, { data }) {
      const { users } = cache.readQuery({ query: GET_USERS });

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: users.map(it => {
            if (it.id === data.updateUser.user.id) {
              return data.updateUser.user;
            } else {
              return it;
            }
          })
        }
      });
    }
  });

  const handleOpen = item => {
    setItem(item);
    setOpenDialog(true);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={`row.name-${index}`}>
                      <TableCell id={row.name}>{row.name}</TableCell>
                      <TableCell id={labelId}>{row.email}</TableCell>
                      <TableCell align="right">
                        <SwitchDevStat isOn={row.isDevStat} item={row} />
                      </TableCell>
                      <TableCell align="right">
                        <Switch isOn={row.isActive} item={row} />
                      </TableCell>
                      <TableCell align="right">{row.roles[0]}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleOpen(row);
                          }}
                        >
                          Zmiana hasła
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {openDialog ? changePasswordDialogBox : ""}
    </div>
  );
}
