import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import md5 from "md5";
import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

const UPDATE_USER_PASSWORD = gql`
  mutation updateUser($id: ID!, $password: String) {
    updateUser(input: { id: $id, password: $password }) {
      user {
        id
      }
    }
  }
`;

const ChangePasswordDialog = ({ open, setOpen, item }) => {
  const [password, setPassword] = useState(md5("vitkac" + new Date()));

  const handleClose = () => {
    setPassword("");
    setOpen(false);
  };

  const [
    updateUserPassword,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(UPDATE_USER_PASSWORD);

  if (mutationLoading) return <p>Loading...</p>;
  if (mutationError) return <p>Error :(</p>;

  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    updateUserPassword({
      variables: {
        id: item.id,
        password: password
      }
    })
      .then(it => handleClose())
      .catch(err => console.log(err));
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Typography variant="h2">
          <DialogTitle disableTypography id="form-dialog-title">
            Hasło generowane automatycznie
          </DialogTitle>
        </Typography>
        <DialogContent>{password}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button onClick={e => handleSave(e)}>Zapisz</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ChangePasswordDialog;
