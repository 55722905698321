import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {getShowProductDevstat} from "../../../../_services/devstatService";
import DevstatShowProductItem from "./DevstatShowProductItem";
import BestsellerListItemPlaceholder from "../../../Bestseller/BestsellerListItemPlaceholder";
import {FilterProvider} from "../../../../_context/filterContext";
import ShowProductItemFilterProvider from "./ShowProductItemFilterProvider";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "10px"
  },
  background: {
    padding: "60px 12px 40px",
    backgroundColor: theme.palette.gray
  }
}));

const DevstatShowProduct = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [tg, setTg] = useState([]);
  const [synchro, setSynchro] = useState(0);
  let { id } = useParams();
  let { type } = useParams();

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getShowProductDevstat(id, type)
      .then(res => {
        setTg(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scroll(0, 0);
      });
    return () => {};
  }, [id, synchro, type]);

  const loading = [...Array(5)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  const noProductAlert = <h1>Nie znaleziono produktu</h1>;

  return (
    <div className={classes.background}>
      {isLoading ? (
        loading
      ) : tg.id ? (
        <FilterProvider>
          <ShowProductItemFilterProvider tg={tg}>
            <DevstatShowProductItem
              tg={tg}
              setSynchro={setSynchro}
            ></DevstatShowProductItem>
          </ShowProductItemFilterProvider>
        </FilterProvider>
      ) : (
        noProductAlert
      )}
    </div>
  );
};

export default DevstatShowProduct;
