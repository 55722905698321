import React, { useEffect, useState, useRef } from "react";
import { getLog } from "../../../_services/devstatLogService";
import { Grid, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import parse from "html-react-parser";

const useStyles = makeStyles(theme => ({
  logPaper: theme.filterPaper,
  pre: theme.pre,
  smallButton: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    fontSize: "0.7em",
    lineHeight: "unset",
    boxSizing: "border-box",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      fontSize: "0.7em",
      lineHeight: "unset",
      boxSizing: "border-box",
      boxShadow: "none"
    }
  },
  textInput: theme.logsTextInput
}));

const LogItemFile = ({ name }) => {
  const classes = useStyles();

  const [log, setLog] = useState("");
  const [lines, setLines] = useState(50);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [update, setUpdate] = useState(0);
  const autoRefreshTime = 10000;

  const showLogWindowRef = useRef(null);

  useEffect(() => {
    getLog(name, lines)
      .then(res => setLog(res.data))
      .finally(() => {
        showLogWindowRef.current.scrollIntoView();
      });
    return () => {};
  }, [name, lines, update]);

  if (autoRefresh) {
    setTimeout(() => {
      setUpdate(update + 1);
    }, autoRefreshTime);
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.logPaper}>
        <Typography variant="h2">Plik - {name}</Typography>
        <pre className={classes.pre} ref={showLogWindowRef}>
          {parse(log)}
        </pre>
        <Grid container spacing={1} direction="row" alignItems="stretch">
          <Grid item>
            <Button
              className={classes.smallButton}
              variant="outlined"
              color="primary"
              onClick={e => setLines(lines - 20)}
            >
              -20
            </Button>
          </Grid>
          <Grid item>
            <input
              type="number"
              value={lines}
              className={classes.textInput}
              onChange={e => setLines(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.smallButton}
              variant="outlined"
              color="primary"
              onClick={e => setLines(lines + 20)}
            >
              +20
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.smallButton}
              variant="outlined"
              color="primary"
              onClick={e => setAutoRefresh(!autoRefresh)}
            >
              {autoRefresh ? "Disable auto Refresh" : "Enable auto Refresh"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default LogItemFile;
