import React, {useContext, useState} from "react";
import {filterContextDevstat} from "../../../../_context/filterContextDevstat";
import {Grid, makeStyles, Paper, TextField, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "10px"
  }
}));

export const DesignerFilter = () => {
  const classes = useStyles();

  const {
    state: { designers }
  } = useContext(filterContextDevstat);

  const [productDesigners, setProductDesigner] = useState(designers);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Projektanci</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            key="projektanci"
            label="Projektant"
            value={productDesigners || ""}
            variant="outlined"
            onChange={event => setProductDesigner(event.target.value)}
            name="designers"
            className={classes.inputField}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
