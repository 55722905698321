import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Divider,
  TextField,
  InputAdornment
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  divider: theme.divider,
  labels: theme.datePriceLabels,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton
  }
}));

export const DiscountFilter = () => {
  const classes = useStyles();

  const {
    state: { discountFrom, discountTo, _update }
  } = useContext(filterContext);

  const [disFrom, setDisFrom] = useState(discountFrom);
  const [disTo, setDisTo] = useState(discountTo);

  useEffect(() => {
    setDisFrom(discountFrom);
  }, [discountFrom, _update]);

  useEffect(() => {
    setDisTo(discountTo);
  }, [discountTo, _update]);

  const selectDiscount = (e, from, to) => {
    e.preventDefault();
    e.stopPropagation();
    setDisFrom(from);
    setDisTo(to);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Średni rabat</Typography>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDiscount(e, null, 10)}
            >
              0% - 10%
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDiscount(e, 11, 20)}
            >
              11% - 20%
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDiscount(e, 21, 30)}
            >
              21% - 30%
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDiscount(e, 31, 50)}
            >
              31% - 50%
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDiscount(e, 50, null)}
            >
              >50%
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.labels}>
        <Grid item xs>
          <TextField
            key="discount-from"
            label="Rabat od (%)"
            type="number"
            value={(disFrom !== null ? disFrom : "0") || ""}
            variant="outlined"
            name="discountFrom"
            onChange={event => setDisFrom(event.target.value)}
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDisFrom(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item className={classes.divider}>
          <br />
          <Divider />
        </Grid>
        <Grid item xs>
          <TextField
            key="discount-to"
            label="Rabat do (%)"
            type="number"
            value={(disTo !== null ? disTo : "100") || ""}
            variant="outlined"
            onChange={event => setDisTo(event.target.value)}
            name="discountTo"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDisTo(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
