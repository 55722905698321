import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    minWidth: "130px"
  },
  progressbar: {
    position: "relative",
    height: "20px",
    width: "100%",
    minWidth: "130px",
    border: "1px solid black",
    borderRadius: "2px",
    textAlign: "center",
    "& .MuiTypography-body1": {
      position: "absolute",
      zIndex: 1,
      width: "100%",
      height: "100%",
      padding: 0,
      "&:hover": {
        backgroundColor: "#ffffff36"
      }
    }
  },
  spacing: {
    height: "12px"
  },
  progressbarRed: {
    borderColor: "#1247dc"
  },
  progressBarBlue: {
    borderColor: "#2753cc"
  },
  progressbarGreen: {
    borderColor: "#3a9a21"
  },
  filler: {
    background: "#1DA598",
    height: "100%",
    borderRadius: "1px",
    transition: "width .2s ease-in",
    position: "absolute"
  },
  fillerRed: {
    backgroundColor: "#dc3912"
  },
  fillerBlue: {
    backgroundColor: "#2753cc"
  },
  fillerGreen: {
    backgroundColor: "#3a9a21"
  },
  legend: {
    width: "30px",
    height: "20px",
    borderRadius: "2px"
  },
  legendBlue: {
    backgroundColor: "#2753cc"
  },
  legendRed: {
    backgroundColor: "#dc3912"
  },
  legendGreen: {
    backgroundColor: "#3a9a21"
  }
}));

export const ProgressBarComponent = ({ type }) => {
  const classes = useStyles();

  let total = 0;
  let online = 0;
  let stationary = 0;
  let other = 0;
  if (!type.length) return <></>;

  type.map(it => {
    total += it.qtySell;

    switch (it.type) {
      case "online":
        online = it.qtySell;
        break;
      case "stationary":
        stationary = it.qtySell;
        break;
      case "other":
        other = it.qtySell;
        break;
      default:
    }
    return [];
  });

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const percentOnline = round((online / total) * 100, 2);
  const percentStationary = round((stationary / total) * 100, 2);
  const percentOther = round((other / total) * 100, 2);

  const Filler = (percent, colorClass) => {
    return (
      <div
        className={`${classes.filler} ${colorClass}`}
        style={{ width: percent > 100 ? "100%" : `${percent}%` }}
      ></div>
    );
  };

  const ProgressBar = (percent, colorClass) => {
    return (
      <div>
        <div className={`${classes.progressbar} ${classes.progressbarGreen}`}>
          {Filler(percent, colorClass)}
          <Typography variant="body1">{`${percent}%`}</Typography>

          {}
        </div>
      </div>
    );
  };

  return (
    <Grid
      container
      className={classes.container}
      direction="column"
      spacing={1}
    >
      {/* <h1>Progress Bar</h1> */}
      <Grid item>
        {ProgressBar(percentOnline ? percentOnline : 0, classes.fillerBlue)}
      </Grid>
      <Grid item>
        {ProgressBar(
          percentStationary ? percentStationary : 0,
          classes.fillerGreen
        )}
      </Grid>
      <Grid item>
        {ProgressBar(percentOther ? percentOther : 0, classes.fillerRed)}
      </Grid>
      <Grid item className={classes.spacing}></Grid>
      <Grid item container direction="row" xs={12} spacing={2}>
        <Grid item>
          <div className={`${classes.legend} ${classes.legendBlue}`}></div>
        </Grid>
        <Grid item>
          <Typography variant="body1">Online</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" xs={12} spacing={2}>
        <Grid item>
          <div className={`${classes.legend} ${classes.legendGreen}`}></div>
        </Grid>
        <Grid item>
          <Typography variant="body1">Stacjonarne</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" xs={12} spacing={2}>
        <Grid item>
          <div className={`${classes.legend} ${classes.legendRed}`}></div>
        </Grid>
        <Grid item>
          <Typography variant="body1">Inne</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
