import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Grid, InputAdornment, makeStyles, Paper, TextField, Typography} from "@material-ui/core";
import {filterContext} from "../../_context/filterContext";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  divider: theme.divider,
  labels: theme.datePriceLabels,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton
  }
}));

export const PriceFilter = () => {
  const classes = useStyles();

  const {
    state: { priceFrom, priceTo, _update }
  } = useContext(filterContext);

  const [pFrom, setPriceFrom] = useState(priceFrom);
  const [pTo, setPriceTo] = useState(priceTo);

  useEffect(() => {
    setPriceFrom(priceFrom);
  }, [priceFrom, _update]);

  useEffect(() => {
    setPriceTo(priceTo);
  }, [priceTo, _update]);

  const selectPrice = (e, pFrom, pTo) => {
    e.preventDefault();
    e.stopPropagation();
    setPriceFrom(pFrom);
    setPriceTo(pTo);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Zakres cen</Typography>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectPrice(e, 0, 1000)}
            >
              0-1000
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectPrice(e, 1001, 2500)}
            >
              1001-2500
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectPrice(e, 2501, 5000)}
            >
              2501-5000
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectPrice(e, 5000, 10000)}
            >
              5001-10000
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.labels}>
        <Grid item xs>
          <TextField
            key="price-from-id"
            label="Cena od"
            value={pFrom !== null ? pFrom : ""}
            variant="outlined"
            onChange={event => setPriceFrom(event.target.value)}
            name="priceFrom"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setPriceFrom(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item className={classes.divider}>
          <br />
          <Divider />
        </Grid>
        <Grid item xs>
          <TextField
            key="price-to-id"
            label="Cena do"
            value={pTo !== null ? pTo : ""}
            variant="outlined"
            onChange={event => setPriceTo(event.target.value)}
            name="priceTo"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setPriceTo(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
