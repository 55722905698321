import React, {useContext, useState} from "react";
import {filterContextDevstat} from "../../../../_context/filterContextDevstat";
import {Grid, makeStyles, Paper, TextField, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "10px"
  }
}));

export const StockFilter = () => {
  const classes = useStyles();

  const {
    state: { stock }
  } = useContext(filterContextDevstat);

  const [productStock, setProductStock] = useState(stock);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Stock</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            key="stock"
            label="Od (szt.)"
            value={productStock || ""}
            variant="outlined"
            onChange={event => setProductStock(event.target.value)}
            name="stock"
            className={classes.inputField}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
