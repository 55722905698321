import { ApolloProvider } from "@apollo/react-hooks";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ApolloClient from "apollo-boost";
import React from "react";
import { Provider } from "react-redux";
import { App } from "../App";
import Store from "../_stores/Store.js";

// const cache = new InMemoryCache({
//   dataIdFromObject: object => object.key || null
// });

const client = new ApolloClient({
  request: operation => {
    const token = localStorage.getItem("apiToken");
    operation.setContext({
      headers: {
        "x-auth-token": token ? token : ""
      }
    });
  },
  uri: process.env.REACT_APP_API_GRAPHQL_URL
  // cache: cache
});

export const Wrapper = () => {
  return (
    <Provider store={Store}>
      <ApolloProvider client={client}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </Provider>
  );
};

// ReactDOM.render(<Wrapper />, document.getElementById("root"));
