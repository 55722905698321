import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const DevstatShowTowar = ({ towary }) => {
  const classes = useStyles();

  const items = towary.map((item, index) => {
    return (
      <div key={`list-${index}`}>
        <ListItem key={`id-${index}`}>id: {item.id} </ListItem>
        <ListItem key={`polka-${index}`}>polkaId: {item.polkaId} </ListItem>
        <ListItem key={`proj-${index}`}>projektant: {item.projektant}</ListItem>
        <ListItem key={`nazwa-${index}`}>nazwa: {item.nazwa} </ListItem>
        <ListItem key={`symbol-${index}`}>symbol: {item.symbol} </ListItem>
        <ListItem key={`aktywny-${index}`}>
          aktywny: {item.aktywny === true ? "true" : "false"}
        </ListItem>
        <ListItem key={`sezon-${index}`}>sezon: {item.sezon} </ListItem>
        <ListItem key={`kolor-${index}`}>kolor: {item.kolor} </ListItem>
        <ListItem key={`rozmiar-${index}`}>rozmiar: {item.rozmiar} </ListItem>
        <ListItem key={`dostepne-${index}`}>
          wDostepne: {item.wDostepne === true ? "true" : "false"}
        </ListItem>
        <ListItem key={`zdjecie-${index}`}>
          wZdjecie: {item.wZdjecie === true ? "true" : "false"}
        </ListItem>
        <ListItem key={`pokd-${index}`}>
          podkolekcja: {item.podkolekcja}
        </ListItem>
        <ListItem key={`stock-${index}`}>stock: {item.stock}</ListItem>
        <ListItem key={`co-${index}`}>
          isCo: {item.isCo === true ? "true" : "false"}
        </ListItem>
        <ListItem key={`created-${index}`}>
          createdAt: {item.createdAt}
        </ListItem>
        <ListItem kkey={`basep-${index}`}>
          basePriceNetto: {item.basePriceNetto}
        </ListItem>
        <ListItem key={`date-${index}`}>
          datePierwszegoStany: {item.datePierwszegoStanu}
        </ListItem>
        --------------------
      </div>
    );
  });

  return (
    <div className={classes.demo}>
      <List style={{ maxHeight: 400, overflow: "auto" }} key="list">
        {items}
      </List>
    </div>
  );
};

export default DevstatShowTowar;
