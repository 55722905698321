import React from "react";
import {TableCell, TableRow} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {parseToFixed, thousandSeparator} from "../../_helpers/separatorValue";

export const OrderListTableMui = ({
  data,
  selectedDataIndex,
  setSelectedDataIndex
}) => {
  const dataParsed = data.map(it => {
    return {
      ...it,
      qtySell: parseInt(it.qtySell, 10),
      qtyReturned: parseInt(it.qtyReturned, 10),
      qtyTotal: parseInt(it.qtyTotal, 10),
      percentRabatSell: parseToFixed(it.percentRabatSell, 2)
    };
  });

  const handleExpandable = (e, index) => {
    return dataParsed[index.dataIndex].currency.map(it => {
      const idx = index.dataIndex + it.currency;

      return (
        <TableRow key={idx}>
          <TableCell></TableCell>
          <TableCell>{it.currency}</TableCell>
          <TableCell>{parseInt(it.orderCnt, 10)}</TableCell>
          <TableCell>{parseInt(it.qtySell, 10)}</TableCell>
          <TableCell>{parseInt(it.qtyReturned, 10)}</TableCell>
          <TableCell>{parseInt(it.qtyTotal, 10)}</TableCell>
          <TableCell>
            {parseToFixed(it.percentRabatSell, 2) +
              " / " +
              parseToFixed(it.percentRabatReturned, 2)}
          </TableCell>
          <TableCell>{parseToFixed(it.percentRabat, 2)}</TableCell>
          <TableCell>{parseToFixed(it.avgVatRate, 2)}</TableCell>
          <TableCell>{thousandSeparator(it.avgPriceNetto)}</TableCell>
          <TableCell>{thousandSeparator(it.priceNetto)}</TableCell>
          <TableCell>
            {thousandSeparator(parseToFixed(it.wartoscMagazynowaNetto, 2))}
          </TableCell>
          <TableCell>{parseToFixed(it.priceNettoPercentRabat, 2)}</TableCell>
        </TableRow>
      );
    });
  };

  const handleSelected = selectRow => {
    const index = selectRow[0].dataIndex;
    let newArr = [];

    if (selectedDataIndex.indexOf(index) === -1) {
      newArr = [...selectedDataIndex, index];
    } else {
      newArr = selectedDataIndex.filter(it => it !== index);
    }

    setSelectedDataIndex(newArr);
  };

  const option = {
    pagination: false,
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: handleExpandable,
    onRowsSelect: handleSelected,
    rowsSelected: selectedDataIndex,
    download: true,
    print: false,
    selectableRows: "multiple",
    disableToolbarSelect: true,
    selectableRowsHeader: false,
    filter: false,
    textLabels: {
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  const columns = [
    {
      name: "fullDate",
      label: "Data",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div styles={{ display: "flex", flexDirection: "column" }}>
              <div>{value}</div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? data[tableMeta.rowIndex].compare.fullDate
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "orderCnt",
      label: "Zamówienia [szt.]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>{value}</div>
              {/*<div style={{ color: "#808080" }}>*/}
              {/*  {data[tableMeta.rowIndex].compare*/}
              {/*    ? data[tableMeta.rowIndex].compare.qtySell*/}
              {/*    : ""}*/}
              {/*</div>*/}
            </div>
          );
        }
      }
    },
    {
      name: "qtySell",
      label: "Sprzedane [szt.]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>{value}</div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? data[tableMeta.rowIndex].compare.qtySell
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "qtyReturned",
      label: "Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>{value}</div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? data[tableMeta.rowIndex].compare.qtyReturned
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "qtyTotal",
      label: "Sprzedane - Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>{value}</div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? data[tableMeta.rowIndex].compare.qtyTotal
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "percentRabatSell",
      label: "Rabat [sprz./ zw. %]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div value={value}>
                <b>{value}</b>
                {" | " +
                  parseToFixed(
                    data[tableMeta.rowIndex].percentRabatReturned,
                    2
                  )}
              </div>

              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare ? (
                  <>
                    {`${parseToFixed(
                      data[tableMeta.rowIndex].compare.percentRabatSell,
                      2
                    )} | ${parseToFixed(
                      data[tableMeta.rowIndex].compare.percentRabatReturned,
                      2
                    )}`}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "percentRabat",
      label: "Średni rabat[%]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div value={value}>{parseToFixed(value, 2)}</div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? parseToFixed(
                      data[tableMeta.rowIndex].compare.percentRabat,
                      2
                    )
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "avgVatRate",
      label: "Średni vat sprzedaży[%]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div value={value}>{parseToFixed(value, 2)}</div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? parseToFixed(data[tableMeta.rowIndex].compare.avgVatRate, 2)
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "avgPriceNetto",
      label: "Średnia centa netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div value={value}>
                {thousandSeparator(parseToFixed(value, 2))}
              </div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? thousandSeparator(
                      parseToFixed(
                        data[tableMeta.rowIndex].compare.avgPriceNetto,
                        2
                      )
                    )
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "priceNetto",
      label: "Suma netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div value={value}>
                {thousandSeparator(parseToFixed(value, 2))}
              </div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? thousandSeparator(
                      parseToFixed(
                        data[tableMeta.rowIndex].compare.priceNetto,
                        2
                      )
                    )
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "wartoscMagazynowaNetto",
      label: "Suma cena bazowa netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div value={value}>
                {thousandSeparator(parseToFixed(value, 2))}
              </div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? thousandSeparator(
                      parseToFixed(
                        data[tableMeta.rowIndex].compare.wartoscMagazynowaNetto,
                        2
                      )
                    )
                  : ""}
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "priceNettoPercentRabat",
      label: "Rabat suma sprzedazy/ suma cena bazowa[%]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div value={value}>
                {thousandSeparator(parseToFixed(value, 2))}
              </div>
              <div style={{ color: "#808080" }}>
                {data[tableMeta.rowIndex].compare
                  ? thousandSeparator(
                      parseToFixed(
                        data[tableMeta.rowIndex].compare.priceNettoPercentRabat,
                        2
                      )
                    )
                  : ""}
              </div>
            </div>
          );
        }
      }
    }
  ];

  return (
    <>{<MUIDataTable data={dataParsed} columns={columns} options={option} />}</>
  );
};
