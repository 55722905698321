import React, { useContext, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { filterContextDevstat } from "../../../../_context/filterContextDevstat";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "2px"
  }
}));

export const ResultsWithSalesFilter = () => {
  const classes = useStyles();

  const {
    state: { resultsWithSales }
  } = useContext(filterContextDevstat);

  const [results, setResults] = useState(resultsWithSales);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Pokaż wyniki</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={6} className={classes.marginBottom}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={results === 1 ? true : false}
                    onChange={e =>
                      setResults(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label="tylko ze sprzedażą"
                name="resultsWithSales"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};
