import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import {Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import {GET_USERS} from "../Users/UsersComponent";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
  h2: {
    margin: 0
  },
  formControl: {
    width: "100%",
    paddingTop: theme.spacing(1),
    "&:last-of-child": {}
  },
  actions: {
    margin: "0 8px"
  }
}));

const ADD_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $roles: Iterable!
    $name: String!
    $isDevStat: Boolean
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        roles: $roles
        name: $name
        isDevStat: $isDevStat
      }
    ) {
      user {
        id
        email
        roles
        name
        isDevStat
        isActive
      }
    }
  }
`;

const NewUserDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("ROLE_USER");
  const [name, setName] = useState("");
  const [devStat, setDevStat] = useState(false);

  const role_option = [
    { name: "User", value: "ROLE_USER" },
    { name: "Admin", value: "ROLE_ADMIN" }
  ];

  const [saveUser] = useMutation(ADD_USER, {
    update(cache, { data }) {
      const { users } = cache.readQuery({ query: GET_USERS });

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: [...users, data.createUser.user]
        }
      });
    }
  });

  const handleClose = () => {
    setEmail("");
    setPassword("");
    setName("");
    setRole("ROLE_USER");
    setDevStat(false);
    setOpen(false);
  };

  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();

    saveUser({
      variables: {
        email: email,
        password: password,
        roles: [role],
        name: name,
        isDevStat: devStat
      }
    })
      .then(it => handleClose())
      .catch(err => console.log(err));
  };

  const handleChange = event => {
    setDevStat(event.target.checked);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Typography variant="h2" className={classes.h2}>
          <DialogTitle disableTypography id="form-dialog-title">
            Dodanie nowego użytkownika
          </DialogTitle>
        </Typography>
        <DialogContent>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="user-dialog-email"
            label="Email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="user-dialog-name"
            label="Imie"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="user-dialog-password"
            label="Hasło"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            fullWidth
          />
          <FormControl
            // style={{ minWidth: 30 }}
            variant="outlined"
            value={role}
            className={classes.formControl}
            onChange={event => {
              setRole(event.target.value);
            }}
          >
            <Select
              native
              placeholder="Wybierz"
              classes={{ root: classes.paginationSelect }}
            >
              {role_option.map(option => {
                const id = `option-${option.name}`;
                return (
                  <option key={id} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
              ))}
            </Select>
            <Switch
              checked={devStat}
              onChange={handleChange}
              color="primary"
              name="isDevStat"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button onClick={e => handleSave(e)} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default NewUserDialog;
