import { Button, Checkbox, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import { useDispatch } from "react-redux";
import { ERROR, SUCCESS } from "../../../_actions/alertsActions";
import { clearLog, getFileLog } from "../../../_services/devstatLogService";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import DevstatLogSearch from "./DevstatLogSearch";

const useStyles = makeStyles(theme => ({
  table: {
    fontSize: "0.9em"
  },
  tableRefresh: {
    width: theme.spacing(2)
  },
  tableName: {
    width: "100%",
    minWidth: "250px"
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  downloadButton: {
    marginRight: theme.spacing(1)
  },
  noPaddingCell: {
    "& .MuiIconButton-root": {
      padding: "4px"
    },
    padding: "6px 0 6px 16px"
  },
  root: {
    flexGrow: 1,
    marginTop: "30px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

const DevstatLogList = ({ list, handleRefresh, handleChangeActivity }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClearLogFile = (e, name) => {
    e.preventDefault();

    //todo: odznaczenie usunietego
    clearLog(name)
      .then(res => {
        handleRefresh();
        dispatch({
          type: SUCCESS,
          payload: `Log ${name} został wyczyszczony`
        });
      })
      .catch(e => {
        dispatch({
          type: ERROR,
          payload: `Nie udało się wyczyścic pliku: ${name}`
        });
      });
  };

  // const getUniIndexInfo = () => {
  //   return (
  //     <div>
  //       <Route path="/uniIndex">
  //         <CompareDbHurtownia uniIndex={uniIndex}></CompareDbHurtownia>
  //       </Route>
  //     </div>
  //   );
  // };

  const handleDownloadFile = (e, name) => {
    getFileLog(name).then(response => {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
    });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="right"
                className={`${classes.tableRefresh} ${classes.noPaddingCell}`}
              >
                <IconButton onClick={handleRefresh} size="small">
                  <SyncRoundedIcon>Odśwież</SyncRoundedIcon>
                </IconButton>
              </TableCell>
              <TableCell align="left" className={classes.tableName}>
                Nazwa
              </TableCell>
              <TableCell align="left">Last mod</TableCell>
              <TableCell align="left">Rozmiar</TableCell>
              <TableCell align="right">Akcje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(row => (
              <TableRow key={row.name}>
                <TableCell align="right" className={classes.noPaddingCell}>
                  <Checkbox
                    checked={row.isActive ? true : false}
                    onChange={e => handleChangeActivity(row, !row.isActive)}
                    value="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    fontSize="small"
                  />
                </TableCell>
                <TableCell align="left">
                  [{row.isDb ? "DB" : "PLIK"}] {row.name}
                </TableCell>
                <TableCell align="left" className={classes.noWrap}>
                  {row.mod_date}
                </TableCell>
                <TableCell align="left" className={classes.noWrap}>
                  {row.size}
                </TableCell>
                <TableCell align="right" className={classes.noWrap}>
                  {row.isDb ? (
                    ""
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.downloadButton}
                        onClick={e => handleDownloadFile(e, row.name)}
                      >
                        Pobierz
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={e => handleClearLogFile(e, row.name)}
                      >
                        Wyczyść log
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DevstatLogSearch></DevstatLogSearch>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DevstatLogList;
