import React from "react";

export const Indicator = ({centerVertically, height}) => {
  return (
    <div style={centerVertically ? {position: "relative", height: height} : {width: "100%", textAlign: "center"}}>
      <img
        src={process.env.PUBLIC_URL + "indicator2.gif"}
        alt="loading..."
        style={centerVertically ? {position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"} : {}}
      />
    </div>
  );
};
