import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import SelectedList from "./SelectedList";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { Indicator } from "../Indicator";

const GET_COUNTRY = gql`
  {
    countries {
      _id
      name
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const CountryFilter = () => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_COUNTRY);
  const {
    state: { country, _update }
  } = useContext(filterContext);

  const [selected, setSelected] = useState(
    country.length ? country.split(",").map(it => parseInt(it, 10)) : ""
  );

  useEffect(() => {
    setSelected(
      country.length ? country.split(",").map(it => parseInt(it, 10)) : ""
    );
    return () => {};
  }, [country, _update]);

  if (loading) return <Indicator centerVertically height="380px"></Indicator>;
  if (error) return "Cannot load Sygnaturas filter";

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Kraj</Typography>
      <input type="hidden" value={selected} name="country" />
      <SelectedList
        data={data.countries}
        selected={selected}
        setSelected={setSelected}
        keyName={"country"}
        nameKey={"name"}
      />
    </Paper>
  );
};
