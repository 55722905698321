import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { ActiveFilter } from "../../_components/Filters/_activeFilter/ActiveFilter";
import { filterContext } from "../../_context/filterContext";
import NoProductAlert from "../../_helpers/NoProductAlert";
import { getSale } from "../../_services/_saleService";
import TablePlaceholder from "../TablePlaceholder";
import ComparePeriodTime, {
  LAST_PERIOD_TYPE,
  LAST_YEAR_TYPE
} from "./ComparePeriodTime";
import OorderListGroupBy from "./OrderListGroupBy";
import { OrderListTableMui } from "./OrderListTableMui";
import OrdersChartCurrency from "./OrdersChartComponent/OrdersChartCurrency";
import OrdersChartPriceNetto from "./OrdersChartComponent/OrdersChartPriceNetto";
import { OrdersDateDetailComponent } from "./OrdersDetailComponent/OrdersDateDetailComponent";
import useParseDateToCompare from "../../_hooks/useParseDataToCompare";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(1)
  },
  compareTime: {
    position: "absolute",
    right: "12px",
    top: "85px",
    zIndex: 10000
  }
}));

const OrdersList = ({ setView }) => {
  const classes = useStyles();
  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isActiveCompareTimeLabel, setIsActiveCompareTimeLabel] = useState(
    false
  );
  const [isActiveCompareTimeButton, setIsActiveCompareTimeButton] = useState(
    false
  );
  const [data, setData] = useState([]);
  const [selectedDataIndex, setSelectedDataIndex] = useState([]);
  const {
    parseDateToArrayLastYear,
    parseDateToArrayLastPeriod
  } = useParseDateToCompare();

  useEffect(() => {
    setIsActiveCompareTimeButton(false);
    setIsActiveCompareTimeLabel(false);
    setSelectedDataIndex([]);
    setIsLoading(true);
    window.scrollTo(0, 0);
    const filters = {
      ...state,
      groupBy: state.groupBy
        .filter(it => it.isActive)
        .map(it => {
          return it.value;
        })
    };
    getSale(filters)
      .then(res => {
        setData(res.data);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }, [state]);

  const compareFunction = (dataCompare, compareType) => {
    if (dataCompare && data) {
      switch (compareType) {
        case LAST_YEAR_TYPE:
          setData(parseDateToArrayLastYear(data, dataCompare));
          setIsLoading(false);
          break;
        case LAST_PERIOD_TYPE:
          setData(parseDateToArrayLastPeriod(data, dataCompare));
          setIsLoading(false);
          break;
        default:
          return;
      }
    }
  };

  const details = data
    .filter((it, index) => selectedDataIndex.indexOf(index) !== -1)
    .map(it => {
      return <OrdersDateDetailComponent date={it.fullDate} key={it.fullDate} />;
    });

  const cleanDataCompare = () => {
    let newArr = data.map(it => {
      delete it.compare;

      return it;
    });

    setData(newArr);
  };

  if (!isLoading && data.length < 1)
    return <NoProductAlert setView={setView} />;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ActiveFilter></ActiveFilter>
        </Grid>
        <Grid item xs={12}>
          <OorderListGroupBy
            setIsActiveCompareTimeLabel={setIsActiveCompareTimeLabel}
            activeCompareTimeLabel={isActiveCompareTimeLabel}
            isActiveCompareTimeButton={isActiveCompareTimeButton}
            setIsActiveCompareTimeButton={setIsActiveCompareTimeButton}
            cleanDataCompare={cleanDataCompare}
          />
        </Grid>

        {isActiveCompareTimeLabel ? (
          <div className={classes.compareTime}>
            <ComparePeriodTime
              setIsActiveCompareTimeLabel={setIsActiveCompareTimeLabel}
              setIsActiveCompareTimeButton={setIsActiveCompareTimeButton}
              setIsLoading={setIsLoading}
              compareFunction={compareFunction}
            />
          </div>
        ) : null}

        <Grid item xs={12}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            // <OrderListTable data={data}></OrderListTable>\
            <OrderListTableMui
              data={data}
              selectedDataIndex={selectedDataIndex}
              setSelectedDataIndex={setSelectedDataIndex}
            ></OrderListTableMui>
          )}
        </Grid>
        <Grid item xs={12}>
          {selectedDataIndex.length ? details : null}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <OrdersChartPriceNetto data={data} />
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <OrdersChartCurrency data={data} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default OrdersList;
