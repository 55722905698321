import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import ActiveFilterItem from "./ActiveFilterItem";

export const GET_SYG = gql`
  query Sygnaturas($id_list: [Int]) {
    sygnaturas(id_list: $id_list) {
      _id
      name
    }
  }
`;

const ActiveFilterSygnatura = ({ IdList, removeAction, change }) => {
  const { data, loading, error } = useQuery(GET_SYG, {
    variables: {
      id_list: IdList
    }
  });

  if (error) return "error";
  if (loading) return "";

  return (
    <>
      {data.sygnaturas.map(it => (
        <ActiveFilterItem
          label={it.name}
          type={"REMOVE_SYGNATURA"}
          payload={it._id}
          key={it._id}
          remove={removeAction}
          change={change}
        ></ActiveFilterItem>
      ))}
    </>
  );
};

export default ActiveFilterSygnatura;
