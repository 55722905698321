import { Button, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import React, { useContext, useState } from "react";
import { filterContextDevstat } from "../../../_context/filterContextDevstat";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #37474f",
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "0.8rem",
      color: "#6f6f6f"
    }
  },
  header: {
    backgroundColor: "#37474f",
    color: "white",
    padding: "7px 10px 9px",
    textTransform: "uppercase"
  },
  productName: {
    fontWeight: 500,
    marginLeft: "10px"
  },
  divider: {
    marginTop: "6px",
    marginBottom: "6px",
    backgroundColor: "rgba(216, 216, 216, 0.26)"
  },
  noMarginY: theme.noMarginY,
  item: {
    "&:nth-of-type(2n)": {
      height: "45%",
      paddingTop: "13px"
    },
    "&:nth-of-type(2n + 1)": {
      height: "48%",
      paddingTop: "20px"
    },
    "& .MuiTypography-body1": {
      lineHeight: 1
    }
  },
  photo: {
    position: "absolute",
    borderRadius: "3px",
    border: `1px solid ${theme.palette.border}`,
    top: "-100%",
    left: "115px",
    width: "400px",
    height: "auto",
    zIndex: 999,
    transition: "all 0.5s ease-out"
  },
  photoThumbnail: {
    height: "150px",
    marginRight: "1.6vw",
    marginLeft: "15px",
    marginTop: "5px"
  },
  photoContainer: {
    position: "relative",
    minHeight: "115px"
  },
  danger: {
    fontSize: "1em",
    color: "#e60000",
    lineHeight: 1.2,
    fontWeight: 500
  }
}));

const getCategoryPath = category => {
  return category && category.match(/^\d/) ? category.substring(1) : category;
};

const DevstatProductListItem = ({ item, index }) => {
  const { state } = useContext(filterContextDevstat);
  const [page] = useState(state.page);
  const [limit] = useState(state.limit);
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);

  const nr = (page - 1) * limit + index + 1;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        className={classes.header}
      >
        <Grid item xs={3}>
          <Typography variant="body1" component="span">
            {nr}.
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={classes.productName}
          >
            {item.designer ? item.designer : "!!! BRAK PROJEKTANTA !!!"}
            {item.sezonFull ? item.sezonFull : "!!! BRAK SEZONU !!!"}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={classes.productName}
          >
            {item.is_co ? "CARRY OVER" : ""}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">{item.nazwa}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            {item.category
              ? getCategoryPath(item.category)
              : "!!! BRAK  KATEGORI !!!"}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item className={classes.photoContainer}>
          <img
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            src={
              item.photo.length
                ? item.photo
                : process.env.PUBLIC_URL + "no-img.png"
            }
            className={classes.photoThumbnail}
            alt="Product"
          />
          {isShown && item.photo.length > 0 ? (
            <img src={item.photo} alt="Product" className={classes.photo} />
          ) : null}
        </Grid>

        <Grid
          item
          xs
          container
          direction="column"
          className={classes.noMarginY}
        >
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Cena bazowa</Typography>
            <Typography variant="body1">{item.basePriceNetto} PLN</Typography>
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Stan</Typography>
            <Typography variant="body1">{item.stock} szt.</Typography>
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Pokaż na stronie</Typography>
            <Typography variant="body1">
              {item.polkaId && item.photo ? (
                <Link
                  href={`https://vitkac.com/sp/${item.polkaId}`}
                  target="_blank"
                  underline="none"
                  color="primary"
                >
                  Vitkac <LinkIcon fontSize="small" />
                </Link>
              ) : (
                ""
              )}
            </Typography>
          </Grid>
          {item.idFarfetch ? (
            <Grid item className={classes.item}>
              <Typography variant="subtitle2">Pokaż na stronie</Typography>
              <Typography variant="body1">
                <Link
                  href={
                    "http://54.37.0.134/r.php?u=https://farfetch.com/pl/shopping/item-" +
                    item.idFarfetch +
                    ".aspx"
                  }
                  target="_blank"
                  underline="none"
                  color="primary"
                >
                  Farfetch <LinkIcon fontSize="small" />
                </Link>
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          <Grid item className={classes.item}>
            <Button
              variant="contained"
              color="primary"
              href={`/devstat/show/${item.id}`}
              target="_blank"
            >
              SHOW DETAIL
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DevstatProductListItem;
