import React, {useContext} from "react";
import {filterContext, SET_TOW_GRUP_ID} from "../../../../_context/filterContext";

export const ShowProductItemFilterProvider = ({ children, tg }) => {
  const {
    dispatch,
    state: { _updateTowGrupId }
  } = useContext(filterContext);

  dispatch({ type: SET_TOW_GRUP_ID, payload: tg.id });

  return <>{_updateTowGrupId ? children : null}</>;
};

export default ShowProductItemFilterProvider;
