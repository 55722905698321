import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LoginRequest } from "../../_services/auth";
import { LOGIN } from "../../_actions/authActions";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Helmet } from "react-helmet";
import { ERROR, SUCCESS } from "../../_actions/alertsActions";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://vitkac.com/">
        Vitkac
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const submit = event => {
    event.preventDefault();
    event.stopPropagation();

    const loginForm = {
      username: email,
      password: password
    };

    LoginRequest(loginForm)
      .then(it => {
        dispatch({ type: LOGIN, payload: it.data });
        dispatch({ type: SUCCESS, payload: "Zostałeś poprawnie zalogowany" });
        history.push("/bestseller");
      })
      .catch(err =>
        dispatch({ type: ERROR, payload: "Błąd logowania spróbuj jeszcze raz" })
      );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={process.env.PUBLIC_URL + "/vitkac-logo.png"} alt="" />
        <br />
        <Typography component="h1" variant="h6">
          Logowanie
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={event => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={event => setPassword(event.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={event => submit(event)}
          >
            Zaloguj
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
