import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {DefaultTooltipContent} from "recharts/lib/component/DefaultTooltipContent";
import NumberFormat from "react-number-format";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: theme.chartPaper,
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const OrdersChartCurrency = ({ data }) => {
  const classes = useStyles();

  const currencyArray = [];
  data.map(it => {
    const ret = {
      fullDate: it.fullDate,
      pln: 0,
      eur: 0,
      usd: 0,
      gbp: 0,
      chf: 0,
      dkk: 0,
      nok: 0,
      sek: 0
    };

    it.currency.map(itemCurr => {
      if (itemCurr.currency === "PLN") {
        ret.pln = itemCurr.priceNetto;
      }

      if (itemCurr.currency === "EUR") {
        ret.eur = itemCurr.priceNetto;
      }
      if (itemCurr.currency === "USD") {
        ret.usd = itemCurr.priceNetto;
      }
      if (itemCurr.currency === "GBP") {
        ret.gbp = itemCurr.priceNetto;
      }
      if (itemCurr.currency === "CHF") {
        ret.chf = itemCurr.priceNetto;
      }
      if (itemCurr.currency === "DKK") {
        ret.dkk = itemCurr.priceNetto;
      }
      if (itemCurr.currency === "NOK") {
        ret.nok = itemCurr.priceNetto;
      }
      if (itemCurr.currency === "SEK") {
        ret.sek = itemCurr.priceNetto;
      }

      return {};
    });

    currencyArray.push(ret);
    return {};
  });

  const maxVal = currencyArray.reduce((acc, it) => {
    let newVal = acc;

    if (it.pln > newVal) {
      newVal = it.pln;
    }

    if (it.eur > newVal) {
      newVal = it.eur;
    }

    if (it.usd > newVal) {
      newVal = it.usd;
    }

    if (it.gbp > newVal) {
      newVal = it.gbp;
    }

    if (it.chf > newVal) {
      newVal = it.chf;
    }

    if (it.dkk > newVal) {
      newVal = it.dkk;
    }

    if (it.nok > newVal) {
      newVal = it.nok;
    }

    if (it.sek > newVal) {
      newVal = it.sek;
    }

    return Round(newVal, 0);
  }, 0);

  const minVal = currencyArray.reduce((acc, it) => {
    let newVal = acc;

    if (it.pln < newVal) {
      newVal = it.pln;
    }

    if (it.eur < newVal) {
      newVal = it.eur;
    }

    if (it.usd < newVal) {
      newVal = it.usd;
    }

    if (it.gbp < newVal) {
      newVal = it.gbp;
    }

    if (it.chf < newVal) {
      newVal = it.chf;
    }

    if (it.dkk < newVal) {
      newVal = it.dkk;
    }

    if (it.nok < newVal) {
      newVal = it.nok;
    }

    if (it.sek < newVal) {
      newVal = it.sek;
    }

    return Round(newVal, 0);
  }, 0);

  function Round(n, k) {
    var factor = Math.pow(10, k);
    return Math.round(n * factor) / factor;
  }

  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }

    const newPayload = [
      {
        name: "EUR",
        value: (
          <NumberFormat
            value={props.payload[0].payload.eur}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "PLN",
        value: (
          <NumberFormat
            value={props.payload[0].payload.pln}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "USD",
        value: (
          <NumberFormat
            value={props.payload[0].payload.usd}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "GBP",
        value: (
          <NumberFormat
            value={props.payload[0].payload.gbp}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "CHF",
        value: (
          <NumberFormat
            value={props.payload[0].payload.chf}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "DKK",
        value: (
          <NumberFormat
            value={props.payload[0].payload.dkk}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "NOK",
        value: (
          <NumberFormat
            value={props.payload[0].payload.nok}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "SEK",
        value: (
          <NumberFormat
            value={props.payload[0].payload.sek}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      }
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h2">
          Wartość sprzedaży netto w poszczególnych walutach
        </Typography>
        <div style={{ width: "100%", height: 600 }}>
          <ResponsiveContainer>
            <LineChart
              data={currencyArray}
              margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
            >
              <XAxis dataKey="fullDate" />
              <YAxis dataKey="eur" domain={[minVal, maxVal]} />
              <YAxis dataKey="pln" domain={[minVal, maxVal]} />
              <YAxis dataKey="usd" domain={[minVal, maxVal]} />
              <YAxis dataKey="gbp" domain={[minVal, maxVal]} />
              <YAxis dataKey="chf" domain={[minVal, maxVal]} />
              <YAxis dataKey="dkk" domain={[minVal, maxVal]} />
              <YAxis dataKey="nok" domain={[minVal, maxVal]} />
              <YAxis dataKey="sek" domain={[minVal, maxVal]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                payload={[
                  {
                    value: "EUR",
                    type: "square",
                    color: "#ff9900"
                  },
                  {
                    value: "PLN",
                    type: "square",
                    color: "#330099"
                  },
                  {
                    value: "USD",
                    type: "square",
                    color: "#006600"
                  },
                  {
                    value: "GBP",
                    type: "square",
                    color: "#ff0033"
                  },
                  {
                    value: "CHF",
                    type: "square",
                    color: "#FF00FF"
                  },
                  {
                    value: "DKK",
                    type: "square",
                    color: "##00FFFF"
                  },
                  {
                    value: "NOK",
                    type: "square",
                    color: "#333333"
                  },
                  {
                    value: "SEK",
                    type: "square",
                    color: "#800080"
                  }
                ]}
              />
              <Line type="monotone" dataKey="eur" stroke="#ff9900" />
              <Line type="monotone" dataKey="pln" stroke="#330099" />
              <Line type="monotone" dataKey="usd" stroke="#006600" />
              <Line type="monotone" dataKey="gbp" stroke="#ff0033" />
              <Line type="monotone" dataKey="chf" stroke="#FF00FF" />
              <Line type="monotone" dataKey="dkk" stroke="#00FFFF" />
              <Line type="monotone" dataKey="nok" stroke="#333333" />
              <Line type="monotone" dataKey="sek" stroke="#ff0033" />
              <Brush dataKey="fullDate" height={30} stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Paper>
    </div>
  );
};

export default OrdersChartCurrency;
