import React, { useContext, useEffect } from "react";
import { RadioComponent } from "../Widget/RadioComponent";
import { useState } from "react";
import { filterContext } from "../../_context/filterContext";
import { makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const PaidFilter = () => {
  const {
    state: { isPaid, _update }
  } = useContext(filterContext);

  const [isP, setIsP] = useState(isPaid);

  useEffect(() => {
    setIsP(parseInt(isPaid, 10));
  }, [isPaid, _update]);

  const options = [
    {
      display: "Opłacone + Nieopłacone",
      value: 2
    },
    {
      display: "Tylko Opłacone",
      value: 1
    },
    {
      display: "Tylko Nieopłacone",
      value: 0
    }
  ];

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <input type="hidden" value={isP} name="isPaid" />
      <Typography variant="h2">Faktura VAT</Typography>
      <RadioComponent
        name="isPaid"
        options={options}
        state={isP}
        setState={setIsP}
      />
    </Paper>
  );
};
