import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import TableComponent from "../../../Bestseller/components/TableComponent";
import NoItemAlert from "./NoItemAlert";

export const GET_DOK_SPRZ_RAZEM_BYID = gql`
  query SprzedazDokRazems($id: [Int]) {
    sprzedazDokRazems(towarGrupowanie_id_list: $id) {
      documentId
      documentNmr
      polkaId
      dataZamowienia
      dataFv
      countryCity
      country {
        code
      }
      qty
      sygnatura {
        name
      }
      towar {
        rozmiar
      }
      isCo
      sezon
      priceNetto
      priceBrutto
      percentRabat
      rabatProcPolka
      uniIndeks
    }
  }
`;

const DevstatShowDokSprzRazem = ({ id }) => {
  const { loading, error, data } = useQuery(GET_DOK_SPRZ_RAZEM_BYID, {
    variables: { id }
  });

  if (error) return `Error! ${error}`;
  if (loading) return <></>;

  const headers = [
    {
      id: "documentNmr",
      numeric: false,
      disablePadding: true,
      label: "DocumentNmr"
    },
    {
      id: "documentId",
      numeric: true,
      disablePadding: true,
      label: "DocumentId"
    },
    {
      id: "polkaId",
      numeric: true,
      disablePadding: true,
      label: "PolkaId"
    },
    {
      id: "rozmiar",
      numeric: true,
      disablePadding: true,
      label: "Rozmiar"
    },
    {
      id: "qty",
      numeric: true,
      disablePadding: true,
      label: "Ilość"
    },
    {
      id: "sygnatura",
      numeric: true,
      disablePadding: true,
      label: "Sygnatura"
    },
    {
      id: "dataZam",
      numeric: true,
      disablePadding: true,
      label: "DataZamówienia"
    },
    {
      id: "dataFv",
      numeric: true,
      disablePadding: true,
      label: "DataFv"
    },
    {
      id: "country",
      numeric: true,
      disablePadding: true,
      label: "Country"
    },
    {
      id: "isCo",
      numeric: true,
      disablePadding: true,
      label: "IsCo"
    },
    {
      id: "sezon",
      numeric: true,
      disablePadding: true,
      label: "Sezon"
    },
    {
      id: "priceNetto",
      numeric: true,
      disablePadding: true,
      label: "priceNetto"
    },
    {
      id: "priceBrutto",
      numeric: true,
      disablePadding: true,
      label: "priceBrutto"
    },
    {
      id: "percentRabat",
      numeric: true,
      disablePadding: true,
      label: "percentRabat"
    },
    {
      id: "rabatProcPolka",
      numeric: true,
      disablePadding: true,
      label: "rabatProcPolka"
    },
    {
      id: "isKsk",
      numeric: true,
      disablePadding: true,
      label: "isKsk"
    },
    {
      id: "uniIndeks",
      numeric: false,
      disablePadding: true,
      label: "uniIndeks"
    }
  ];

  function createData(
    documentNmr,
    documentId,
    polkaId,
    rozmiar,
    qty,
    sygnatura,
    dataZam,
    dataFv,
    country,
    isCo,
    sezon,
    priceNetto,
    priceBrutto,
    percentRabat,
    rabatProcPolka,
    isKsk,
    uniIndeks
  ) {
    return {
      documentNmr,
      documentId,
      polkaId,
      rozmiar,
      qty,
      sygnatura,
      dataZam,
      dataFv,
      country,
      isCo,
      sezon,
      priceNetto,
      priceBrutto,
      percentRabat,
      rabatProcPolka,
      isKsk,
      uniIndeks
    };
  }

  const rows = data.sprzedazDokRazems.map(it =>
    createData(
      it.documentNmr,
      it.documentId,
      it.polkaId,
      it.towar.rozmiar,
      it.qty,
      it.sygnatura.name,
      it.dataZamowienia,
      it.dataFv,
      it.country ? it.country.code : "-",
      it.isCo === true ? "true" : "false",
      it.sezon ? it.sezon : "-",
      it.priceNetto,
      it.priceBrutto,
      it.percentRabat,
      it.rabatProcPolka,
      it.isKsk === true ? "true" : "false",
      it.uniIndeks
    )
  );

  return (
    <>
      {data.sprzedazDokRazems.length ? (
        <TableComponent
          headCellsData={headers}
          rowsData={rows}
        ></TableComponent>
      ) : (
        <NoItemAlert text="brak danych dla sprzedazy" />
      )}
    </>
  );
};

export default DevstatShowDokSprzRazem;
