import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../../_context/filterContext";
import { getLokalizacje } from "../../../_services/_bestsellerService";
import TableComponent from "../components/TableComponent";
import Chart from "react-google-charts";
import Grid from "@material-ui/core/Grid";
import TablePlaceholder from "../../../_components/TablePlaceholder";

export const LokalizacjeComponent = ({ switchValue, id }) => {
  const { state } = useContext(filterContext);
  const [lokalizacje, setLokalizacje] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getLokalizacje(switchValue ? state : { isPaid: 1 }, id)
      .then(res => {
        setLokalizacje(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state, id, switchValue]);

  const headers = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Kraj"
    },
    {
      id: "code",
      numeric: true,
      disablePadding: false,
      label: "Kod"
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane - Zwrócone [szt.]"
    },
    {
      id: "sell",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane [szt.]"
    },
    {
      id: "returned",
      numeric: true,
      disablePadding: false,
      label: "Zwrócone [szt.]"
    }
  ];

  function createData(name, code, total, sell, returned) {
    return { name, code, total, sell, returned };
  }

  const rows = lokalizacje.map(it =>
    createData(it.name, it.code, it.qtyTotal, it.qtySell, it.qtyReturned)
  );

  const headerChart = ["Kraj", "Sprzedane"];
  const sygnaturaDataChart = lokalizacje.map(it => [
    it.code,
    parseInt(it.qtySell)
  ]);

  const data = [headerChart, ...sygnaturaDataChart];

  return (
    <>
      <Grid item xs={7}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <TableComponent
            headCellsData={headers}
            rowsData={rows}
          ></TableComponent>
        )}
      </Grid>
      <Grid item xs={5}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <Chart
            width={"500px"}
            height={"300px"}
            chartType="GeoChart"
            data={data}
            mapsApiKey="YOUR_KEY_HERE"
            rootProps={{ "data-testid": "1" }}
          />
        )}
      </Grid>
    </>
  );
};
