import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    alignItems: "center",
    flex: "1 1 0%"
  },
  text: {
    margin: "0 0 8px",
    fontSize: "1.25em"
  },
  button: {
    padding: "4px 12px"
  }
}));

const NoProductAlert = ({ setView }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        height: "calc(100vh - 100px)",
        marginTop: "calc(80px / -2)"
      }}
    >
      <Typography variant="h2" className={classes.text}>
        Brak wyników wyszukiwania
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={e => setView(0)}
        className={classes.button}
      >
        Wyszukaj ponownie
      </Button>
    </div>
  );
};

export default NoProductAlert;
