import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  text: {
    color: "red"
  }
}));

const NoItemAlert = ({ text }) => {
  const classes = useStyles();

  return <div className={classes.text}>{text.toUpperCase()}</div>;
};

export default NoItemAlert;
