import React, { useState } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: "3px",
    lineHeight: 1.7,
    boxSizing: "border-box",
    minWidth: 0,
    backgroundColor: "white",
    padding: "8px 0 6px",
    "&:disabled": {
      backgroundColor: "#e0e0e0"
    }
  },
  buttonIcon: {
    height: "14px"
  },
  page: {
    padding: "5px 2px",
    fontSize: "0.85em"
  }
}));

const useLogItemPagination = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const handleChangePage = value => {
    const nextPage = page + value;
    nextPage >= 1 ? setPage(nextPage) : setPage(1);
  };

  const component = (
    <Grid container direction="row" alignItems="flex-end">
      <Grid item>
        <div className={classes.page}>Strona {page}</div>
      </Grid>
      <Grid item>
        <Button onClick={e => handleChangePage(-1)} className={classes.button}>
          <ArrowBackIosRoundedIcon className={classes.buttonIcon} />
        </Button>
      </Grid>

      <Grid item>
        <Button onClick={e => handleChangePage(1)} className={classes.button}>
          <ArrowForwardIosRoundedIcon className={classes.buttonIcon} />
        </Button>
      </Grid>
    </Grid>
  );

  return [component, page];
};

export default useLogItemPagination;
