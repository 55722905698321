import {Button, Grid, Link, makeStyles, Typography} from "@material-ui/core";
import React, {useContext, useState} from "react";
import {filterContext} from "../../_context/filterContext";
import LinkIcon from "@material-ui/icons/Link";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import {SUCCESS} from "../../_actions/alertsActions";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #37474f",
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "0.8rem",
      color: "#6f6f6f"
    },
    "& .MuiGrid-spacing-xs-7": {
      paddingBottom: theme.spacing(6),
      width: `calc(100% + ${theme.spacing(4)}px)`
    }
  },
  header: {
    backgroundColor: "#37474f",
    color: "white",
    padding: "7px 10px 9px",
    textTransform: "uppercase"
  },
  productName: {
    fontWeight: 500,
    marginLeft: "10px"
  },
  divider: {
    marginTop: "6px",
    marginBottom: "6px",
    backgroundColor: "rgba(216, 216, 216, 0.26)"
  },
  noMarginY: theme.noMarginY,
  item: {
    position: "relative",
    border: `1px solid ${theme.palette.border}`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    "&:nth-of-type(2n)": {
      marginTop: theme.spacing(3)
    },
    "&:nth-of-type(2n + 1)": {
      marginTop: 0
    },
    "& .MuiTypography-body1": {
      lineHeight: 1
    }
  },
  photo: {
    position: "absolute",
    borderRadius: "3px",
    border: `1px solid ${theme.palette.border}`,
    top: "-100%",
    left: "165px",
    width: "400px",
    height: "auto",
    zIndex: 999,
    transition: "all 0.5s ease-out"
  },
  photoThumbnail: {
    maxWidth: "110%",
    maxHeight: "150px",
    marginLeft: "15px",
    marginTop: "5px"
  },
  photoContainer: {
    position: "relative",
    minHeight: "115px",
    paddingTop: "0!important"
  },
  danger: {
    fontSize: "1em",
    color: "#e60000",
    lineHeight: 1.2,
    fontWeight: 500
  },
  overlineText: {
    position: "absolute",
    top: theme.spacing(-1.5),
    left: theme.spacing(1),
    backgroundColor: "white",
    padding: theme.spacing(0.5)
  },
  oneLine: {
    marginTop: "9px",
    marginBottom: theme.spacing(1)
  },
  itemName: {
    marginLeft: theme.spacing(-5)
  },
  itemCategory: {
    marginLeft: theme.spacing(-2)
  },
  productInfo: {
    marginTop: "25px!important"
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 1
  }
}));

const getCategoryPath = category => {
  return category && category.match(/^\d/) ? category.substring(1) : category;
};

const copyToClipboard = (text, dispatch) => {
  const el = document.createElement("textarea");
  el.value = text.trim();
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  dispatch({ type: SUCCESS, payload: "Skopiowano" });
};

const RealTimeListItem = ({ item, index }) => {
  const { state } = useContext(filterContext);
  const [limit] = useState(state.limit);
  const [page] = useState(state.page);
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);
  const { isDevStat } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const nr = (page - 1) * limit + index + 1;
  const status = item.qtyTotal > 0 ? "Sprzedaz" : "Zwrot";

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        className={classes.header}
      >
        <Grid item xs={4}>
          <Typography variant="body1" component="span">
            {nr}.
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={classes.productName}
          >
            {item.designer} {item.sezonFull}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={classes.productName}
          >
            {item.isCo ? "CARRY OVER" : ""}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.itemName}>
          <div className={classes.tooltipContainer}>
            <Tooltip
              title={"kopiuj do schowka"}
              aria-label="add"
              onClick={() => copyToClipboard(item.photoSid, dispatch)}
            >
              <Typography variant="body1">{item.photoSid}</Typography>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={4} className={classes.itemCategory}>
          <Typography variant="body1">
            {getCategoryPath(item.category)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={7}
        className={classes.productInfo}
      >
        <Grid item xs={2} lg={1} className={classes.photoContainer}>
          <img
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            src={
              item.photo.length
                ? item.photo
                : process.env.PUBLIC_URL + "no-img.png"
            }
            className={classes.photoThumbnail}
            alt="Product"
          />
          {isShown ? (
            <img src={item.photo} alt="Product" className={classes.photo} />
          ) : null}
        </Grid>

        <Grid
          item
          xs={3}
          container
          direction="column"
          className={classes.noMarginY}
          spacing={4}
        >
          <Grid item container className={classes.item}>
            <Typography variant="overline" className={classes.overlineText}>
              Cena bazowa
            </Typography>
            <Grid item xs={6}>
              <Typography variant="subtitle2">NETTO</Typography>
              <Typography variant="body1">{item.basePriceNetto} PLN</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">BRUTTO</Typography>
              <Typography variant="body1">
                {item.basePriceBrutto} PLN
              </Typography>
            </Grid>
          </Grid>
          <Grid item container className={classes.item}>
            <Typography variant="overline" className={classes.overlineText}>
              Data
            </Typography>
            <Grid item xs={6}>
              <Typography variant="subtitle2">ZAMOWIENIA</Typography>
              <Typography variant="body1">{item.dataZamowienia}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">FV</Typography>
              <Typography variant="body1">{item.timeDataFv}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          container
          direction="column"
          className={classes.noMarginY}
          spacing={4}
        >
          <Grid item container className={classes.item}>
            <Typography variant="overline" className={classes.overlineText}>
              Wartość sprzedaży
            </Typography>
            <Grid item xs={3}>
              <Typography variant="subtitle2">NETTO</Typography>
              <Typography variant="body1">{item.priceNetto} PLN</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2">BRUTTO</Typography>
              <Typography variant="body1">{item.priceBrutto} PLN</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" className={classes.danger}>
                RABAT
              </Typography>
              <Typography
                variant="body1"
                className={(classes.bold, classes.danger)}
              >
                {item.percentRabat}%
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" className={classes.danger}>
                VAT
              </Typography>
              <Typography
                variant="body1"
                className={(classes.bold, classes.danger)}
              >
                {item.vatRate}%
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2">OPŁACONE:</Typography>
              <Typography
                variant="body1"
                // className={item.czyOplacone === false ? classes.danger : ""}
              >
                {item.czyOplacone === false ? "NIE" : "TAK"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container className={classes.item}>
            <Typography variant="overline" className={classes.overlineText}>
              Towar
            </Typography>
            <Grid item xs={3}>
              <Typography variant="subtitle2">ILOŚĆ</Typography>
              <Typography variant="body1">{item.qtyTotal}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2">Status</Typography>
              <Typography variant="body1">{status}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2">Rozmiar</Typography>
              <Typography variant="body1">{item.rozmiar}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2">Sygnatura</Typography>
              <Typography variant="body1">{item.sygnatura}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="column"
          className={classes.noMarginY}
          spacing={4}
        >
          <Grid item container className={classes.item}>
            <Typography variant="overline" className={classes.overlineText}>
              Klient
            </Typography>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Kraj</Typography>
              <Typography variant="body1">{item.country}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2">Miasto</Typography>
              <Typography variant="body1">{item.city}</Typography>
            </Grid>
          </Grid>
          <Grid item container className={classes.item}>
            <Typography variant="overline" className={classes.overlineText}>
              Pokaż na stronie
            </Typography>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.oneLine}>
                {item.polkaId && item.photo ? (
                  <Link
                    href={`https://vitkac.com/sp/${item.polkaId}`}
                    target="_blank"
                    underline="none"
                    color="primary"
                  >
                    Vitkac <LinkIcon fontSize="small" />
                  </Link>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.oneLine}>
                <Link
                  href={
                    "http://54.37.0.134/r.php?u=https://farfetch.com/pl/shopping/item-" +
                    item.idFarfetch +
                    ".aspx"
                  }
                  target="_blank"
                  underline="none"
                  color="primary"
                >
                  Farfetch <LinkIcon fontSize="small" />
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isDevStat ? (
        <Grid>
          <Button variant="contained">
            <Link
              href={`/devstat/show/${item.towGrup_id}`}
              underline="none"
              color="primary"
              target="_blank"
            >
              Show Devstat
            </Link>
          </Button>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default RealTimeListItem;
