import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NoItemAlert from "./NoItemAlert";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold"
  }
});

const DevstatShowProductTable = ({ tg }) => {
  const classes = useStyles();

  function createData(nazwa, wartosc) {
    return { nazwa, wartosc };
  }

  const rows = [
    createData("ID", tg.id),
    createData("NAZWA", tg.nazwa),
    createData(
      "DESIGNER",
      tg.designer.length ? (
        tg.designer
      ) : (
        <NoItemAlert text="---brak projektanta---" />
      )
    ),
    createData(
      "CATEGORY",
      tg.category.length ? (
        tg.category
      ) : (
        <NoItemAlert text="---brak kategorii---" />
      )
    ),
    createData("SYMBOL", tg.vitkacSymbol),
    createData("ACTIVE", tg.isActive === true ? "TAK" : "NIE"),
    createData("STOCK", tg.stock),
    createData("PODKOLEKCJA", tg.podkolekcja),
    createData("CO", tg.isCo === true ? "TAK" : "NIE"),
    createData("SEZON", tg.sezon),
    createData(
      "POLKA IDS W GRUPIE:",
      tg.polkaIds.map(it => {
        return it + " ";
      })
    )
  ];

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.nazwa}>
              <TableCell component="th" scope="row" className={classes.bold}>
                {row.nazwa}
              </TableCell>
              <TableCell>{row.wartosc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevstatShowProductTable;
