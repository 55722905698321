import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {DefaultTooltipContent} from "recharts/lib/component/DefaultTooltipContent";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {DefaultLegendContent} from "recharts/lib/component/DefaultLegendContent";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    ...theme.chartPaper,
    boxShadow: "none",
    padding: 0
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  title: {
    marginBottom: "15px"
  }
}));

const RealTimeSummaryChart = ({
  data,
  dataTotal,
  filters,
  title,
  legendTotalName
}) => {
  const classes = useStyles();
  const [spaceTopTooltip, setSpaceTopTooltip] = useState(0);

  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }

    setSpaceTopTooltip(0);

    const round = (n, k) => {
      const factor = Math.pow(10, k + 1);
      n = Math.round(Math.round(n * factor) / 10);
      return n / (factor / 10);
    };

    const separatorFunction = x => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    let newPayload = [];

    if (!props.payload) return null;

    if (props.payload.length > 10) {
      setSpaceTopTooltip(-200);
    }

    props.payload.map(it => {
      let price = separatorFunction(round(it.value, 2)) + " PLN";

      const objPrice = {
        name: `Cena netto "${it.name}": ${price}  | Ilość`,
        value: `${it.payload.qtyTotal} szt.`,
        color: it.color
      };

      // const objIlosc = {
      //   name: `Ilość "${it.name}"`,
      //   value: `${it.payload.qtyTotal} szt.`,
      //   color: it.color
      // };

      return (newPayload = [...newPayload, objPrice]);
    });

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  const CustomLegend = props => {
    let newPayload = [];
    props.payload.map(it => {
      const obj = {
        value: it.value,
        type: "square",
        color: it.color
      };
      return (newPayload = [...newPayload, obj]);
    });

    return <DefaultLegendContent {...props} payload={newPayload} />;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.title}>
          <span style={{ fontWeight: 500 }}>{title.toUpperCase()} </span>( suma
          netto PLN)
        </div>
        <div
          style={{
            width: "100%",
            height: 300
          }}
        >
          <ResponsiveContainer>
            <LineChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <XAxis dataKey={"hour"} allowDuplicatedCategory={false} />
              <YAxis
                dataKey={v => parseInt(v.priceNetto)}
                allowDuplicatedCategory={true}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                style={{ height: 200 }}
                content={<CustomTooltip />}
                wrapperStyle={{
                  zIndex: 9999,
                  fontSize: 11,
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  top: spaceTopTooltip
                }}
              />
              <Legend content={<CustomLegend />} />

              {data.online && filters.online
                ? Object.keys(data.online).map((key, index) => {
                    return (
                      <Line
                        type="monotone"
                        data={data.online[key].data}
                        dataKey="priceNetto"
                        name={data.online[key].name}
                        key={data.online[key].color + index}
                        stroke={data.online[key].color}
                      />
                    );
                  })
                : null}
              {data.stationary && filters.stacjonarne
                ? Object.keys(data.stationary).map((key, index) => {
                    return (
                      <Line
                        type="monotone"
                        data={data.stationary[key].data}
                        dataKey="priceNetto"
                        name={data.stationary[key].name}
                        key={index + "-stationary"}
                        stroke={data.stationary[key].color}
                      />
                    );
                  })
                : null}
              {data.other && filters.inne
                ? Object.keys(data.other).map((key, index) => {
                    return (
                      <Line
                        type="monotone"
                        data={data.other[key].data}
                        dataKey="priceNetto"
                        name={data.other[key].name}
                        key={index + "-other"}
                        stroke={data.other[key].color}
                      />
                    );
                  })
                : null}
              {dataTotal && filters.total ? (
                <Line
                  type="monotone"
                  data={dataTotal}
                  dataKey="priceNetto"
                  name={legendTotalName}
                  key={"total"}
                  stroke={"#00008B"}
                />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Paper>
    </div>
  );
};

export default RealTimeSummaryChart;
