import Request from "./Request";

export const getSaleDesigner = (filters = {}) => {
  return Request(true).get("/api/sale/designer", {
    params: filters
  });
};

export const getSaleLokalizacje = (filters = {}) => {
  return Request(true).get("/api/sale/lokalizacje", {
    params: filters
  });
};

export const getSaleSygnatury = (filters = {}) => {
  return Request(true).get("/api/sale/sygnatura", {
    params: filters
  });
};

export const getSaleKategorie = (filters = {}) => {
  return Request(true).get("/api/sale/categories", {
    params: filters
  });
};

export const getSale = (filters = {}) => {
  return Request(true).get("/api/sale/", {
    params: filters
  });
};

export const getSaleRabatDetailByDate = (filters, date) => {
  return Request(true).get("/api/sale/rabat/" + date, {
    params: filters
  });
};

export const getSaleSeasonDetailByDate = (filters, date) => {
  return Request(true).get("/api/sale/season/" + date, {
    params: filters
  });
};

export const getLevel3Kat = (id, filters) => {
  return Request(true).get("/api/sale/categories/" + id, {
    params: filters
  });
};

export const getSaleKategorieThirdLevelInLine = (filters = {}) => {
  return Request(true).get("/api/sale/categories/in_line", {
    params: filters
  });
};
