import React, {useContext, useState} from "react";
import {filterContextDevstat} from "../../../../_context/filterContextDevstat";
import {Grid, makeStyles, Paper, TextField, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "10px"
  }
}));

export const SezonFilter = () => {
  const classes = useStyles();

  const {
    state: { seasons }
  } = useContext(filterContextDevstat);

  const [productSeasons, setProductSeasons] = useState(seasons);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Sezony</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            key="sezony"
            label="Sezon"
            value={productSeasons || ""}
            variant="outlined"
            onChange={event => setProductSeasons(event.target.value)}
            name="seasons"
            className={classes.inputField}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
