import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import SelectedList from "./SelectedList";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { Indicator } from "../Indicator";

const GET_SEASONS = gql`
  {
    seasons {
      id
      _id
      name
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const SeasonFilter = () => {
  const token = localStorage.getItem("token");
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_SEASONS, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });
  const {
    state: { seasons, _update }
  } = useContext(filterContext);

  const [selected, setSelected] = useState(
    seasons.length ? seasons.split(",").map(it => parseInt(it, 10)) : ""
  );
  useEffect(() => {
    setSelected(
      seasons.length ? seasons.split(",").map(it => parseInt(it, 10)) : ""
    );
  }, [seasons, _update]);

  if (loading) return <Indicator centerVertically height="380px"></Indicator>;
  if (error) return "Cannot load Seeason filter";

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Sezon</Typography>
      <input type="hidden" value={selected} name="seasons" />
      <SelectedList
        data={data.seasons}
        selected={selected}
        setSelected={setSelected}
        keyName={"season"}
        nameKey={"name"}
      />
    </Paper>
  );
};
