import React, { useContext, useEffect } from "react";
import { RadioComponent } from "../Widget/RadioComponent";
import { useState } from "react";
import { filterContext } from "../../_context/filterContext";
import { makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const CoFilter = () => {
  const {
    state: { isCo, _update }
  } = useContext(filterContext);

  const [is_Co, setIsCo] = useState(isCo);

  useEffect(() => {
    setIsCo(parseInt(isCo, 10));
  }, [isCo, _update]);

  const options = [
    {
      display: "Carry Over + sprzedaż sezonowa",
      value: 2
    },
    {
      display: "Tylko Carry Over",
      value: 1
    },
    {
      display: "Tylko sprzedaż sezonowa",
      value: 0
    }
  ];

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <input type="hidden" value={isCo} name="isCo" />
      <Typography variant="h2">Carry Over</Typography>
      <RadioComponent
        name="isCo"
        options={options}
        state={is_Co}
        setState={setIsCo}
      />
    </Paper>
  );
};
