import { combineReducers } from "redux";
import auth from "./authReducer";
import alerts from "./alertsReducer";
import filters from "./filtersReducer";

const rootReducer = combineReducers({
  auth,
  alerts,
  filters
});

export default rootReducer;
