import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {DefaultTooltipContent} from "recharts/lib/component/DefaultTooltipContent";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginLeft: "12px"
  },
  paper: theme.chartPaper,
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  h2: {
    marginBottom: "12px",
    fontSize: "1.2em"
  }
}));

const SaleKategorieChart = ({ data }) => {
  const classes = useStyles();

  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }

    const newPayload = [
      {
        name: "Suma netto [PLN]",
        value: props.payload[0].payload.priceNetto
      }
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.h2}>
        Suma netto [PLN]
      </Typography>
      <div style={{ width: "calc(100% - 12px)", height: 300 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="p2" />
            <YAxis dataKey="priceNetto" />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              payload={[
                {
                  value: "Suma netto [PLN]",
                  type: "square",
                  color: "#112E51"
                }
              ]}
            />
            <Bar dataKey="priceNetto" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SaleKategorieChart;
