import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import Paper from "@material-ui/core/Paper";
import DevstatShowProductTable from "./DevstatShowProductTable";
import DevstatShowTableRozmiary from "./DevstatShowTableRozmiary";
import DevstatKategoriaItem from "./DevstatKategoriaItem";
import NoItemAlert from "./NoItemAlert";
import BestsellerList from "../../../Bestseller/BestsellerList";
import DevstatShowDokSprzRazem from "./DevstatShowDokSprzRazem";
import DevstatShowDostawy from "./DevstatShowDostawy";
import DevstatShowTowar from "./DevstatShowTowar";
import { synchroRequest } from "../../../../_services/devstatService";
import { ERROR, SUCCESS } from "../../../../_actions/alertsActions";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    "& .MuiTypography-h4": {
      marginTop: theme.spacing(-0.5)
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "0.8rem",
      color: "#6f6f6f"
    },
    "& .MuiTableCell-root": {
      fontSize: "13px"
    }
  },
  paper: theme.filterPaper,
  photoThumbnail: {
    maxWidth: `calc(100% - ${theme.spacing(0.5)}px)`,
    maxHeight: "248px",
    marginLeft: theme.spacing(0.25)
  },
  photoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: "180px"
  },
  info: {
    fontSize: "20px",
    marginTop: "15px",
    textAlign: "justify",
    color: "black"
  },
  button: {
    marginLeft: "5px"
  },
  title: {
    color: "black",
    fontWeight: "400",
    marginBottom: "10px"
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  },
  constHeight: {
    height: "310px"
  },
  photoThumb: {
    width: "235px"
  },
  productName: {
    paddingBottom: "12px",
    "& .MuiTypography-h2": {
      margin: 0
    }
  },
  header: {
    backgroundColor: "#37474f",
    color: "white",
    padding: "7px 10px 9px",
    textTransform: "uppercase"
  },
  itemContainer: {
    border: "1px solid #37474f",
    padding: "0!important",
    margin: "12px"
  },
  itemSubContainer: {
    padding: theme.spacing(2)
  }
}));

const DevstatShowProductItem = ({ tg, setSynchro }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const synchro = 0;

  const makeSynchro = e => {
    synchroRequest(tg.id)
      .then(res => {
        dispatch({ type: SUCCESS, payload: "Synchro Successfull" });
        setSynchro(synchro + 1);
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: ERROR, payload: "Synchro Error" });
      })
      .finally(() => {});
  };

  const catTables = tg.kategorie
    ? Object.keys(tg.kategorie).map((key, index) => {
        return (
          <Grid item xs={3} md={2} key={index}>
            <Paper className={classes.paper}>
              <Typography variant="subtitle2" className={classes.title}>
                Rozmiar: {key}
              </Typography>
              <DevstatKategoriaItem
                key={index}
                kategorie={tg.kategorie[key]}
                nazwa={[key]}
              />
            </Paper>
          </Grid>
        );
      })
    : null;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={`${classes.paper} ${classes.productName}`}>
            <Typography variant="h2">{tg.nazwa}</Typography>
          </Paper>
        </Grid>
        <Grid item className={classes.photoThumb}>
          <Paper className={classes.paper}>
            <Grid container direction="row">
              <Grid item className={classes.photoContainer}>
                <img
                  src={
                    tg.photo && tg.photo.length
                      ? tg.photo
                      : process.env.PUBLIC_URL + "no-img.png"
                  }
                  className={classes.photoThumbnail}
                  alt="Product"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={tg.polkaIds && tg.polkaIds[0] ? false : true}
                  color="primary"
                  href={
                    tg.polkaIds ? `https://vitkac.com/sp/${tg.polkaIds[0]}` : ""
                  }
                  target="_blank"
                >
                  Vitkac <LinkIcon fontSize="small" />
                </Button>
                <Button
                  disabled={tg.idFarfetch ? false : true}
                  color="primary"
                  className={classes.button}
                  href={
                    "http://54.37.0.134/r.php?u=https://farfetch.com/pl/shopping/item-" +
                    tg.idFarfetch +
                    ".aspx"
                  }
                  target="_blank"
                >
                  Farfetch <LinkIcon fontSize="small" />
                </Button>
              </Grid>
              <Grid container item xs={12} justify="flex-start">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={e => makeSynchro(e)}
                >
                  SYNCH
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          <DevstatShowProductTable tg={tg} />
        </Grid>

        <Grid item xs={4} className={classes.itemContainer}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.header}
          >
            <Grid item xs={3}>
              <Typography variant="body1">Rozmiary</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.itemSubContainer}>
            {tg.rozmiary ? (
              <DevstatShowTableRozmiary rozmiary={tg.rozmiary} />
            ) : (
              <NoItemAlert text="brak danych" />
            )}{" "}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.itemContainer}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.header}
          >
            <Grid item xs={3}>
              <Typography variant="body1">Kategorie</Typography>
            </Grid>
            <Grid item xs={3}>
              {tg.category ? (
                <Typography variant="body1" className={classes.title}>
                  {tg.category + " / " + tg.categoryColor}
                </Typography>
              ) : (
                <NoItemAlert text="---brak kategorii---"></NoItemAlert>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.itemSubContainer}>
            {Object.keys(tg.kategorie).length ? (
              <>{catTables}</>
            ) : (
              <NoItemAlert text="brak danych"></NoItemAlert>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} key={tg.id}>
          <BestsellerList setView={0} IsPagination={false} />
        </Grid>
        <Grid item xs={12} className={classes.itemContainer}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.header}
          >
            <Grid item xs={3}>
              <Typography variant="body1">Sprzedaże</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.itemSubContainer}>
            <DevstatShowDokSprzRazem id={tg.id} key={`sprzedaz-${tg.id}`} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.itemContainer}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.header}
          >
            <Grid item xs={3}>
              <Typography variant="body1">Dostawy</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.itemSubContainer}>
            <DevstatShowDostawy id={tg.id} key={`dostawa-${tg.id}`} />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.itemContainer}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.header}
          >
            <Grid item xs={3}>
              <Typography variant="body1">Towar</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.itemSubContainer}>
            <DevstatShowTowar towary={tg.towary} key={`towar-${tg.id}`} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DevstatShowProductItem;
