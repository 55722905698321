import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { HIDE } from "../_actions/alertsActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

export const Alerts = () => {
  const { text, isShow, name } = useSelector(state => state.alerts);
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({ type: HIDE });
  };

  return (
    <div className={classes.root}>
      <Snackbar open={isShow} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={name}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};
