import { makeStyles, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { TasksTabele } from "./TasksTabele";
import { Task2RunTabele } from "./Task2RunTabele";
import { Task2KillTabele } from "./Task2KillTabele";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    backgroundColor: theme.palette.gray,
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    padding: theme.spacing(2),
    boxSizing: "border-box",
    "& .MuiTableCell-root": {
      fontSize: "13px"
    }
  },
  tablePaper: theme.filterPaper
}));

export const TasksComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Panel</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Paper className={classes.tablePaper}>
            <Typography variant="h4">Tasks List</Typography>
            <TasksTabele />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.tablePaper}>
            <Typography variant="h4">Task 2 Start List</Typography>
            <Task2RunTabele />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.tablePaper}>
            <Typography variant="h4">Task 2 Kill List </Typography>
            <Task2KillTabele />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
