import Axios from "axios";
import {errorAlert} from "../_actions/alertsActions";

// eslint-disable-next-line no-native-reassign
export default Request = (isAuth = true, isBlob = false) => {
  const token = localStorage.getItem("apiToken");

  const instance = Axios.create({
    // baseURL: "http://127.0.0.1:8081",
    baseURL: process.env.REACT_APP_API_URL,
    responseType: isBlob ? 'blob' : 'json',
    headers: isAuth
      ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": token
        }
      : {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
  });

  instance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  );

  return instance;
};

const errorHandler = error => {
  if (
    error.status &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    if (isHandlerEnabled(error.config)) {
      errorAlert("Zostałeś wylogowany");
      // alert("Zostałeś wylogowany");
      window.location = "/logout";
    }
  }
  return Promise.reject({ ...error });
};

const successHandler = response => {
  if (isHandlerEnabled(response.config)) {
  }
  return response;
};

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};
