import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getUniIndexInfo } from "../../../_services/devstatService";
import { synchroSprzedazDokRazemRequest } from "../../../_services/devstatLogService";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { ERROR, SUCCESS } from "../../../_actions/alertsActions";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  logPaper: theme.filterPaper,
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    padding: theme.spacing(2)
  }
}));

const CompareDbHurtownia = () => {
  const classes = useStyles();
  let { index } = useParams();
  const [db, setDb] = useState();
  const [hurtowniaDb, setHurtowniaDb] = useState();
  const [fullDb, setFullDb] = useState();
  const [fullPolka, setFullPolka] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [synchro, setSynchro] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getUniIndexInfo(index)
      .then(res => {
        console.log(res);
        setDb(res.data.db);
        setHurtowniaDb(res.data.hurtowniaDb);
        setFullDb(res.data.fullDb);
        setFullPolka(res.data.fullHurtownia);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {};
  }, [index, synchro]);

  const makeSynchro = e => {
    synchroSprzedazDokRazemRequest(fullPolka[0].uni_indeks)
      .then(res => {
        dispatch({ type: SUCCESS, payload: "Synchro Successfull" });
        setSynchro(synchro + 1);
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: ERROR, payload: "Synchro Error" });
      })
      .finally(() => {});
  };

  return (
    <div className={classes.root}>
      <Button variant="contained" color="primary" onClick={e => makeSynchro(e)}>
        SYNCH
      </Button>
      {isLoading ? (
        "Loading"
      ) : db ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Dane z bazy</TableCell>
                {Object.keys(db).map(it => {
                  return <TableCell key={it}>{it}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="db">
                <TableCell>Db</TableCell>
                {Object.values(db).map((row, index) => (
                  <TableCell component="th" scope="row" key={index}>
                    {row}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow key="hurtownia">
                <TableCell>Hurtownia</TableCell>
                {hurtowniaDb
                  ? Object.values(hurtowniaDb).map((row, index) => (
                      <TableCell component="th" scope="row" key={index}>
                        {row}
                      </TableCell>
                    ))
                  : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      <Paper className={classes.logPaper}>
        <Typography variant="h2">Full Data Of DB and Hurtownia</Typography>
        {!isLoading ? (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              {fullDb ? (
                <div>
                  <h3>Dane z Bazy</h3>
                  <pre>{JSON.stringify(fullDb, null, 2)} </pre>
                </div>
              ) : (
                "BRAK DANYCH"
              )}
            </Grid>
            <Grid item xs={6}>
              {fullPolka ? (
                <div>
                  <h3>Dane z Polki</h3>
                  <pre>{JSON.stringify(fullPolka, null, 2)} </pre>
                </div>
              ) : (
                "BRAK DANYCH Z HURTOWNI"
              )}
            </Grid>
          </Grid>
        ) : (
          "Loading"
        )}
      </Paper>
    </div>
  );
};

export default CompareDbHurtownia;
