import React, {useState} from "react";

const ColumnsSortContext = React.createContext();

export default ColumnsSortContext;

export const initData = {
  online: {
    sprzedaz: { name: null, direction: null },
    zwroty: { name: null, direction: null }
  },
  stacjonarne: {
    sprzedaz: { name: null, direction: null },
    zwroty: { name: null, direction: null }
  },
  inne: {
    sprzedaz: { name: null, direction: null },
    zwroty: { name: null, direction: null }
  },
  total: {
    sprzedaz: { name: null, direction: null },
    zwroty: { name: null, direction: null }
  }
};

export const ColumnsSortProvider = ({ children }) => {
  const [sortColumns, setSortColumns] = useState(initData);

  return (
    <ColumnsSortContext.Provider value={{ sortColumns, setSortColumns }}>
      {children}
    </ColumnsSortContext.Provider>
  );
};
