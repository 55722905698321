import React from "react";
import {getUser} from "../../_services/auth";
import {useDispatch} from "react-redux";
import {LOGOUT, SET_USER} from "../../_actions/authActions";

export const Auth = ({ children }) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("apiToken");
  const userId = localStorage.getItem("id");

  if (token && userId) {
    getUser(userId)
      .then(it => {
        dispatch({
          type: SET_USER,
          payload: it.data
        });
      })
      .catch(err => {
        dispatch({
          type: LOGOUT
        });
      });
  } else {
    dispatch({
      type: LOGOUT
    });
  }

  return <>{children}</>;
};
