import React from "react";
import {Button, Link, makeStyles} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const DevstatDiffUniIndexTable = ({ uniIndexList }) => {
  const classes = useStyles();

  function createData(uniIndex) {
    return {
      uniIndex
    };
  }

  const rows = uniIndexList.map(it => createData(it.uniIndeks));

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>UniIndex</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.uniIndex}>
              <TableCell component="th" scope="row">
                {row.uniIndex}
              </TableCell>
              <TableCell>
                <Link
                  href={`/index/${row.uniIndex}`}
                  underline="none"
                  color="primary"
                  target="_blank"
                >
                  <Button variant="contained" color="primary">
                    SHOW DETAIL
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevstatDiffUniIndexTable;
