import { useQuery, useMutation } from "@apollo/react-hooks";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { gql } from "apollo-boost";
import { default as React } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import AddNewDevice from "./AddNewDevice";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    backgroundColor: theme.palette.gray,
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    padding: theme.spacing(2),
    boxSizing: "border-box",
    "& .MuiTableCell-root": {
      fontSize: "13px"
    }
  },
  paper: theme.filterPaper
}));

const GET_USERS_DEVICE = gql`
  query UserDevices($apiToken: String!) {
    userDevices(user_apiToken: $apiToken, isActive: true) {
      id
      name
      user {
        email
        name
        apiToken
      }
      createdAt
      updatedAt
      isLocked
      activationDate
      isActive
    }
  }
`;

const DELETE_USER_DEVICE_ITEM = gql`
  mutation deleteUserDevice($id: ID!) {
    deleteUserDevice(input: { id: $id }) {
      userDevice {
        id
      }
    }
  }
`;

const UserDevice = () => {
  const classes = useStyles();
  const user = useSelector(state => state.auth);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data, loading, error, refetch } = useQuery(GET_USERS_DEVICE, {
    variables: { apiToken: user.token }
  });

  const [deleteItem] = useMutation(DELETE_USER_DEVICE_ITEM, {
    update(cache, { data }) {
      refetch();
    }
  });

  if (loading) return <></>;
  if (error) return <></>;

  const handleClose = () => {
    setIsDialogOpen(false);
    refetch();
  };

  const handleSuccess = () => {
    refetch();
    setIsDialogOpen(false);
  };

  const handleDialogOpen = value => {
    setIsDialogOpen(value);
  };

  const handleDelete = dataDelete => {
    const ids = [];

    dataDelete.data.map(it => {
      if (data.userDevices[it.index]) {
        ids.push(data.userDevices[it.index].id);
      }
      return () => {};
    });

    ids.map(id => {
      deleteItem({
        variables: {
          id: id
        }
      });
      return () => {};
    });
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Device Panel</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDialogOpen(true)}
          >
            Dodaj nowe urządzenie
          </Button>
        </Grid>

        <Grid item xs={12}>
          <UserDeviceTable obj={data} handleDelete={handleDelete} />
        </Grid>

        {isDialogOpen ? (
          <AddNewDevice
            isOpen={isDialogOpen}
            handleClose={handleClose}
            handleSuccess={handleSuccess}
          />
        ) : null}
      </Grid>
    </div>
  );
};

const UserDeviceTable = ({ obj: { userDevices }, handleDelete }) => {
  const columns = [
    {
      name: "name",
      label: "Device name",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "createdAt",
      label: "Dodano",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "activationDate",
      label: "Aktywowano",
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  return (
    <>
      <MUIDataTable
        title={"Devices"}
        data={userDevices}
        columns={columns}
        options={{
          download: false,
          print: false,
          selectableRowsHeader: false,
          onRowsDelete: handleDelete
        }}
      />
    </>
  );
};

export default UserDevice;
