import { Tooltip as MaterialTooltip, makeStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import React from "react";
import NumberFormat from "react-number-format";
import {
  Bar,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {DefaultTooltipContent} from "recharts/lib/component/DefaultTooltipContent";

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: "0.95em",
    paddingTop: 0
  },
  switch: {
    marginLeft: theme.spacing(8)
  },
  settingsRow: {
    marginBottom: theme.spacing(1)
  }
}));

const DynamikaChart = ({ data, switchDate, setSwitchDate }) => {
  const classes = useStyles();

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }

    const newPayload = [
      {
        name: "Stan",
        value: (
          <NumberFormat
            value={props.payload[0].payload.stan}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" [szt.]"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "Średni rabat",
        value: (
          <NumberFormat
            value={round(props.payload[0].payload.avgRabat, 2)}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" %"}
            fixedDecimalScale={2}
          />
        )
      }
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  return (
    <>
      {/* <Grid item xs={12}> */}
      {/* <Paper className={classes.paper}> */}
      <Grid container direction="row" alignItems="center" className={classes.settingsRow}>

      <MaterialTooltip
        title={
          switchDate
            ? "Tylko zmiana stanów"
            : "Wszystktie daty od pierwszego stanu magazynowego"
        }
      >
        <Switch
          checked={switchDate}
          onChange={(event, newValue) => {
            setSwitchDate(newValue);
          }}
          value={switchDate}
          inputProps={{ "aria-label": "secondary checkbox" }}
          className={classes.switch}
        />
        
      </MaterialTooltip>
      <Typography variant="body1" className={classes.text}>Wszystkie daty lub tylko zmiana stanów</Typography>

      </Grid>

      <div style={{ width: "100%", height: 450 }}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{ top: 5, right: 15, left: 15, bottom: 5 }}
          >
            <XAxis dataKey="fullDate" padding={{ left: 20, right: 20 }} />
            <YAxis dataKey="stan" yAxisId="left" />
            <YAxis
              dataKey="avgRabat"
              yAxisId="right"
              orientation="right"
              tickFormatter={toPercent}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              payload={[
                {
                  value: "Stan",
                  type: "square",
                  color: "#330099"
                },
                {
                  value: "Średni rabat %",
                  type: "square",
                  color: "#cc0000"
                }
              ]}
            />
            <Bar yAxisId="left" barSize={10} dataKey="stan" fill="#330099" />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="avgRabat"
              stroke="#cc0000"
            />
            <Brush
              dataKey="fullDate"
              height={60}
              stroke="#8884d8"
              startIndex={data.length > 70 ? data.length - 70 : 0}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {/* </Paper> */}
      {/* </Grid> */}
    </>
  );
};

export default DynamikaChart;
