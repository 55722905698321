import {Button, Grid, Link, makeStyles, Typography} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import React, {useContext, useState} from "react";
import {filterContext} from "../../_context/filterContext";
import BestletterNavigation from "./BestsellerMenu";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import {SUCCESS} from "../../_actions/alertsActions";
import BestsellerItemStany from "./CustomBestsellerListItem/BestsellerItemStany";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #37474f",
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "0.8rem",
      color: "#6f6f6f"
    }
  },
  header: {
    backgroundColor: "#37474f",
    color: "white",
    padding: "7px 10px 9px",
    textTransform: "uppercase"
  },
  productName: {
    fontWeight: 500,
    marginLeft: "10px"
  },
  divider: {
    marginTop: "6px",
    marginBottom: "6px",
    backgroundColor: "rgba(216, 216, 216, 0.26)"
  },
  noMarginY: theme.noMarginY,
  item: {
    "&:nth-of-type(2n)": {
      "min-height": "90px",
      paddingTop: "13px"
    },
    "&:nth-of-type(2n + 1)": {
      height: "80px",
      paddingTop: "20px"
    },
    "& .MuiTypography-body1": {
      lineHeight: 1
    }
  },
  photo: {
    position: "absolute",
    borderRadius: "3px",
    border: `1px solid ${theme.palette.border}`,
    top: "-100%",
    left: "115px",
    width: "400px",
    height: "auto",
    zIndex: 999,
    transition: "all 0.5s ease-out"
  },
  photoThumbnail: {
    height: "150px",
    marginRight: "1.6vw",
    marginLeft: "15px",
    marginTop: "5px"
  },
  photoContainer: {
    position: "relative",
    minHeight: "115px"
  },
  danger: {
    fontSize: "1em",
    color: "#e60000",
    lineHeight: 1.2,
    fontWeight: 500
    // paddingBottom: 10
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 1
  }
}));

const getCategoryPath = category => {
  return category && category.match(/^\d/) ? category.substring(1) : category;
};

const copyToClipboard = (text, dispatch) => {
  const el = document.createElement("textarea");
  el.value = text.trim();
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  dispatch({ type: SUCCESS, payload: "Skopiowano" });
};

const BestletterListItem = ({ item, index }) => {
  const { state } = useContext(filterContext);
  const [limit] = useState(state.limit);
  const [page] = useState(state.page);
  const classes = useStyles();
  const [isShown, setIsShown] = useState(false);
  const { isDevStat } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const nr = (page - 1) * limit + index + 1;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        className={classes.header}
      >
        <Grid item xs={3}>
          <Typography variant="body1" component="span">
            {nr}.
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={classes.productName}
          >
            {item.designer} {item.sezon}
          </Typography>
          <Typography
            variant="body1"
            component="span"
            className={classes.productName}
          >
            {item.isCo ? "CARRY OVER" : ""}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.tooltipContainer}>
            <Tooltip
              title={"kopiuj do schowka"}
              aria-label="add"
              onClick={() => copyToClipboard(item.photoSid, dispatch)}
            >
              <Typography variant="body1">{item.photoSid}</Typography>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            {getCategoryPath(item.category)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item className={classes.photoContainer}>
          <img
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            src={
              item.photo.length
                ? item.photo
                : process.env.PUBLIC_URL + "no-img.png"
            }
            className={classes.photoThumbnail}
            alt="Product"
          />
          {isShown && item.photo.length > 0 ? (
            <img src={item.photo} alt="Product" className={classes.photo} />
          ) : null}
        </Grid>

        <Grid
          item
          xs
          container
          direction="column"
          className={classes.noMarginY}
        >
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Cena bazowa</Typography>
            <Typography variant="body1">{item.basePriceNetto} PLN</Typography>
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Sprzedaż</Typography>
            <Typography variant="body1">{item.qtySell} szt.</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          container
          direction="column"
          className={classes.noMarginY}
        >
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Średnia cena sprzedaży</Typography>
            <Typography variant="body1">{item.avgPriceNetto} PLN</Typography>
          </Grid>
          <Grid item className={classes.item}>
            <BestsellerItemStany item={item} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          container
          direction="column"
          className={classes.noMarginY}
        >
          <Grid item className={classes.item}>
            <Typography variant="subtitle2" className={classes.danger}>
              {/* Średni rabat (Sprzedaż/Zwroty) */}
              Średni rabat
            </Typography>
            <Typography
              variant="body1"
              className={(classes.bold, classes.danger)}
              style={{ marginBottom: 10 }}
            >
              {/* {item.percentRabat}% /({item.percentRabatSell}% / {item.percentRabatReturned}%) */}
              {item.percentRabat}%
            </Typography>

            <Typography variant="subtitle2" className={classes.danger}>
              {/* Średni rabat (Sprzedaż/Zwroty) */}
              Średni vat sprzedaży
            </Typography>
            <Typography
              variant="body1"
              className={(classes.bold, classes.danger)}
            >
              {/* {item.percentRabat}% /({item.percentRabatSell}% / {item.percentRabatReturned}%) */}
              {item.avgVatRate}%
            </Typography>
          </Grid>

          <Grid item className={classes.item} style={{ marginTop: 10 }}>
            <Typography variant="subtitle2">Zwroty</Typography>
            <Typography variant="body1">{item.qtyReturned} szt.</Typography>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Pierwsza dostawa</Typography>
            <Typography variant="body1">
              {item.dostawy.first.dataDostawy
                ? item.dostawy.first.dataDostawy
                : ""}
              {item.dostawy.first.qty
                ? " (" + item.dostawy.first.qty + " szt.)"
                : ""}
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">Pokaż na stronie</Typography>
            <Typography variant="body1">
              {item.polkaId && item.photo ? (
                <Link
                  href={`https://vitkac.com/sp/${item.polkaId}`}
                  target="_blank"
                  underline="none"
                  color="primary"
                >
                  Vitkac <LinkIcon fontSize="small" />
                </Link>
              ) : (
                ""
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item className={classes.item}>
            <Typography variant="subtitle2">
              {item.dostawy.last.length ? "3 ostatnie dostawy" : ""}
            </Typography>
            {item.dostawy.last.map((it, index) => {
              return (
                <Typography variant="body1" key={index}>
                  {it.dataDostawy}
                  {it.qty ? " (" + it.qty + " szt.)" : ""}
                </Typography>
              );
            })}
          </Grid>

          {item.idFarfetch === 0 ? (
            ""
          ) : (
            <Grid item className={classes.item}>
              <Typography variant="subtitle2">Pokaż na stronie</Typography>
              <Typography variant="body1">
                <Link
                  href={
                    "http://54.37.0.134/r.php?u=https://farfetch.com/pl/shopping/item-" +
                    item.idFarfetch +
                    ".aspx"
                  }
                  target="_blank"
                  underline="none"
                  color="primary"
                >
                  Farfetch <LinkIcon fontSize="small" />
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>

        {isDevStat ? (
          <Grid>
            <Button variant="contained">
              <Link
                href={`/devstat/show/${item.id}`}
                underline="none"
                color="primary"
                target="_blank"
              >
                Show Devstat
              </Link>
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid>
        <BestletterNavigation id={item.id} sygnatures={item.sygnatury} />
      </Grid>
    </Grid>
  );
};

export default BestletterListItem;
