import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { gql } from "apollo-boost";
import React, { useState, useEffect } from "react";
import { QRCode } from "react-qr-svg";
import { useDispatch } from "react-redux";
import { SUCCESS } from "../../_actions/alertsActions";

const ADD_NEW_DEVICE = gql`
  mutation createUserDevice($name: String!) {
    createUserDevice(input: { name: $name }) {
      userDevice {
        id
        name
        authCode
        user {
          email
          name
          apiToken
        }
        createdAt
        updatedAt
        isLocked
        activationDate
      }
    }
  }
`;

const GET_DEVICE = gql`
  query UserDevice($id: ID!) {
    userDevice(id: $id) {
      id
      name
      user {
        email
        name
        apiToken
      }
      createdAt
      updatedAt
      isLocked
      activationDate
      isActive
    }
  }
`;

const AddNewDevice = ({ isOpen, handleClose, handleSuccess }) => {
  // console.log(reloadList);
  const [authCode, setAuthCode] = useState("");
  const [id, setId] = useState(null);
  const [createUserDevice] = useMutation(ADD_NEW_DEVICE, {
    update: (
      cache,
      {
        data: {
          createUserDevice: { userDevice: updated }
        }
      }
    ) => {
      setId(updated.id);
      setAuthCode(updated.authCode);
    }
  });
  const [name, setName] = useState("");

  const handleAdd = () => {
    createUserDevice({
      variables: {
        name: name
      }
    });
  };

  const step1 = (
    <>
      <DialogTitle id="form-dialog-title">Dodaj nowe urządzenie</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nazwa urządzenia"
          type="email"
          fullWidth
          value={name}
          onKeyPress={e => (e.key === "Enter" ? handleAdd() : null)}
          onInput={e => {
            setName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={handleAdd} color="primary">
          Dalej
        </Button>
      </DialogActions>
    </>
  );

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="form-new-device"
        onClose={handleSuccess}
      >
        {authCode ? (
          <AddDeviceStep2
            authCode={authCode}
            id={id}
            handleClose={handleClose}
            handleSuccess={handleSuccess}
          />
        ) : (
          step1
        )}
      </Dialog>
    </div>
  );
};

const AddDeviceStep2 = ({ id, authCode, handleClose, handleSuccess }) => {
  const dispatch = useDispatch();
  const { data, loading } = useQuery(GET_DEVICE, {
    variables: {
      id: id
    },
    pollInterval: 2000
  });

  useEffect(() => {
    if (data && data.userDevice && data.userDevice.isActive) {
      handleSuccess();
      dispatch({ type: SUCCESS, payload: "Urządzenie zostało aktywowane" });
    }
    return () => {};
  }, [data, handleSuccess, dispatch]);

  if (loading) return <></>;

  return (
    <>
      <DialogTitle id="form-dialog-title">Zeskanuj kod w Aplikacji</DialogTitle>
      <DialogContent>
        <QRCode value={authCode} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>
      </DialogActions>
    </>
  );
};

export default AddNewDevice;
