import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.lightGray
  }
}));

const ActiveFilterItem = ({ label, type, remove, payload = null, change }) => {
  const classes = useStyles();
  return (
    <Chip
      label={label}
      size="small"
      deleteIcon={<DeleteForeverRoundedIcon className={classes.icon} />}
      onDelete={e => remove(type, payload)}
      onClick={e => change()}
    ></Chip>
  );
};

export default ActiveFilterItem;
