import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../_context/filterContext";
import SelectedList from "./SelectedList";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { Indicator } from "../Indicator";

const GET_DESIGNERS = gql`
  {
    projektancis {
      _id
      nazwa
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const DesignerFilter = () => {
  const classes = useStyles();
  const token = localStorage.getItem("token");

  const { loading, error, data } = useQuery(GET_DESIGNERS, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  });
  const {
    state: { designers, _update }
  } = useContext(filterContext);
  const [selected, setSelected] = useState(
    designers.length ? designers.split(",").map(it => parseInt(it, 10)) : []
  );

  useEffect(() => {
    setSelected(
      designers.length ? designers.split(",").map(it => parseInt(it, 10)) : []
    );
  }, [designers, _update]);

  if (loading) return <Indicator centerVertically height="380px"></Indicator>;
  if (error) return "Cannot load Designer filter";

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Projektanci</Typography>
      <input type="hidden" value={selected} name="designers" />
      <SelectedList
        data={data.projektancis}
        selected={selected}
        setSelected={setSelected}
        keyName={"designers"}
        nameKey={"nazwa"}
      />
    </Paper>
  );
};
