import React, { useState, useEffect, useContext } from "react";
import { filterContext } from "../../_context/filterContext";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton
  },
  marginBottom: {
    marginBottom: "10px"
  }
}));

export const SearchFilter = () => {
  const classes = useStyles();

  const {
    state: { queryUrl, queryName, _update }
  } = useContext(filterContext);

  const [urlQuery, setUrlQuery] = useState(queryUrl ? queryUrl : "");
  const [productQuery, setProductQuery] = useState(queryName ? queryName : "");

  useEffect(() => {
    setUrlQuery(queryUrl.length ? queryUrl : "");
  }, [queryUrl, _update]);

  useEffect(() => {
    setProductQuery(queryName.length ? queryName : "");
  }, [queryName, _update]);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Wyszukiwarka</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            key="query-for-url"
            label="Wpisz URL"
            value={urlQuery || ""}
            variant="outlined"
            onChange={event => setUrlQuery(event.target.value)}
            name="queryUrl"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setUrlQuery(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key="query-for-product"
            label="Wpisz nazwę produktu"
            value={productQuery || ""}
            variant="outlined"
            onChange={event => setProductQuery(event.target.value)}
            name="queryName"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setProductQuery(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
