import React, {useContext, useEffect, useState} from "react";
import {filterContext} from "../../../_context/filterContext";
import DynamikaChart from "./DynamikaChart";
import {getDynamika} from "../../../_services/_bestsellerService";
import TablePlaceholder from "../../../_components/TablePlaceholder";
import Grid from "@material-ui/core/Grid";

export const DynamikaComponent = ({ switchValue, id }) => {
  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [switchDate, setSwitchDate] = useState(true);
  const [dynamika, setDynamika] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getDynamika(switchValue ? state : { isPaid: 1 }, id)
      .then(res => {
        setDynamika(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state, id, switchValue]);

  return (
    // <>
    <Grid item xs={12}>
      {isLoading ? (
        <TablePlaceholder />
      ) : (
        <DynamikaChart
          data={switchDate ? dynamika.filter(it => it.isChange) : dynamika}
          switchDate={switchDate}
          setSwitchDate={setSwitchDate}
        />
      )}
    </Grid>
    // </>
  );
};
