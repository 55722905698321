import React, {useState} from "react";

const SelectedSygnaturySprzedazContext = React.createContext();

export default SelectedSygnaturySprzedazContext;

export const initDataS = {
  online: [],
  stacjonarne: [],
  inne: []
};

export const SelectedSygnaturySprzedazProvider = ({ children }) => {
  const [selectedS, setSelectedS] = useState(initDataS);

  return (
    <SelectedSygnaturySprzedazContext.Provider
      value={{ selectedS, setSelectedS }}
    >
      {children}
    </SelectedSygnaturySprzedazContext.Provider>
  );
};
