import Request from "./Request";

export const getDevstatProduct = (filters = {}) => {
  return Request(true).get("/api/devstat/product", {
    params: filters
  });
};

export const getShowProductDevstat = (id, _type) => {
  return Request(true).get("/api/devstat/show/" + id, {
    params: _type ? { type: _type } : {}
  });
};

export const getUniIndexInfo = uniIndex => {
  return Request(true).get("/api/devstat/uni/index/" + uniIndex);
};

export const synchroRequest = id => {
  return Request(true).get("/api/devstat/synchro/" + id);
};

export const getCheckSaleList = date => {
  return Request(true).get("/api/devstat/check/sales", {
    params: { date }
  });
};

export const getUniIndexDiffList = date => {
  return Request(true).get("/api/devstat/check/sale/" + date);
};
