import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

export const GET_SELECTED_CATEGORY = gql`
  query Kategorias($submited: [Int]) {
    kategorias(id_list: $submited) {
      _id
      id
      fullPath
      nazwaPl
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5)
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

const getCategory = category => {
  return category.match(/^\d/) ? category.substring(1) : category;
};

export const CategoryChip = ({ submited, setSubmited }) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_SELECTED_CATEGORY, {
    variables: { submited }
  });

  if (error) return <></>;
  if (loading) return <></>;

  const handleDelete = dataToDelete => () => {
    setSubmited(submited => submited.filter(sub => sub !== dataToDelete._id));
  };

  return (
    <>
      {data.kategorias.map(it => {
        return (
          <Chip
            size="small"
            key={it.id}
            label={getCategory(it.fullPath)}
            onDelete={handleDelete(it)}
            className={classes.chip}
          />
        );
      })}
    </>
  );
};
