import { convertDate } from "./ConvertDate";

export const getPreviousYearDate = date => {
  var newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() - 1);

  return convertDate(newDate);
};

export const getPreviousMonthDates = date => {
  let first = new Date(date);
  first.setDate(1);
  first.setMonth(first.getMonth() - 1);

  let last = new Date(date);
  last.setDate(0);
  last.setMonth(last.getMonth() - 0);

  return {
    lastDay: convertDate(last),
    firstDay: convertDate(first)
  };
};

export const getPreviousYearDates = date => {
  let first = new Date(date);
  first.setDate(1);
  first.setFullYear(first.getFullYear() - 1);

  let last = new Date(date);
  last.setDate(0);
  last.setFullYear(last.getFullYear() - 0);

  return {
    firstDay: convertDate(first),
    lastDay: convertDate(last)
  };
};
