import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@material-ui/core";
import { gql } from "apollo-boost";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SUCCESS } from "../../_actions/alertsActions";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "98%"
  },
  inputField: {
    ...theme.inputField
  },
  button: {
    margin: theme.spacing(1),
    width: "99%"
  },
  title: {
    margin: "8px"
  }
}));

const GET_USERS = gql`
  {
    users {
      id
      name
      email
    }
  }
`;

const AUTH_MANUAL = gql`
  mutation createUserDevice($user: String!, $uuid: String!) {
    createUserDevice(
      input: { name: "Manual Auth", user: $user, uuid: $uuid, isActive: true }
    ) {
      userDevice {
        id
        name
        isActive
        user {
          id
          name
          email
        }
      }
    }
  }
`;

const ManualAuthUserDevice = ({ handleSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(GET_USERS);
  const [createUserDevice] = useMutation(AUTH_MANUAL, {
    update: (cache, { data }) => {
      handleSuccess();
      dispatch({ type: SUCCESS, payload: "Dodano nowe urządzenie" });
    }
  });

  const [user, setUser] = useState("");
  const [uuid, setUuid] = useState("");

  if (loading) return <></>;
  if (error) return "Cannot load data";

  const handleSubmit = e => {
    e.preventDefault();
    createUserDevice({
      variables: {
        user,
        uuid
      }
    });

    setUuid("");
    setUser("");
  };

  const validateForm = e => {
    return !(uuid.length && user.length);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h2 className={classes.title}>Manualna aktywacja urządzenia</h2>
            </Grid>
            <Grid item xs={6}>
              <SelectUser users={data.users} user={user} setUser={setUser} />
            </Grid>
            <Grid item xs={6}>
              <InputUuid uuid={uuid} setUuid={setUuid} />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                disabled={validateForm()}
              >
                Zapisz
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const SelectUser = ({ users, user, setUser }) => {
  const classes = useStyles();
  const handleChange = e => {
    setUser(e.target.value);
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        id="select-user"
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Uzytkownik
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={user}
          onChange={handleChange}
          label="User"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {users.map(it => {
            return (
              <MenuItem value={it.id} key={it.id}>
                {it.name} ({it.email})
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const InputUuid = ({ uuid, setUuid }) => {
  const classes = useStyles();
  const handleInput = e => {
    e.preventDefault();
    setUuid(e.target.value);
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        id="select-user"
      >
        <TextField
          id="input-uuid"
          label="UUID"
          variant="outlined"
          value={uuid}
          onInput={handleInput}
        />
      </FormControl>
    </>
  );
};

export default ManualAuthUserDevice;
