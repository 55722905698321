import React, {useState} from "react";
import {Button, makeStyles, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {DatePicker} from "@material-ui/pickers";
import {convertDate} from "../../../_helpers/ConvertDate";
import {getCheckSaleList} from "../../../_services/devstatService";
import DevstatCheckSprzedazeTables from "./DevstatCheckSprzedazeTables";
import DevstatDiffUniIndex from "./DevstatDiffUniIndex";
import BestsellerListItemPlaceholder from "../../Bestseller/BestsellerListItemPlaceholder";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    backgroundColor: theme.palette.gray,
    padding: theme.spacing(2),
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    boxSizing: "border-box"
  },
  button: {
    marginTop: "20px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  paper2: theme.filterPaper
}));

const firstDaylastMonth = () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);

  var res = convertDate(firstDay);

  return res;
};

const DevstatCheckSprzedaz = () => {
  const classes = useStyles();
  const [date, setDate] = useState(firstDaylastMonth());
  const [results, setResults] = useState([]);
  const [checkDate, setCheckDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const makeRequest = e => {
    setIsLoading(true);
    getCheckSaleList(date)
      .then(res => {
        setResults(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loading = [...Array(2)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2">Check Sale List</Typography>
          </Paper>
        </Grid>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                label="Wprowadź datę od:"
                value={date}
                onChange={event => setDate(convertDate(event))}
                format="yyyy/MM/dd"
                name="dateFromSell"
                className={classes.inputField}
              />
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={e => makeRequest(e)}
                >
                  WYKONAJ
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {results.length ? (
            <Paper className={classes.paper}>
              <DevstatCheckSprzedazeTables
                data={results}
                setCheckDate={setCheckDate}
              ></DevstatCheckSprzedazeTables>
            </Paper>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            loading
          ) : checkDate.length ? (
            <Paper className={classes.paper}>
              <DevstatDiffUniIndex date={checkDate}></DevstatDiffUniIndex>
            </Paper>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DevstatCheckSprzedaz;
