import React, {useContext, useState} from "react";
import {filterContextDevstat} from "../../../../_context/filterContextDevstat";
import {Grid, makeStyles, Paper, TextField, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "10px"
  }
}));

export const SymbolFilter = () => {
  const classes = useStyles();

  const {
    state: { symbol }
  } = useContext(filterContextDevstat);

  const [symbolProduct, setSymbolProduct] = useState(symbol);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Symbol</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            key="symbol"
            label="Symbol"
            value={symbolProduct || ""}
            variant="outlined"
            onChange={event => setSymbolProduct(event.target.value)}
            name="symbol"
            className={classes.inputField}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
