import React, { useState, useEffect } from "react";
import { getSaleKategorieThirdLevelInLine } from "../../../_services/_saleService";
import MUIDataTable from "mui-datatables";
import TablePlaceholder from "../../TablePlaceholder";

export const SaleKategorieTable = ({ ids, filters }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [kategorie, setKategorie] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getSaleKategorieThirdLevelInLine(filters)
      .then(res => {
        setKategorie(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ids, filters]);

  const columns = [
    {
      name: "name",
      label: "Nazwa",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "total",
      label: "Sprzedane - Zwrócone [szt.]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "sell",
      label: "Sprzedane [szt.]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "returned",
      label: "Zwrócone [szt.]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "rabat",
      label: "Rabat [%]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "avg_p_netto",
      label: "Średnia cena netto [PLN]",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "priceNetto",
      label: "Suma netto [PLN]",
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  function createData(
    name,
    total,
    sell,
    returned,
    rabat,
    avg_p_netto,
    priceNetto
  ) {
    return {
      name,
      total,
      sell,
      returned,
      rabat,
      avg_p_netto,
      priceNetto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const datas = kategorie.map(it =>
    createData(
      it.nazwa,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      round(it.percentRabat, 2),
      round(it.avgPriceNetto, 2),
      round(it.priceNetto, 2)
    )
  );

  const options = {
    download: true,
    print: false,
    selectableRows: "none",
    disableToolbarSelect: true,
    selectableRowsHeader: false,
    textLabels: {
      pagination: {
        rowsPerPage: "Wyświetl"
      },
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <TablePlaceholder />
      ) : (
        <MUIDataTable
          title={"Kategorie"}
          data={datas}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
};
