import React, { useContext, useRef } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { NazwaFilter } from "./NazwaFilters";
import { SymbolFilter } from "./SymbolFilters";
import { PolkaFilter } from "./PolkaFilter";
import { SezonFilter } from "./SezonFilter";
import { DesignerFilter } from "./DesignerFilter";
import { NoProductsFilter } from "./NoProductsFilter";
import { StockFilter } from "./StockFilter";
import { ResultsWithSalesFilter } from "./ResultsWithSalesFilter";
import {
  filterContextDevstat,
  SET_FORM
} from "../../../../_context/filterContextDevstat";

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: "24px"
  },
  labels: theme.datePriceLabels,
  button: {
    marginTop: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: "10px"
  }
}));

const DevstatFiltersComponents = () => {
  const submitFormRef = useRef(null);
  const classes = useStyles();
  const { dispatch } = useContext(filterContextDevstat);

  const submit = event => {
    event.preventDefault();
    event.stopPropagation();

    const form = getFormValue(submitFormRef.current.elements);

    dispatch({ type: SET_FORM, payload: form });
  };

  const getFormValue = () => {
    if (submitFormRef && submitFormRef.current) {
      const t = submitFormRef.current.elements;

      return {
        nazwa: t.nazwa ? t.nazwa.value : "",
        symbol: t.symbol ? t.symbol.value : "",
        polkaIds: t.polkaIds ? t.polkaIds.value : "",
        seasons: t.seasons ? t.seasons.value : "",
        designers: t.designers ? t.designers.value : "",
        noCategory: t.noCategory.checked === true ? 1 : 0,
        noSeasons: t.noSeasons.checked === true ? 1 : 0,
        noDesigners: t.noDesigners.checked === true ? 1 : 0,
        noBasePriceNetto: t.noBasePriceNetto.checked === true ? 1 : 0,
        noLevel2: t.noLevel2.checked === true ? 1 : 0,
        noLevel3: t.noLevel3.checked === true ? 1 : 0,
        noColor: t.noColor.checked === true ? 1 : 0,
        stock: t.stock ? t.stock.value : "",
        resultsWithSales: t.resultsWithSales.checked === true ? 1 : 0
      };
    }

    return {};
  };

  return (
    <div className={classes.root}>
      <form ref={submitFormRef}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <NazwaFilter></NazwaFilter>
          </Grid>
          <Grid item xs={6}>
            <SymbolFilter></SymbolFilter>
          </Grid>
          <Grid item xs={6}>
            <PolkaFilter></PolkaFilter>
          </Grid>
          <Grid item xs={6}>
            <SezonFilter></SezonFilter>
          </Grid>
          <Grid item xs={6}>
            <DesignerFilter></DesignerFilter>
          </Grid>
          <Grid item xs={3}>
            <StockFilter></StockFilter>
          </Grid>
          <Grid item xs={3}>
            <ResultsWithSalesFilter></ResultsWithSalesFilter>
          </Grid>
          <Grid item xs={12}>
            <NoProductsFilter></NoProductsFilter>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={3} justify="center" className={classes.button}>
        <Button variant="contained" color="primary" onClick={e => submit(e)}>
          Szukaj
        </Button>
      </Grid>
    </div>
  );
};

export default DevstatFiltersComponents;
