import React, {useContext, useEffect, useState} from "react";
import {filterContext} from "../../_context/filterContext";
import {getSaleSygnatury} from "../../_services/_saleService";
import TableComponent from "../Bestseller/components/TableComponent";
import {ProgressBarComponent} from "../ProgressBarComponent";
import {Grid, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TablePlaceholder from "../TablePlaceholder";
import {ActiveFilter} from "../Filters/_activeFilter/ActiveFilter";
import NoProductAlert from "../../_helpers/NoProductAlert";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(1)
  },
  tablePaper: theme.tablePaper,
  chartPaper: theme.chartPaper
}));

export const SaleSygnaturyView = ({ setView }) => {
  const classes = useStyles();

  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saleSygnatury, setSaleSygnatury] = useState([]);
  const [saleSygnaturyType, setSaleSygnaturyType] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getSaleSygnatury(state)
      .then(res => {
        setSaleSygnatury(res.data.total);
        setSaleSygnaturyType(res.data.type);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }, [state]);

  const headers = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Nazwa"
    },
    {
      id: "orderCnt",
      numeric: true,
      disablePadding: false,
      label: "Zamówienia [szt.]"
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane - Zwrócone [szt.]"
    },
    {
      id: "sell",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane [szt.]"
    },
    {
      id: "returned",
      numeric: true,
      disablePadding: false,
      label: "Zwrócone [szt.]"
    },
    {
      id: "rabat",
      numeric: true,
      disablePadding: false,
      label: "Rabat [%]"
    },
    {
      id: "avg_vat",
      numeric: true,
      disablePadding: false,
      label: "Średni vat sprzedaży [%]"
    },
    {
      id: "avg_p_netto",
      numeric: true,
      disablePadding: false,
      label: "Średnia cena netto [PLN]"
    },
    {
      id: "priceNetto",
      numeric: true,
      disablePadding: false,
      label: "Suma netto [PLN]"
    },
    {
      id: "wartoscMagazynowaNetto",
      numeric: true,
      disablePadding: false,
      label: "Suma cena bazowa netto [PLN]"
    }
  ];

  function createData(
    name,
    orderCnt,
    total,
    sell,
    returned,
    rabat,
    avg_vat,
    avg_p_netto,
    priceNetto,
    wartoscMagazynowaNetto
  ) {
    return {
      name,
      orderCnt,
      total,
      sell,
      returned,
      rabat,
      avg_vat,
      avg_p_netto,
      priceNetto,
      wartoscMagazynowaNetto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const rows = saleSygnatury.map(it =>
    createData(
      it.name,
      it.orderCnt,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      round(it.percentRabat, 2),
      round(it.avgVatRate, 2),
      round(it.avgPriceNetto, 2),
      round(it.priceNetto, 2),
      round(it.wartoscMagazynowaNetto, 2)
    )
  );

  if (!isLoading && saleSygnatury.length < 1)
    return <NoProductAlert setView={setView} />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ActiveFilter></ActiveFilter>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.tablePaper}>
          {isLoading ? (
            <TablePlaceholder />
          ) : (
            <TableComponent
              headCellsData={headers}
              rowsData={rows}
            ></TableComponent>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <Paper className={classes.chartPaper}>
            <Typography variant="h2">Sygnatury</Typography>
            <ProgressBarComponent
              type={saleSygnaturyType}
            ></ProgressBarComponent>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
