import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useRef, useState } from "react";
import {
  CLEAR_FILTER,
  filterContext,
  SET_FORM
} from "../../_context/filterContext";
import { DateFilter } from "../Filters/DateFilter";
import { DiscountFilter } from "../Filters/DiscountFilter";
import { PercentRabatPerItemFilter } from "../Filters/PercentRabetPerItemFilter";
import { PaidFilter } from "../Filters/PaidFilter";
import { CoFilter } from "../Filters/CoFilter";
import { CzyOplaconeFilter } from "../Filters/CzyOplaconeFilter";
import { PriceFilter } from "../Filters/PricesFilter";
import { SaleFilter } from "../Filters/SaleFilter";
import { SearchFilter } from "../Filters/SearchFilter";
import { DesignerFilter } from "./DesignerFilter";
import { SeasonFilter } from "./SeasonFilter";
import { SygnaturaFilter } from "./SygnaturaFilter";
import { CountryFilter } from "./CountryFilter";
import { CategoryFilter } from "./CategoryFilter";
import SaveFilterDialog from "./_saveFilter/SaveFilterDialog";
import { SavedFilter } from "./_saveFilter/SavedFilter";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(2)
  },
  paper: theme.filterPaper
}));

export const FiltersComponent = ({ setView }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();
  const submitFormRef = useRef(null);
  const { dispatch } = useContext(filterContext);

  const handleShowProductList = () => {
    setView(1);
  };

  const submit = event => {
    event.preventDefault();
    event.stopPropagation();

    const form = getFormValue(submitFormRef.current.elements);

    dispatch({ type: SET_FORM, payload: form });
    setView(1);
  };

  const clearFilters = event => {
    dispatch({ type: CLEAR_FILTER });
  };

  const getFormValue = () => {
    if (submitFormRef && submitFormRef.current) {
      const t = submitFormRef.current.elements;

      return {
        discountFrom: t.discountFrom ? t.discountFrom.value : "",
        discountTo: t.discountTo ? t.discountTo.value : "",
        percentRabatPerItemFrom: t.percentRabatPerItemFrom
          ? t.percentRabatPerItemFrom.value
          : "",
        percentRabatPerItemTo: t.percentRabatPerItemTo
          ? t.percentRabatPerItemTo.value
          : "",
        dateFrom: t.dateFrom ? t.dateFrom.value : "",
        dateTo: t.dateTo ? t.dateTo.value : "",
        priceFrom: t.priceFrom ? t.priceFrom.value : "",
        priceTo: t.priceTo ? t.priceTo.value : "",
        isPaid: t.isPaid ? t.isPaid.value : 2,
        czyOplacone: t.czyOplacone ? t.czyOplacone.value : 2,
        isSale: t.isSale ? t.isSale.value : 2,
        isCo: t.isCo ? t.isCo.value : 2,
        queryUrl: t.queryUrl ? t.queryUrl.value : "",
        queryName: t.queryName ? t.queryName.value : "",
        seasons: t.seasons ? t.seasons.value : "",
        category: t.category ? t.category.value : "",
        designers: t.designers ? t.designers.value : "",
        sygnaturas: t.sygnaturas ? t.sygnaturas.value : "",
        country: t.country ? t.country.value : "",
        page: 1
      };
    }
    return {};
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Filtry</title>
      </Helmet>
      <form ref={submitFormRef}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SavedFilter handleShowProductList={handleShowProductList} />
          </Grid>
          <Grid item xs={12}>
            <CategoryFilter />
          </Grid>
          <Grid item xs={6}>
            <DateFilter></DateFilter>
          </Grid>
          <Grid item xs={6}>
            <PriceFilter></PriceFilter>
          </Grid>
          <Grid item xs={6}>
            <SygnaturaFilter />
          </Grid>
          <Grid item xs={6}>
            <DesignerFilter />
          </Grid>
          <Grid item xs={6}>
            <CountryFilter></CountryFilter>
          </Grid>
          <Grid item xs={6}>
            <SeasonFilter></SeasonFilter>
          </Grid>
          <Grid item xs={6}>
            <DiscountFilter></DiscountFilter>
          </Grid>
          <Grid item xs={6}>
            <SearchFilter></SearchFilter>
          </Grid>
          <Grid item xs={6}>
            <SaleFilter></SaleFilter>
          </Grid>
          <Grid item xs={6}>
            <PaidFilter></PaidFilter>
          </Grid>
          <Grid item xs={6}>
            <CoFilter></CoFilter>
          </Grid>
          <Grid item xs={6}>
            <CzyOplaconeFilter></CzyOplaconeFilter>
          </Grid>
          <Grid item xs={6}>
            <PercentRabatPerItemFilter></PercentRabatPerItemFilter>
          </Grid>
        </Grid>
      </form>

      <Grid container spacing={3} justify="center" className={classes.buttons}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Zapisz filtry
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={e => submit(e)}>
            Szukaj
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={e => clearFilters(e)}
          >
            Wyczyść filtry
          </Button>
        </Grid>

        <SaveFilterDialog
          open={openDialog}
          setOpen={setOpenDialog}
          formValue={getFormValue}
        />
      </Grid>
    </div>
  );
};
