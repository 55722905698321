export const ERROR = "alert_error";
export const SUCCESS = "alert_success";
export const HIDE = "alert_hide";

export const errorAlert = text => dispatch => {
  dispatch({ type: ERROR, payload: text });
};

export const hideAlert = () => dispatch => {
  dispatch({ type: HIDE });
};

export const successAlert = text => dispatch => {
  dispatch({ type: SUCCESS, payload: text });
};
