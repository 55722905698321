import React from "react";
import {
  makeStyles
} from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const REMOVE_TASK2RUN = gql`
  mutation deleteTask2Run($id: ID!) {
    deleteTask2Run(input: { id: $id }) {
      task2Run {
        id
      }
    }
  }
`;

export const GET_TASKS_2_RUN = gql`
  {
    task2Runs {
      id
      command
      afterDate
      log
    }
  }
`;

const useStyles = makeStyles({
  table: {
    minWidth: 100
  }
});

function createData(id, command, afterDate, log) {
  return {
    id,
    command,
    afterDate,
    log
  };
}

export const Task2RunTabele = () => {
  const classes = useStyles();

  const [removeTask2Run] = useMutation(REMOVE_TASK2RUN, {
    update(cache, { data }) {
      const { task2Runs } = cache.readQuery({ query: GET_TASKS_2_RUN });
      cache.writeQuery({
        query: GET_TASKS_2_RUN,
        data: {
          task2Runs: task2Runs.filter(
            it => it.id !== data.deleteTask2Run.task2Run.id
          )
        }
      });
    }
  });

  const { loading, error, data } = useQuery(GET_TASKS_2_RUN);

  if (loading) return <>Loading</>;
  if (error) return <>Błąd ładowania taksów</>;

  const rows = data.task2Runs.map(it =>
    createData(it.id, it.command, it.afterDate, it.log)
  );

  const deleteTask = item => {
    removeTask2Run({
      variables: {
        id: item.id
      }
    });
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table" title="" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">Command</TableCell>
            <TableCell align="left">After date</TableCell>
            <TableCell align="left">Log</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.command}</TableCell>
              <TableCell align="left">{row.afterDate}</TableCell>
              <TableCell align="left">{row.log}</TableCell>
              <TableCell align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={e => deleteTask(row)}
                  >
                    End
                  </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
