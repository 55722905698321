import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {DefaultTooltipContent} from "recharts/lib/component/DefaultTooltipContent";
import NumberFormat from "react-number-format";
import {
  Bar,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush
} from "recharts";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: theme.chartPaper,
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const OrdersChartPriceNetto = ({ data }) => {
  const classes = useStyles();

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }
    // return null;
    const newPayload = [
      {
        name: "Cena netto",
        value: (
          <NumberFormat
            value={props.payload[0].payload.priceNetto}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" PLN"}
            fixedDecimalScale={2}
          />
        )
      },
      {
        name: "Średni rabat",
        value: (
          <NumberFormat
            value={round(props.payload[0].payload.percentRabat, 2)}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" %"}
            fixedDecimalScale={2}
          />
        )
      }
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h2">Wartość sprzedaży netto PLN</Typography>
          <div style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <ComposedChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="fullDate" padding={{ left: 20, right: 20 }} />
                <YAxis dataKey="priceNetto" yAxisId="left" />
                <YAxis
                  dataKey="percentRabat"
                  yAxisId="right"
                  orientation="right"
                  tickFormatter={toPercent}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  payload={[
                    {
                      value: "Średnia cena sprzedaży netto",
                      type: "square",
                      color: "#330099"
                    },
                    {
                      value: "Średni rabat %",
                      type: "square",
                      color: "#cc0000"
                    }
                  ]}
                />
                <Bar
                  yAxisId="left"
                  barSize={20}
                  dataKey="priceNetto"
                  fill="#330099"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="percentRabat"
                  stroke="#cc0000"
                />
                <Brush dataKey="fullDate" height={30} stroke="#8884d8" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default OrdersChartPriceNetto;

// return (
//   <>
//     <div className={classes.root}>
//       <Paper className={classes.paper}>
//         <Typography variant="h2">Wartość sprzedaży netto PLN</Typography>
//         <div style={{ width: "100%", height: 300 }}>
//           <ResponsiveContainer>
//             <LineChart
//               data={data}
//               margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//             >
//               <XAxis dataKey="fullDate" />
//               <YAxis dataKey="priceNetto" />
//               <CartesianGrid strokeDasharray="3 3" />
//               <Tooltip content={<CustomTooltip />} />
//               <Legend
//                 payload={[
//                   {
//                     value: "Średnia cena sprzedaży netto",
//                     type: "square",
//                     color: "#330099"
//                   }
//                 ]}
//               />
//               <Line type="monotone" dataKey="priceNetto" stroke="#330099" />
//               <Brush dataKey="fullDate" height={30} stroke="#8884d8" />
//             </LineChart>
//           </ResponsiveContainer>
//         </div>
//       </Paper>
//     </div>
//   </>
// );
// };
