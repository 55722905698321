import { TableCell, TableRow } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-google-charts";
import TablePlaceholder from "../../../_components/TablePlaceholder";
import { filterContext } from "../../../_context/filterContext";
import { getRabaty } from "../../../_services/_bestsellerService";

const useStyles = makeStyles(theme => ({
  chart: {
    marginLeft: "10px"
  }
}));

export const RabatyComponent = ({ switchValue, id }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(filterContext);
  const [rabaty, setRabaty] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getRabaty(switchValue ? state : { isPaid: 1 }, id)
      .then(res => {
        setRabaty(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state, id, switchValue]);

  const headerChart = ["Range", "Sell"];

  const rabatyDataChart = rabaty.map(it => [it.name, it.qtySell]);

  const data = [headerChart, ...rabatyDataChart];

  return (
    <>
      <Grid item xs={8}>
        {isLoading ? <TablePlaceholder /> : <RabatyTable data={rabaty} />}
      </Grid>

      <Grid item xs={3} className={classes.chart}>
        {isLoading ? (
          <TablePlaceholder />
        ) : (
          <Chart
            width={"500px"}
            height={"300px"}
            chartType="PieChart"
            data={data}
            mapsApiKey="YOUR_KEY_HERE"
            rootProps={{ "data-testid": "1" }}
          />
        )}
      </Grid>
    </>
  );
};

const RabatyTable = ({ data }) => {
  const dataParsed = data.map(it => {
    return {
      ...it,
      qtyTotal: parseInt(it.qtyTotal, 10),
      qtySell: parseInt(it.qtySell, 10),
      qtyReturned: parseInt(it.qtyReturned, 10)
    };
  });

  const handleExpandable = (e, index) => {
    return dataParsed[index.dataIndex].details.map(it => {
      const idx = index.dataIndex + "rabat-details";

      return (
        <TableRow key={idx}>
          <TableCell></TableCell>
          <TableCell>{it.percentRabat}</TableCell>
          <TableCell>{it.qtyTotal}</TableCell>
          <TableCell>{it.qtySell}</TableCell>
          <TableCell>{it.qtyReturned}</TableCell>
        </TableRow>
      );
    });
  };

  const option = {
    pagination: false,
    expandableRows: true,
    expandableRowsOnClick: true,
    renderExpandableRow: handleExpandable,
    download: false,
    print: false,
    selectableRows: "none",
    disableToolbarSelect: true,
    selectableRowsHeader: false,
    filter: false,
    textLabels: {
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  const columns = [
    {
      name: "name",
      label: "Przedział",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "qtyTotal",
      label: "Sprzedane - Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "qtySell",
      label: "Sprzedane [szt.]",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "qtyReturned",
      label: "Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  return (
    <>{<MUIDataTable data={dataParsed} columns={columns} options={option} />}</>
  );
};
