import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {DefaultTooltipContent} from "recharts/lib/component/DefaultTooltipContent";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush
} from "recharts";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: theme.chartPaper,
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const SaleDesignerChartTotal = ({ data }) => {
  const classes = useStyles();

  const CustomTooltip = props => {
    if (!props.active) {
      return null;
    }

    const newPayload = [
      {
        name: "Ilość",
        value: props.payload[0].payload.qtySell
      }
    ];

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h2">Sprzedane sztuki</Typography>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="nazwa" />
              <YAxis dataKey="qtySell" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                payload={[
                  {
                    value: "Ilość sprzedanych sztuk",
                    type: "square",
                    color: "#112E51"
                  }
                ]}
              />
              <Bar dataKey="qtySell" fill="#8884d8" />
              <Brush dataKey="qtySell" height={30} stroke="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Paper>
    </div>
  );
};

export default SaleDesignerChartTotal;
