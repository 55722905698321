import React, {useContext, useEffect, useState} from "react";
import {filterContext} from "../../_context/filterContext";
import {getSaleDesigner} from "../../_services/_saleService";
import TableComponent from "../Bestseller/components/TableComponent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {ActiveFilter} from "../Filters/_activeFilter/ActiveFilter";
import {makeStyles} from "@material-ui/core/styles";
import SaleDesignerChartTotal from "./SaleDesignerChartTotal";
import SaleDesignerChartPriceNetto from "./SaleDesignerChartPriceNetto";
import TablePlaceholder from "../TablePlaceholder";
import NoProductAlert from "../../_helpers/NoProductAlert";

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(1)
  },
  paper: theme.tablePaper
}));

export const SaleDesignerView = ({ setView }) => {
  const classes = useStyles();
  const { state } = useContext(filterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [saleDesigner, setSaleDesigner] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getSaleDesigner(state)
      .then(res => {
        setSaleDesigner(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }, [state]);

  const headers = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Nazwa"
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane - Zwrócone [szt.]"
    },
    {
      id: "sell",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane [szt.]"
    },
    {
      id: "returned",
      numeric: true,
      disablePadding: false,
      label: "Zwrócone [szt.]"
    },
    {
      id: "rabat",
      numeric: true,
      disablePadding: false,
      label: "Rabat [%]"
    },
    {
      id: "avg_vat",
      numeric: true,
      disablePadding: false,
      label: "Średni vat sprzedaży[%]"
    },
    {
      id: "avg_p_netto",
      numeric: true,
      disablePadding: false,
      label: "Średnia cena netto [PLN]"
    },
    {
      id: "priceNetto",
      numeric: true,
      disablePadding: false,
      label: "Suma netto [PLN]"
    },
    {
      id: "wartoscMagazynowaNetto",
      numeric: true,
      disablePadding: false,
      label: "Suma cena bazowa netto [PLN]"
    }
  ];

  function createData(
    name,
    total,
    sell,
    returned,
    rabat,
    avg_vat,
    avg_p_netto,
    priceNetto,
    wartoscMagazynowaNetto
  ) {
    return {
      name,
      total,
      sell,
      returned,
      rabat,
      avg_vat,
      avg_p_netto,
      priceNetto,
      wartoscMagazynowaNetto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const rows = saleDesigner.map(it =>
    createData(
      it.nazwa,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      round(it.percentRabat, 2),
      round(it.avgVatRate, 2),
      round(it.avgPriceNetto, 2),
      round(it.priceNetto, 2),
      round(it.wartoscMagazynowaNetto, 2)
    )
  );

  if (!isLoading && saleDesigner.length < 1)
    return <NoProductAlert setView={setView} />;

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ActiveFilter />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {isLoading ? (
                <TablePlaceholder />
              ) : (
                <TableComponent
                  headCellsData={headers}
                  rowsData={rows}
                ></TableComponent>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <TablePlaceholder />
            ) : (
              <SaleDesignerChartTotal data={saleDesigner} />
            )}
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <TablePlaceholder />
            ) : (
              <SaleDesignerChartPriceNetto data={saleDesigner} />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
