import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import "./App.css";
import { vitkacTheme } from "./theme";
import { Alerts } from "./_components/Alerts";
import { Auth } from "./_components/Auth/Auth";
import AuthGuard from "./_components/Auth/AuthGuard";
import { Login } from "./_components/Auth/Login";
import { Logout } from "./_components/Auth/Logout";
import { BestsellerComponent } from "./_components/Bestseller/BestsellerComponent";
import DevstatCheckSprzedaz from "./_components/Devstat/checkSprzedaz/DevstatCheckSprzedaz";
import DevstatProductComponent from "./_components/Devstat/DevstatTowarGrupowanie/DevstatProductComponent";
import DevstatShowProduct from "./_components/Devstat/DevstatTowarGrupowanie/DevstatShowProduct/DevstatShowProduct";
import CompareDbHurtownia from "./_components/Devstat/Log/CompareDbHurtownia";
import DevstatLog from "./_components/Devstat/Log/DevstatLog";
import { AppMenu } from "./_components/Menu";
import { OrdersComponent } from "./_components/Orders/OrdersComponent";
import { RealTimeComponent } from "./_components/RealTime/RealTimeComponent";
import { SaleDesignerComponent } from "./_components/Sale/SaleDesignerComponenent";
import { SaleKategorieComponent } from "./_components/Sale/SaleKategorieComponent";
import { SaleLokalizacjeComponent } from "./_components/Sale/SaleLokalizacjeComponent";
import { SaleSygnaturyComponent } from "./_components/Sale/SaleSygnaturyComponent";
import { TasksComponent } from "./_components/Tasks/TasksComponent";
import UserDevice from "./_components/UserDeviceAdmin/UserDevice";
import UserDeviceAdmin from "./_components/UserDeviceAdmin/UserDeviceAdmin";
import { UsersComponent } from "./_components/Users/UsersComponent";
import MonthlyIncomeComponent from "./_components/Other/MonthlyIncomeComponent";

export const App = () => {
  return (
    <MuiThemeProvider theme={vitkacTheme}>
      <Auth>
        <Router>
          <Alerts />
          <Switch exac={true}>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
            <AuthGuard>
              <AppMenu />
              <Route path="/bestseller" exact>
                <BestsellerComponent />
              </Route>
              <Route path="/sale/designers" exact>
                <SaleDesignerComponent />
              </Route>
              <Route path="/sale/lokalizacje" exact>
                <SaleLokalizacjeComponent />
              </Route>
              <Route path="/sale/sygnatury" exact>
                <SaleSygnaturyComponent />
              </Route>
              <Route path="/sale/order" exact>
                <OrdersComponent />
              </Route>
              <Route path="/sale/kategorie" exact>
                <SaleKategorieComponent />
              </Route>
              <Route path="/realtime" exact>
                <RealTimeComponent />
              </Route>
              <Route path="/other/monthly-income" exact>
                <MonthlyIncomeComponent />
              </Route>
              <Route path="/users" exact>
                <UsersComponent />
              </Route>
              <Route path="/devices" exact>
                <UserDevice />
              </Route>
              <Route path="/" exact>
                <Redirect to="/bestseller" />
              </Route>
              <Route path="/devstat" exact>
                <DevstatProductComponent />
              </Route>
              <Route path="/devstat/show/:id/:type?" exact>
                <DevstatShowProduct />
              </Route>
              <Route path="/devstat/log" exact>
                <DevstatLog />
              </Route>
              <Route path="/tasks" exact>
                <TasksComponent />
              </Route>
              <Route path="/devstat/check/sales" exact>
                <DevstatCheckSprzedaz />
              </Route>
              <Route path="/admin/devices" exact>
                <UserDeviceAdmin />
              </Route>
              <Route path="/index/:index" exact>
                <CompareDbHurtownia />
              </Route>
            </AuthGuard>
          </Switch>
        </Router>
      </Auth>
    </MuiThemeProvider>
  );
};

export default App;
