import React from "react";
import {makeStyles} from "@material-ui/core";

import DevstatFiltersComponents from "./DevstatFilters/DevstatFiltersComponents";
import DevstatProductList from "./DevstatProductList";
import {FilterProviderDevstat} from "../../../_context/filterContextDevstat";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "10px"
  },
  background: {
    padding: "60px 12px 40px",
    backgroundColor: theme.palette.gray
  }
}));

const DevstatProductComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <FilterProviderDevstat>
        <DevstatFiltersComponents></DevstatFiltersComponents>
        <DevstatProductList></DevstatProductList>
      </FilterProviderDevstat>
    </div>
  );
};

export default DevstatProductComponent;
