import Request from "./Request";

export const getRealTimeList = (filters = {}) => {
  return Request(true).get("/api/realtime/towar", {
    params: filters
  });
};

export const getSummaryRealTime = (date, cancelToken) => {
  return Request(true).get("/api/realtime/summary/", {
    params: { date },
    cancelToken: cancelToken
  });
};

export const getRealTimeDataChartSprzedaze = (
  date,
  chartSygnatury,
  cancelToken
) => {
  return Request(true).get("/api/realtime/summary/chart/sprzedaz/", {
    params: { date, chartSygnatury },
    cancelToken: cancelToken
  });
};

export const getRealTimeDataChartZwroty = (
  date,
  chartSygnatury,
  cancelToken
) => {
  return Request(true).get("/api/realtime/summary/chart/zwroty/", {
    params: { date, chartSygnatury },
    cancelToken: cancelToken
  });
};

export const getRealTimeSummaryByType = (date, cancelToken) => {
  return Request(true).get("/api/realtime/summary/type", {
    params: { date },
    cancelToken: cancelToken
  });
};
