import { ERROR, SUCCESS, HIDE } from "../_actions/alertsActions";

const initialState = {
  text: "",
  isShow: false,
  name: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERROR: {
      return {
        text: action.payload,
        isShow: true,
        name: "error"
      };
    }
    case SUCCESS: {
      return {
        ...state,
        text: action.payload,
        isShow: true,
        name: "success"
      };
    }
    case HIDE: {
      return {
        ...state,
        isShow: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
