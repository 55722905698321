import React, {useEffect, useState} from "react";
import {getRealTimeDataChartZwroty} from "../../../_services/_realtimeService";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import BestsellerListItemPlaceholder from "../../Bestseller/BestsellerListItemPlaceholder";
import RealTimeSummaryChart from "../RealTimeSummaryChart";
import {getTotalData} from "../totalData";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  divider: theme.divider,
  labels: theme.datePriceLabels,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton
  }
}));

const RealTimeSummaryChartZwroty = ({
  date,
  update,
  sygnaturyIds,
  filters
}) => {
  const classes = useStyles();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState();
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setShowChart(false);

    let cancelSource = axios.CancelToken.source();

    getRealTimeDataChartZwroty(
      date,
      sygnaturyIds.toString(),
      cancelSource.token
    )
      .then(res => {
        setData(res.data);
        setIsLoading(false);

        if (res.data && filters.total === true && !sygnaturyIds.length) {
          setTotal(getTotalData(res.data));
        }

        /* do not show chart if there is no data to selected filter */
        if (
          (filters.online && res.data.online) ||
          (filters.stacjonarne && res.data.stationary) ||
          (filters.inne && res.data.other) ||
          (filters.total && res.data)
        ) {
          setShowChart(true);
        }
      })
      .catch(err => {
        if (err.response) {
          console.error(err);
          setIsLoading(false);
        }
      });

    return function() {
      cancelSource.cancel();
    };
  }, [date, update, sygnaturyIds, filters]);

  const loading = [...Array(1)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  if (isLoading) return loading;

  if (!showChart) return null;

  return (
    <>
      {Object.keys(data).length ? (
        <RealTimeSummaryChart
          data={data}
          filters={filters}
          title={"zwroty"}
          dataTotal={total}
          legendTotalName={"Total"}
        />
      ) : null}
    </>
  );
};

export default RealTimeSummaryChartZwroty;
