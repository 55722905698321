import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold"
  },
  tableContainer: {
    maxHeight: "280px"
  }
});

const DevstatShowTableRozmiary = ({ rozmiary }) => {
  const classes = useStyles();

  function createData(rozmiar, stock_as, stock_polka) {
    return { rozmiar, stock_as, stock_polka };
  }

  const rows = rozmiary.map(it =>
    createData(it.rozmiar, it.stock_as, it.stock_polka)
  );

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table
        stickyHeader
        className={classes.table}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Rozmiar</TableCell>
            <TableCell>Stock_as</TableCell>
            <TableCell>Stock_polka</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.rozmiar + index}>
              <TableCell>{row.rozmiar}</TableCell>
              <TableCell>{row.stock_as}</TableCell>
              <TableCell>{row.stock_polka}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevstatShowTableRozmiary;
