import React, { useContext } from "react";
import { Select, Grid, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import {
  filterContext,
  SET_SORT,
  SET_PAGE
} from "../../_context/filterContext";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 30,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(132, 132, 132, 0.13)",
      borderRadius: 0
    }
  },
  noMargin: theme.noMargin,
  paginationSelect: theme.paginationSelect.input
}));

export const Sort = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: { orderBy }
  } = useContext(filterContext);

  const sort_options = [
    { name: "ilość sprz. malejąco", value: "qtyTotalDesc" },
    { name: "ilość sprz. rosnąco", value: "qtyTotalAsc" },
    { name: "stan malejąco", value: "qtyStockDesc" },
    { name: "stan rosnąco", value: "qtyStockAsc" },
    { name: "ilosć zwróconych malejąco", value: "qtyReturnDesc" },
    { name: "ilość zwróconych rosnąco", value: "qtyReturnAsc" },
    { name: "rabat procentowy malejaco", value: "discountDesc" },
    { name: "rabat procentowy rosnąco", value: "discountAsc" },
    { name: "data dostawy rosnąco", value: "deliveryDateAsc" },
    { name: "data dostawy malejąco", value: "deliveryDateDesc" },
    { name: "cena bazowa rosnąco", value: "basePriceAsc" },
    { name: "cena bazowa malejąco", value: "basePriceDesc" },
    { name: "cena średnia rosnąco", value: "avgPriceAsc" },
    { name: "cena średnia malejąco", value: "avgPriceDesc" },
    { name: "ilość sprz. kolor malejąco", value: "qtyColorDesc" },
    { name: "ilość sprz. kolor rosnąco", value: "qtyColorAsc" }
  ];

  const setSort = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: SET_SORT, payload: e.target.value });
    dispatch({ type: SET_PAGE, payload: 1 });
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      className={classes.noMargin}
    >
      <Grid item>
        <Typography variant="body1">Sortuj</Typography>
      </Grid>
      <Grid item className={classes.noMargin}>
        <FormControl
          style={{ minWidth: 30 }}
          variant="outlined"
          className={classes.formControl}
          onChange={event => {
            setSort(event);
          }}
        >
          <Select
            native
            placeholder="Wybierz"
            value={orderBy}
            classes={{ root: classes.paginationSelect }}
          >
            {sort_options.map(option => {
              const id = `option-${option.name}`;
              return (
                <option key={id} value={option.value}>
                  {option.name}
                </option>
              );
            })}
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
