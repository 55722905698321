import React, { useContext, useEffect, useState } from "react";
import { RadioComponent } from "../Widget/RadioComponent";
import { filterContext } from "../../_context/filterContext";
import { makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const SaleFilter = () => {
  const {
    state: { isSale, _update }
  } = useContext(filterContext);

  const [isS, setIsSale] = useState(parseInt(isSale, 10));

  useEffect(() => {
    setIsSale(parseInt(isSale, 10));
  }, [isSale, _update]);

  const options = [
    {
      display: "Sprzedaż z uwzględnieniem zwrotów",
      value: 2
    },
    {
      display: "Sprzedaż bez uwzględnienia zwrotów",
      value: 1
    },
    {
      display: "Tylko zwroty",
      value: 0
    }
  ];

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <input type="hidden" value={isS} name="isSale" />
      <Typography variant="h2">Status sprzedaży</Typography>
      <RadioComponent
        name="isSale"
        options={options}
        state={isS}
        setState={setIsSale}
      />
    </Paper>
  );
};
