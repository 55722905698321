import React, { useReducer } from "react";

export const SET_FORM = "SET_FORM";
export const SET_PAGE_DEVSTAT = "SET_PAGE_DEVSTAT";
export const SET_LIMIT_DEVSTAT = "SET_LIMIT_DEVSTAT";

const initialValue = {
  nazwa: "",
  symbol: "",
  polkaIds: [],
  seasons: [],
  designers: [],
  noCategory: 0,
  noSeasons: 0,
  noDesigners: 0,
  noBasePriceNetto: 0,
  noLevel2: 0,
  noLevel3: 0,
  noColor: 0,
  stock: "",
  resultsWithSales: 0,
  limit: localStorage.getItem("limitPage")
    ? localStorage.getItem("limitPage")
    : 20,
  page: 1
};

let reducer = (state, action) => {
  switch (action.type) {
    case SET_FORM:
      return {
        ...state,
        ...action.payload
      };
    case SET_PAGE_DEVSTAT:
      return {
        ...state,
        page: action.payload
      };
    case SET_LIMIT_DEVSTAT:
      return {
        ...state,
        limit: action.payload
      };
    default:
      return state;
  }
};

export const filterContextDevstat = React.createContext(initialValue);

export const FilterProviderDevstat = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  return (
    <filterContextDevstat.Provider value={{ state, dispatch }}>
      {children}
    </filterContextDevstat.Provider>
  );
};
