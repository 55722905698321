import React, { useContext } from "react";
import { filterContext, SET_FOR_REALTIME } from "../../_context/filterContext";

export const DispatchRealTimeStartFilters = ({ children }) => {
  const {
    dispatch,
    state: { _update }
  } = useContext(filterContext);

  dispatch({ type: SET_FOR_REALTIME });

  return <>{_update ? children : null}</>;
};

export default DispatchRealTimeStartFilters;
