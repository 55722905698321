import React, {useState, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getStatic } from "../../../_services/devstatLogService";

const useStyles = makeStyles(theme => ({
    warning: {
        color: "red",
        fontWeight: ""         
    }
  }));

export const DevstatStatic = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        getStatic()
        .then(it => {
            setData(it.data);
          })
        .catch(err => {
            setError("DEVSTAT STATIC: " + err.response.data.detail + " statusCode:" + err.response.data.status);
        })

        return () => {};
      },[]);

    return (
        <div>
        {error ? (error) : (<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                Nazwa
              </TableCell>
              <TableCell>Wartość</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow key={row.name}>
                <TableCell className={row.isError ? classes.warning : null}>
                  {row.name}
                </TableCell>
                <TableCell className={row.isError ? classes.warning : null}>
                  {row.value} {row.isError ? " - ERROR" : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)}
        
      </div>
    );
    
};