import React from "react";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import { parseToFixed, thousandSeparator } from "../../../_helpers/separatorValue";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  table: {
    "& .MuiTableCell-root": {
      width: "16.6%"
    }
  }
}));

export const OrdersDateDetailRabat = ({ data }) => {
  const classes = useStyles();

  const dataParsed = data.detail.map(it => {
    return {
      ...it,
      qtySell: parseInt(it.qtySell, 10),
      qtyReturned: parseInt(it.qtyReturned, 10),
      qtyTotal: parseInt(it.qtyTotal, 10),
      avgPriceNetto: parseToFixed(it.avgPriceNetto, 2),
      priceNetto: parseToFixed(it.priceNetto, 2),
      priceNettoPercent: parseToFixed(it.proceNettoPercent, 2),
      qtyReturnedPercent: parseToFixed(it.qtyReturnedPercent, 2),
      qtySellPercent: parseToFixed(it.qtySellPercent, 2)
    };
  });

  const option = {
    pagination: false,
    expandableRows: false,
    expandableRowsOnClick: false,
    customFooter: () => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell>SUMMARY</TableCell>
            <TableCell>{parseInt(data.total.qtySell, 10)}</TableCell>
            <TableCell>{parseInt(data.total.qtyReturned, 10)}</TableCell>
            <TableCell>{parseInt(data.total.qtyTotal, 10)}</TableCell>
            <TableCell>
              {thousandSeparator(parseToFixed(data.total.priceNetto, 2))}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableFooter>
      );
    },
    print: false,
    disableToolbarSelect: true,
    selectableRowsHeader: false,
    selectableRows: "none",
    filter: false,
    download: true,
    textLabels: {
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  const columns = [
    {
      name: "name",
      label: "Przedział",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "qtySell",
      label: "Sprzedane [szt.]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <p value={value}>
              {value +
                " ( " +
                data["detail"][tableMeta.rowIndex].qtySellPercent +
                " % )"}
            </p>
          );
        }
      }
    },
    {
      name: "qtyReturned",
      label: "Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <p value={value}>
              {value +
                " ( " +
                data["detail"][tableMeta.rowIndex].qtyReturnedPercent +
                " % )"}
            </p>
          );
        }
      }
    },
    {
      name: "qtyTotal",
      label: "Sprzedane-Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "priceNetto",
      label: "Łączna suma netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <p value={value}>
              {thousandSeparator(value) +
                " ( " +
                data["detail"][tableMeta.rowIndex].priceNettoPercent +
                " % )"}
            </p>
          );
        }
      }
    },
    {
      name: "avgPriceNetto",
      label: "Średnia cena netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return <p value={value}>{thousandSeparator(value)}</p>;
        }
      }
    }
  ];

  return (
    <>
      <MUIDataTable
        data={dataParsed}
        columns={columns}
        options={option}
        className={classes.table}
      />
    </>
  );
};
