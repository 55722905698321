import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import ActiveFilterItem from "./ActiveFilterItem";

export const GET_CAT = gql`
  query Kategorias($id_list: [Int]) {
    kategorias(id_list: $id_list) {
      _id
      fullPath
    }
  }
`;

const getCategory = category => {
  return category.match(/^\d/) ? category.substring(1) : category;
};

const ActiveFilterCategory = ({ IdList, removeAction, change }) => {
  const { data, loading, error } = useQuery(GET_CAT, {
    variables: {
      id_list: IdList
    }
  });

  if (error) return "error";
  if (loading) return "";

  return (
    <>
      {data.kategorias.map((it, index) => (
        <ActiveFilterItem
          key={index}
          label={getCategory(it.fullPath)}
          type={"REMOVE_CATEGORY"}
          payload={it._id}
          remove={removeAction}
          change={change}
        ></ActiveFilterItem>
      ))}
    </>
  );
};

export default ActiveFilterCategory;
