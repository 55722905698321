import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import ActiveFilterItem from "./ActiveFilterItem";

export const GET_PROJ = gql`
  query Projektancis($id_list: [Int]) {
    projektancis(id_list: $id_list) {
      _id
      nazwa
    }
  }
`;

const ActiveFilterDesigner = ({ IdList, removeAction, change }) => {
  const { data, loading, error } = useQuery(GET_PROJ, {
    variables: {
      id_list: IdList
    }
  });

  if (error) return "error";
  if (loading) return "";

  return (
    <>
      {data.projektancis.map(it => (
        <ActiveFilterItem
          key={it._id}
          label={it.nazwa}
          type={"REMOVE_DESIGNER"}
          payload={it._id}
          remove={removeAction}
          change={change}
        ></ActiveFilterItem>
      ))}
    </>
  );
};

export default ActiveFilterDesigner;
