import React, { useContext, useEffect } from "react";
import { RadioComponent } from "../Widget/RadioComponent";
import { useState } from "react";
import { filterContext } from "../../_context/filterContext";
import { makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper
}));

export const CzyOplaconeFilter = () => {
  const {
    state: { czyOplacone, _update }
  } = useContext(filterContext);

  const [czyOpl, setCzyOpl] = useState(czyOplacone);

  useEffect(() => {
    setCzyOpl(parseInt(czyOpl, 10));
  }, [czyOpl, _update]);

  const options = [
    {
      display: "Opłacone + Nieopłacone",
      value: 2
    },
    {
      display: "Tylko Opłacone",
      value: 1
    },
    {
      display: "Tylko Nieopłacone",
      value: 0
    }
  ];

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <input type="hidden" value={czyOpl} name="czyOplacone" />
      <Typography variant="h2">Potwierdzenie płatności</Typography>
      <RadioComponent
        name="czyOplacone"
        options={options}
        state={czyOpl}
        setState={setCzyOpl}
      />
    </Paper>
  );
};
