import React from "react";
import { FormControl, Grid, InputLabel, Select } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { gql } from "apollo-boost";

const GET_CATEGORIES = gql`
  query Kategorias(
    $level: Int!
    $p1Id: Int
    $p2Id: Int
    $p3Id: Int
    $p4Id: Int
    $p5Id: Int
  ) {
    kategorias(
      level: $level
      p1Id: $p1Id
      p2Id: $p2Id
      p3Id: $p3Id
      p4Id: $p4Id
      p5Id: $p5Id
    ) {
      _id
      id
      nazwaPl
      level
      p1Id
      p2Id
      p3Id
      p4Id
      p5Id
      fullPath
      wbtNazwa
    }
  }
`;

const useStyles = makeStyles(theme => ({
  separator: {
    position: "relative",
    "&:not(:last-of-type):after": {
      content: "'\\203A'",
      height: "42px",
      position: "absolute",
      fontSize: "18px",
      top: "18px",
      right: "-3px"
    }
  },
  formControl: {
    width: "100%",
    "&:last-of-child": {}
  }
}));

const getCategory = category => {
  return category.match(/^\d/) ? category.substring(1) : category;
};

export const CategoryItem = ({
  level,
  setSelectedCategory,
  setLevel,
  QUERY,
  selectedCategory,
  emptyLevel1,
  setEmptyLevel1
}) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_CATEGORIES, {
    variables: {
      p1Id: level > 1 ? selectedCategory.p1Id : null,
      p2Id: level > 2 ? selectedCategory.p2Id : null,
      p3Id: level > 3 ? selectedCategory.p3Id : null,
      p4Id: level > 4 ? selectedCategory.p4Id : null,
      level: level
      // id: selectedCategory.id,
      // full_path: selectedCategory.full_path
    }
  });

  if (error) return <CategoryItemDisabled />;
  if (loading) return <CategoryItemDisabled />;

  const handleChange = event => {
    setEmptyLevel1(false);
    var dataId = event.target[event.target.selectedIndex].getAttribute(
      "data-id"
    );

    var dataPath = event.target[event.target.selectedIndex].getAttribute(
      "data-path"
    );

    if (level === 1) {
      setSelectedCategory({
        ...selectedCategory,
        p1Id: event.target.value,
        p2Id: null,
        p3Id: null,
        p4Id: null,
        p5Id: null,
        id: dataId,
        full_path: dataPath
      });
    }
    if (level === 2) {
      setSelectedCategory({
        ...selectedCategory,
        p2Id: event.target.value,
        p3Id: null,
        p4Id: null,
        p5Id: null,
        id: dataId,
        full_path: dataPath
      });
    }
    if (level === 3) {
      setSelectedCategory({
        ...selectedCategory,
        p3Id: event.target.value,
        p4Id: null,
        p5Id: null,
        id: dataId,
        full_path: dataPath
      });
    }
    if (level === 4) {
      setSelectedCategory({
        ...selectedCategory,
        p4Id: event.target.value,
        p5Id: null,
        id: dataId,
        full_path: dataPath
      });
    }
    if (level === 5) {
      setSelectedCategory({
        ...selectedCategory,
        p5Id: event.target.value,
        id: dataId,
        full_path: dataPath
      });
    }
    setLevel(level + 1);
  };

  let s = null;
  switch (level) {
    case 1:
      s = selectedCategory.p1Id ? selectedCategory.p1Id : null;
      break;
    case 2:
      s = selectedCategory.p2Id ? selectedCategory.p2Id : null;
      break;
    case 3:
      s = selectedCategory.p3Id ? selectedCategory.p3Id : null;
      break;
    case 4:
      s = selectedCategory.p4Id ? selectedCategory.p4Id : null;
      break;
    case 5:
      s = selectedCategory.p5Id ? selectedCategory.p5Id : null;
      break;
    default:
  }

  return (
    <Grid item xs className={classes.separator}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          native
          disabled={data.kategorias.length > 0 ? false : true}
          value={
            emptyLevel1 === true ? "Wybierz" : s === null ? "Nieaktywne" : s
          }
          onChange={e => handleChange(e)}
        >
          {data.kategorias.length > 0 ? (
            <option>Wybierz</option>
          ) : (
            <option>Nieaktywne</option>
          )}
          {data.kategorias.map(val => {
            let nazwa = val.wbtNazwa ? val.wbtNazwa : val.nazwaPl;
            const id = `option-${val.id}`;
            return (
              <option
                key={id}
                value={val._id}
                data-id={val._id}
                data-path={val.fullPath}
              >
                {nazwa ? getCategory(nazwa) : ""}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export const CategoryItemDisabled = () => {
  const classes = useStyles();
  return (
    <Grid item xs className={classes.separator}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Nieaktywne</InputLabel>
        <Select native disabled={true}></Select>
      </FormControl>
    </Grid>
  );
};
