import React, { useContext, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DateFilter } from "../../Filters/DateFilter";
import { PriceFilter } from "../../Filters/PricesFilter";
import { filterContext, SET_FORM } from "../../../_context/filterContext";
import { DiscountFilter } from "../DiscountFilter";
import { SearchFilter } from "../SearchFilter";
import { PaidFilter } from "../PaidFilter";
import { CzyOplaconeFilter } from "../CzyOplaconeFilter";
import { SaleFilter } from "../SaleFilter";
import { CoFilter } from "../CoFilter";
import { CategoryFilter } from "../CategoryFilter";
import { SygnaturaFilter } from "../SygnaturaFilter";
import { CountryFilter } from "../CountryFilter";
import { DesignerFilter } from "../DesignerFilter";
import { SeasonFilter } from "../SeasonFilter";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: "unset!important",
      minWidth: "660px"
    },
    "& .MuiDialogContent-root:first-child": {
      paddingTop: theme.spacing(1)
    }
  },
  dialog: {
    padding: "0 12px",
    "& .MuiPaper-root": {
      boxShadow: "none!important"
    }
  }
}));

const ChangeActiveFilterDialogBox = ({ open, setOpen, name }) => {
  const classes = useStyles();
  const submitFormRef = useRef(null);
  const { dispatch } = useContext(filterContext);

  const {
    state: {
      dateFrom,
      dateTo,
      priceFrom,
      priceTo,
      discountFrom,
      discountTo,
      queryUrl,
      queryName,
      isPaid,
      czyOplacone,
      isSale,
      isCo,
      category,
      sygnaturas,
      seasons,
      designers,
      country
    }
  } = useContext(filterContext);

  const handleClose = () => {
    setOpen(false);
  };

  const getFormValue = () => {
    if (submitFormRef && submitFormRef.current) {
      const t = submitFormRef.current.elements;

      return {
        discountFrom: t.discountFrom ? t.discountFrom.value : discountFrom,
        discountTo: t.discountTo ? t.discountTo.value : discountTo,
        dateFrom: t.dateFrom ? t.dateFrom.value : dateFrom,
        dateTo: t.dateTo ? t.dateTo.value : dateTo,
        priceFrom: t.priceFrom ? t.priceFrom.value : priceFrom,
        priceTo: t.priceTo ? t.priceTo.value : priceTo,
        isPaid: t.isPaid ? t.isPaid.value : isPaid,
        czyOplacone: t.czyOplacone ? t.czyOplacone.value : czyOplacone,
        isSale: t.isSale ? t.isSale.value : isSale,
        isCo: t.isCo ? t.isCo.value : isCo,
        queryUrl: t.queryUrl ? t.queryUrl.value : queryUrl,
        queryName: t.queryName ? t.queryName.value : queryName,
        seasons: t.seasons ? t.seasons.value : seasons,
        category: t.category ? t.category.value : category,
        designers: t.designers ? t.designers.value : designers,
        sygnaturas: t.sygnaturas ? t.sygnaturas.value : sygnaturas,
        country: t.country ? t.country.value : country,
        page: 1
      };
    }
    return {};
  };

  const handleSave = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = getFormValue(submitFormRef.current.elements);

    dispatch({ type: SET_FORM, payload: form });
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        className={classes.root}
      >
        <form ref={submitFormRef}>
          <DialogContent className={classes.dialog}>
            {
              {
                date: <DateFilter />,
                price: <PriceFilter />,
                discount: <DiscountFilter />,
                search: <SearchFilter />,
                isPaid: <PaidFilter />,
                czyOplacone: <CzyOplaconeFilter />,
                isSale: <SaleFilter />,
                isCo: <CoFilter />,
                category: <CategoryFilter />,
                sygnatura: <SygnaturaFilter />,
                country: <CountryFilter />,
                designer: <DesignerFilter />,
                season: <SeasonFilter />
              }[name]
            }
          </DialogContent>
        </form>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button onClick={handleSave}>Zapisz</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeActiveFilterDialogBox;
