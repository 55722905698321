import React, {useState} from "react";

const SelectedSygnaturyZwrotyContext = React.createContext();

export default SelectedSygnaturyZwrotyContext;

export const initDataZ = {
  online: [],
  stacjonarne: [],
  inne: []
};

export const SelectedSygnaturyZwrotyProvider = ({ children }) => {
  const [selectedZ, setSelectedZ] = useState(initDataZ);

  return (
    <SelectedSygnaturyZwrotyContext.Provider
      value={{ selectedZ, setSelectedZ }}
    >
      {children}
    </SelectedSygnaturyZwrotyContext.Provider>
  );
};
