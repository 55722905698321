import React, {useContext, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import {getRealTimeSummaryByType} from "../../_services/_realtimeService";
import {convertDate} from "../../_helpers/ConvertDate";
import {Grid} from "@material-ui/core";
import {parseToFixed, thousandSeparator} from "../../_helpers/separatorValue";
import axios from "axios";
import ColumnsSortContext from "./ColumnSortContext";

const TotalDetails = ({ date }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { sortColumns, setSortColumns } = useContext(ColumnsSortContext);

  const translateSygnatureType = value => {
    switch (value) {
      case "other":
        return "Inne";
      case "stationary":
        return "Stacjonarne";
      case "online":
        return "Online";
      case "total":
        return "Total";
      default:
        return value;
    }
  };

  useEffect(() => {
    setIsLoading(true);

    let cancelSource = axios.CancelToken.source();

    getRealTimeSummaryByType(convertDate(date), cancelSource.token)
      .then(res => {
        let dataWithTranslateSyganatura = res.data;

        dataWithTranslateSyganatura.sprzedaz.map(it => {
          const name = it.name;
          it.name = translateSygnatureType(name);

          return it;
        });

        dataWithTranslateSyganatura.zwroty.map(it => {
          const name = it.name;
          it.name = translateSygnatureType(name);

          return it;
        });

        setData(dataWithTranslateSyganatura);
      })

      .catch(err => {
        if (err.response) {
          console.error(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return function() {
      cancelSource.cancel();
    };
  }, [date]);

  if (isLoading) return null;

  const changeColumnSortContext = (columnName, direction, keyName) => {
    let newSortColumns = sortColumns;
    let newDirection = false;

    if (direction === "ascending") {
      newDirection = "asc";
    } else {
      newDirection = "desc";
    }

    newSortColumns.total[keyName].name = columnName;
    newSortColumns.total[keyName].direction = newDirection;

    setSortColumns(newSortColumns);
  };

  const optionsS = {
    download: false,
    print: false,
    filter: false,
    search: false,
    pagination: false,
    disableToolbarSelect: false,
    selectableRowsHeader: false,
    viewColumns: false,
    selectableRows: "none",
    onColumnSortChange: (changedColumn, direction) => {
      changeColumnSortContext(changedColumn, direction, "sprzedaz");
    }
  };

  const optionsZ = {
    download: false,
    print: false,
    filter: false,
    search: false,
    pagination: false,
    disableToolbarSelect: false,
    selectableRowsHeader: false,
    viewColumns: false,
    selectableRows: "none",
    onColumnSortChange: (changedColumn, direction) => {
      changeColumnSortContext(changedColumn, direction, "zwroty");
    }
  };

  const columnsS = [
    {
      name: "name",
      label: "Sygnatura typ",
      options: {
        sortDirection:
          sortColumns.total.sprzedaz.name === "name"
            ? sortColumns.total.sprzedaz.direction
            : null,
        customBodyRender: value => {
          return value;
        }
      }
    },
    {
      name: "qtyTotal",
      label: "Sprzedaże",
      options: {
        sortDirection:
          sortColumns.total.sprzedaz.name === "qtyTotal"
            ? sortColumns.total.sprzedaz.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    },
    {
      name: "percentRabat",
      label: "Średni rabat [%]",
      options: {
        sortDirection:
          sortColumns.total.sprzedaz.name === "percentRabat"
            ? sortColumns.total.sprzedaz.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    },
    {
      name: "priceNetto",
      label: "Suma netto [PLN]",
      options: {
        sortDirection:
          sortColumns.total.sprzedaz.name === "priceNetto"
            ? sortColumns.total.sprzedaz.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    },
    {
      name: "avgPriceNetto",
      label: "Średnia cena netto [PLN]",
      options: {
        sortDirection:
          sortColumns.total.sprzedaz.name === "avgPriceNetto"
            ? sortColumns.total.sprzedaz.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    }
  ];

  const columnsZ = [
    {
      name: "name",
      label: "Sygnatura typ",
      options: {
        sortDirection:
          sortColumns.total.zwroty.name === "name"
            ? sortColumns.total.zwroty.direction
            : null,
        customBodyRender: value => {
          return translateSygnatureType(value);
        }
      }
    },
    {
      name: "qtyTotal",
      label: "Zwroty",
      options: {
        sortDirection:
          sortColumns.total.zwroty.name === "qtyTotal"
            ? sortColumns.total.zwroty.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    },
    {
      name: "percentRabat",
      label: "Średni rabat [%]",
      options: {
        sortDirection:
          sortColumns.total.zwroty.name === "percentRabat"
            ? sortColumns.total.zwroty.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    },
    {
      name: "priceNetto",
      label: "Suma netto [PLN]",
      options: {
        sortDirection:
          sortColumns.total.zwroty.name === "priceNetto"
            ? sortColumns.total.zwroty.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    },
    {
      name: "avgPriceNetto",
      label: "Średnia cena netto [PLN]",
      options: {
        sortDirection:
          sortColumns.total.zwroty.name === "avgPriceNetto"
            ? sortColumns.total.zwroty.direction
            : null,
        customBodyRender: value => {
          return value ? thousandSeparator(parseToFixed(value, 2)) : 0;
        }
      }
    }
  ];

  return (
    <>
      <Grid item xs={6}>
        <Paper>
          <MUIDataTable
            data={data["sprzedaz"]}
            columns={columnsS}
            options={optionsS}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <MUIDataTable
            data={data["zwroty"]}
            columns={columnsZ}
            options={optionsZ}
          />
        </Paper>
      </Grid>
    </>
  );
};

export default TotalDetails;
