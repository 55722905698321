import React, {useEffect, useState} from "react";
import {makeStyles, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {getUniIndexDiffList} from "../../../_services/devstatService";
import DevstatDiffUniIndexTable from "./DevstatDiffUniIndexTable";
import BestsellerListItemPlaceholder from "../../Bestseller/BestsellerListItemPlaceholder";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    backgroundColor: theme.palette.gray,
    padding: theme.spacing(2),
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    boxSizing: "border-box"
  },
  button: {
    marginTop: "20px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  paper2: theme.filterPaper
}));

const DevstatDiffUniIndex = ({ date }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [uniIndexDiffList, setUniIndexDiffList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getUniIndexDiffList(date)
      .then(res => {
        setUniIndexDiffList(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scroll(0, 0);
      });

    return () => {};
  }, [date]);

  const loading = [...Array(2)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        UniIndexDiff dla dnia: {date}
      </Typography>
      <Grid item xs={12}>
        {isLoading ? (
          loading
        ) : (
          <DevstatDiffUniIndexTable
            uniIndexList={uniIndexDiffList}
          ></DevstatDiffUniIndexTable>
        )}
      </Grid>
    </div>
  );
};

export default DevstatDiffUniIndex;
