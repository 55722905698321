import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {LOGOUT} from "../../_actions/authActions";

export const Logout = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch({ type: LOGOUT });

  history.push("/login");

  return <></>;
};
