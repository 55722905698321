import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  TextField,
  Button,
  Link
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField,
    width: "400px"
  },
  marginBottom: {
    marginBottom: "2px"
  },
  padd: {
    padding: "20px"
  }
}));

const DevstatLogSearch = () => {
  const classes = useStyles();
  const [uniIndex, setUniIndex] = useState("");
  const [tg, setTg] = useState("");
  const [polka, setPolka] = useState("");
  const [towar, setTowar] = useState("");

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Wyszukiwarka</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={6}>
          <Grid item xs={12} className={classes.padd}>
            <TextField
              key="query-for-uni-index"
              label="Wpisz uni index"
              value={uniIndex || ""}
              variant="outlined"
              onChange={event => setUniIndex(event.target.value)}
              name="uniIndex"
              className={classes.inputField}
            />
            <Button variant="contained">
              <Link
                href={`/index/${uniIndex}`}
                underline="none"
                color="primary"
              >
                Szukaj
              </Link>
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.padd}>
            <TextField
              key="query-for-tg"
              label="Wpisz tg"
              value={tg || ""}
              variant="outlined"
              onChange={event => setTg(event.target.value)}
              name="tg"
              className={classes.inputField}
            />
            <Button variant="contained">
              <Link
                href={`/devstat/show/${tg}/tg`}
                underline="none"
                color="primary"
                target="_blank"
              >
                Szukaj
              </Link>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} className={classes.padd}>
            <TextField
              key="query-for-towar"
              label="Wpisz towar id"
              value={towar || ""}
              variant="outlined"
              onChange={event => setTowar(event.target.value)}
              name="towar"
              className={classes.inputField}
            />
            <Button variant="contained">
              <Link
                href={`/devstat/show/${towar}/towar`}
                underline="none"
                color="primary"
                target="_blank"
              >
                Szukaj
              </Link>
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.padd}>
            <TextField
              key="query-for-polka"
              label="Wpisz polka id"
              value={polka || ""}
              variant="outlined"
              onChange={event => setPolka(event.target.value)}
              name="polka"
              className={classes.inputField}
            />
            <Button variant="contained">
              <Link
                href={`/devstat/show/${polka}/polka`}
                underline="none"
                color="primary"
                target="_blank"
              >
                Szukaj
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DevstatLogSearch;
