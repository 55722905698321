import React, { useEffect, useState } from "react";
import { getTgPriceCompare } from "../../../_services/_bestsellerService";
import TablePlaceholder from "../../../_components/TablePlaceholder";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinkIcon from "@material-ui/icons/Link";
import { Grid, Link } from "@material-ui/core";

const EUR = "EUR";
const GBP = "GBP";
const PLN = "PLN";
const USD = "USD";

const PriceCompare = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getTgPriceCompare(id)
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading)
    return (
      <Grid item xs={12}>
        <TablePlaceholder />
      </Grid>
    );

  if (!isLoading && !data.length)
    return (
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <div>BRAK DANYCH</div>
      </div>
    );

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>{EUR}</TableCell>
              <TableCell>{GBP}</TableCell>
              <TableCell>{PLN}</TableCell>
              <TableCell>{USD}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <>
                    {row.shopName}

                    <Link
                      href={row.url}
                      target="_blank"
                      underline="none"
                      color="primary"
                    >
                      <LinkIcon fontSize="small" />
                    </Link>
                  </>
                </TableCell>
                <TableCell>
                  <DisplayData row={row} curr={EUR} />
                </TableCell>
                <TableCell>
                  <DisplayData row={row} curr={GBP} />
                </TableCell>
                <TableCell>
                  <DisplayData row={row} curr={PLN} />
                </TableCell>
                <TableCell>
                  <DisplayData row={row} curr={USD} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default PriceCompare;

const getDataByCurrency = (it, currency) => {
  const arr = it.values.filter(
    val =>
      it.shopSid.toLowerCase() === val.shopSid.toLowerCase() &&
      val.currencyCode === currency
  );

  return {
    price: arr.length ? arr[0]["price"] : "-",
    updatedAt: arr.length ? arr[0]["updatedAt"] : "-"
  };
};

const DisplayData = ({ row, curr }) => {
  const data = getDataByCurrency(row, curr);

  return (
    <>
      {data["price"]}
      <div style={{ color: "#a9a9a9" }}>{` [ ${data["updatedAt"]} ]`}</div>
    </>
  );
};
