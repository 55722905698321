import { Grid, makeStyles, Paper, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getLogDbProduct } from "../../../_services/devstatLogService";
import useLogItemPagination from "./useLogItemPagination";

const useStyles = makeStyles(theme => ({
  textInput: {
    ...theme.logsTextInput,
    height: "30px",
    width: "150px",
    textAlign: "left"
  },
  logPaper: theme.filterPaper,
  pre: {
    ...theme.pre,
    padding: 0
  },
  polkaId: {
    width: "100%",
    backgroundColor: "#f8f8f8",
    padding: "12px 10px",
    boxSizing: "border-box"
  },
  message: {
    padding: "8px 12px 2px 16px"
  },
  messages: {
    marginBottom: theme.spacing(2)
  }
}));

const LogItemProduct = ({ item: { name } }) => {
  const classes = useStyles();

  const [paginationComponent, page] = useLogItemPagination();
  const [log, setLog] = useState([]);
  const [polkaId, setPolkaId] = useState("");
  const [tg, setTg] = useState("");

  useEffect(() => {
    getLogDbProduct(page).then(res => {
      setLog(res.data);
    });
  }, [page, polkaId, tg]);

  return (
    <Grid item xs={12}>
      <Paper className={classes.logPaper}>
        <Typography variant="h2">{name}</Typography>
        <Grid container direction="row" spacing={1} className={classes.filterRow} alignItems="flex-end">
          <Grid item>
            <input
              type="number"
              value={polkaId}
              className={classes.textInput}
              onChange={e => setPolkaId(e.target.value)}
              placeholder="Polka ID"
            />{" "}
          </Grid>
          <Grid item>
            <input
              type="number"
              value={tg}
              className={classes.textInput}
              onChange={e => setTg(e.target.value)}
              placeholder="Towar Grupowanie ID"
            />
          </Grid>
          <Grid item xs>
            <Button variant="contained" color="primary" size="small">
              Pokaż
            </Button>
          </Grid>
          <Grid item>{paginationComponent}</Grid>
        </Grid>
        {log.map((it, key) => {
          return (
            <pre className={classes.pre}>
              <LogComponent item={it} key={key} classes={classes} />
            </pre>
          );
        })}
      </Paper>
    </Grid>
  );
};

const LogComponent = ({ item, classes }) => {
  const { polkaId, items } = item;
  return (<div className={classes.messages}>
      <div className={classes.polkaId}>PolkaID: {polkaId}</div>
      {items.map((it, key) => {
        return (
            <LogItem item={it} key={key} classes={classes}/>
        );
      })}
      </div>
  );
};

export const LogItem = ({ item, classes }) => {
  const { message, createdAt, exceptionClass } = item;
  return (
    <div className={classes.message}>
      [{createdAt}][{exceptionClass}] <br />
      {message}
    </div>
  );
};

export default LogItemProduct;
