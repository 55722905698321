import React from "react";
import { Switch } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const UPDATE_IS_LOCKED = gql`
  mutation updateUserDevice($id: ID!, $isLocked: Boolean) {
    updateUserDevice(input: { id: $id, isLocked: $isLocked }) {
      userDevice {
        id
        isLocked
      }
    }
  }
`;

const UpdateIsLockedUserDevice = ({ obj }) => {
  const [updateIsLocked] = useMutation(UPDATE_IS_LOCKED);

  const handleIsLocked = e => {
    e.preventDefault();
    updateIsLocked({
      variables: {
        id: obj.id,
        isLocked: !obj.isLocked
      }
    });
  };

  return (
    <Switch
      checked={obj.isLocked}
      onChange={e => handleIsLocked(e)}
      name="isLockedSwitch"
    />
  );
};

export default UpdateIsLockedUserDevice;
