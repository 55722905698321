import {LOGIN, LOGOUT, SET_USER} from "../_actions/authActions";

const initialState = {
  isLoggedIn: undefined,
  token: undefined,
  loggedUser: undefined,
  name: "",
  isDevStat: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const { apiToken, id, name, isDevStat, roles } = action.payload;
      localStorage.setItem("apiToken", apiToken);
      localStorage.setItem("id", id);
      return {
        ...state,
        isLoggedIn: true,
        token: apiToken,
        name: name,
        isDevStat: isDevStat,
        roles: [...roles]
      };
    }
    case LOGOUT: {
      localStorage.removeItem("apiToken");
      localStorage.removeItem("id");
      return {
        ...state,
        isLoggedIn: false,
        loggedUser: undefined,
        token: null,
        name: "",
        roles: []
      };
    }
    case SET_USER:
      const { id, apiToken, loggedUser, isDevStat, name, roles } = action.payload;
      localStorage.setItem("apiToken", apiToken);
      localStorage.setItem("id", id);
      return {
        ...state,
        isLoggedIn: true,
        loggedUser,
        name,
        isDevStat,
        token: apiToken,
        roles: [...roles]
      };
    default: {
      return { ...state };
    }
  }
};
