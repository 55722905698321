export const LOGIN = "login_auth";
export const LOGOUT = "logout_auth";
export const SET_USER = "set_user_auth";

export const loginAuth = user => dispatch => {
  dispatch({ type: LOGIN, payload: user });
};

export const logoutAuth = () => dispatch => {
  dispatch({ type: LOGOUT });
};

export const setUser = payload => dispatch => {
  dispatch({ type: SET_USER, payload });
};
