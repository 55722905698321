const useParseDateToCompare = () => {
  const parseDateToArrayLastYear = (data, dataCompare) => {
    data.map(itData => {
      dataCompare.filter(it => {
        const fullDate = it.fullDate;

        const year = parseInt(fullDate.split("-")[0]) + 1;

        const substring = fullDate.substring(4);

        const parseDate = `${year}` + substring;

        if (parseDate === itData.fullDate) {
          itData.compare = it;
        }

        return it;
      });

      return itData;
    });

    return data;
  };

  const parseDateToArrayLastPeriod = (data, dataCompare) => {
    data.map((it, index) => {
      it.compare = dataCompare[index];
      dataCompare[index].isUse = true;
      return it;
    });

    dataCompare
      .filter(it => it.isUse !== true)
      .map(it => {
        return data.push({
          avgPriceBrutto: 0,
          avgPriceNetto: 0,
          avgVatRate: 0,
          currency: [],
          fullDate: "-",
          percentRabat: 0,
          percentRabatReturned: 0,
          percentRabatSell: 0,
          priceBrutto: 0,
          priceNetto: 0,
          priceNettoPercentRabat: 0,
          qtyReturned: 0,
          qtySell: 0,
          qtyTotal: 0,
          wartoscMagazynowaNetto: 0,
          compare: it
        });
      });

    return data;
  };

  return { parseDateToArrayLastYear, parseDateToArrayLastPeriod };
};

export default useParseDateToCompare;
