import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Request from "../../_services/Request";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    padding: "12px 12px 40px",
    flexGrow: 1,
    backgroundColor: theme.palette.gray,
    display: "flex",
    flexDirection: "column",
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    boxSizing: "border-box",
    "& .MuiCircularProgress-root": {
      height: "17px!important",
      width: "17px!important",
      color: "white"
    }
  },
  paper: theme.filterPaper,
  button: {
    marginTop: theme.spacing(2)
  }
}));

const MonthlyIncomeComponent = () => {
  const classes = useStyles();
  const [ selectedMonths, setSelectedMonths ] = useState("02");
  const [ selectedYear, setSelectedYear ] = useState("2022")
  const [ isLoading, setIsLoading ]= useState(false);

  const months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień"
  ];

  const getValueForMonth = (index) => {
    const length = index.toString().length;
    const value = (length === 1 && index < 9) ? "0" + (index + 2).toString() : (index + 2).toString();

    return value !== "13" ? value : "01";
  };

  const submit = () => {
    const body = {
      month: selectedMonths,
      year: selectedMonths === "01" ? (Number(selectedYear) + 1).toString() : selectedYear
    };

    const i = Number(selectedMonths) !== 1 ? Number(selectedMonths) - 2 : -1;
    const filename = months[(i >= 0 ) ? i : 11] + '_' + (months[i + 1] ? months[i + 1] : months[0]) + '.xlsx';

    setIsLoading(true);

    Request(true, true).post("/api/monthlyReport", JSON.stringify(body))
      .then(res => {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
    })
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zliczanie przychodu</title>
      </Helmet>

      <Paper className={classes.paper}>
        <Typography variant="h2">Zliczanie przychodu z przełomu miesięcy</Typography>

        <Grid container direction="row" wrap="nowrap" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel>Miesiąc</InputLabel>
              <Select
                label="Miesiąc"
                native
                onChange={e => setSelectedMonths(e.target.value)}
              >
                {months.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={getValueForMonth(index)}
                    >
                      {item}/{months[index + 1] ? months[index + 1] : months[0]}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              id="outlined-basic"
              label="Rok"
              variant="outlined"
              value={selectedYear}
              type="number" min="2022" onChange={(e) => setSelectedYear(e.target.value)}/>
          </Grid>
        </Grid>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => submit()}
        >
          {isLoading ? <CircularProgress color="secondary" /> : 'Generuj'}
        </Button>
      </Paper>
    </div>
  );
}

export default MonthlyIncomeComponent;
