import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import SelectedSygnaturySprzedazContext from "./Sprzedaze/SelectedSygnaturySprzedazContext";
import SelectedSygnaturyZwrotyContext from "./Zwroty/SelectedSygnaturyZwrotyContext";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "40px",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "10px",
    border: "1px solid black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  }
}));

const SelectSygnatura = ({
  filters,
  setFilters,
  setSelectedSprzedazeIds,
  setSetectedZwrotyIds
}) => {
  const classes = useStyles();

  const { setSelectedS } = useContext(SelectedSygnaturySprzedazContext);
  const { setSelectedZ } = useContext(SelectedSygnaturyZwrotyContext);

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <Checkbox
            style={{ color: "black" }}
            checked={filters.online}
            onChange={e => {
              if (filters.stacjonarne || filters.inne || filters.total) {
                setFilters({
                  ...filters,
                  online: !filters.online,
                  total: false
                });
              }
            }}
          />
        }
        label="ONLINE"
      />
      <FormControlLabel
        control={
          <Checkbox
            style={{ color: "black" }}
            checked={filters.stacjonarne}
            onChange={e => {
              if (filters.online || filters.inne || filters.total) {
                setFilters({
                  ...filters,
                  stacjonarne: !filters.stacjonarne,
                  total: false
                });
              }
            }}
          />
        }
        label="STACJONARNE"
      />
      <FormControlLabel
        control={
          <Checkbox
            style={{ color: "black" }}
            checked={filters.inne}
            onChange={e => {
              if (filters.online || filters.stacjonarne || filters.total) {
                setFilters({ ...filters, inne: !filters.inne, total: false });
              }
            }}
          />
        }
        label="INNE"
      />
      <FormControlLabel
        control={
          <Checkbox
            style={{ color: "black" }}
            checked={filters.total}
            onChange={e => {
              if (!filters.total) {
                setSelectedSprzedazeIds([]);
                setSetectedZwrotyIds([]);
                setSelectedS({ online: [], stacjonarne: [], inne: [] });
                setSelectedZ({ online: [], stacjonarne: [], inne: [] });
                setFilters({
                  online: false,
                  stacjonarne: false,
                  inne: false,
                  total: true
                });
              }
            }}
          />
        }
        label="TOTAL"
      />
    </div>
  );
};

export default SelectSygnatura;
