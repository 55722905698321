import Request from "./Request";

export const getDevstatLog = () => {
  return Request(true).get("/api/devstat/log/");
};

export const getLog = (name, lines = 50) => {
  return Request(true).get(`/api/devstat/log/${name}/${lines}`);
};

export const getStatic = () => {
  return Request(true).get(`/api/devstat/static`);
}

export const getLogDbProduct = (page = 1, polkaId = null, tgId = null) => {
  return Request(true).get(`/api/devstat/log/db/sid/product`, {
    params: {
      polkaId,
      tgId,
      page
    }
  });
};

export const getLogDbSprzedaz = (page = 1, polkaId = null, tgId = null) => {
  return Request(true).get(`/api/devstat/log/db/sid/sprzedaz`, {
    params: {
      polkaId,
      tgId
    }
  });
};

export const clearLog = name => {
  return Request(true).delete(`/api/devstat/log/${name}`);
};

export const getFileLog = name => {
  return Request(true).get(`api/devstat/log/get/log/file/${name}`, {
    responseType: "arraybuffer"
  });
};

export const synchroSprzedazDokRazemRequest = docNum => {
  return Request(true).get("/api/devstat/synchro/sprzedaz/" + docNum);
};
