import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import {getLogs} from "../../_services/_tasksService";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 30,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(132, 132, 132, 0.13)",
      borderRadius: 0
    }
  }
}));

const TaskLimitInputDialog = ({ open, setOpen, item }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(50);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setLimit(50);
    setMessage("");
    setOpen(false);
  };

  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();

    getLogs({ limit }, item.id.substr(11))
      .then(res => {
        setMessage(res.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const logs = () => {
    let test = message.split("\n").map((item, i) => {
      return <p key={i}>{item}</p>;
    });

    return test;
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Limit</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="dialog-limit"
            label="limit"
            type="number"
            value={limit}
            onChange={e => setLimit(e.target.value)}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button onClick={e => handleSave(e)} color="primary">
            Wyślij
          </Button>
        </DialogActions>
        {message ? logs() : ""}
      </Dialog>
    </div>
  );
};

export default TaskLimitInputDialog;
