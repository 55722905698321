import React, { useContext, useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import { filterContext } from "../../_context/filterContext";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  divider: theme.divider,
  labels: theme.datePriceLabels,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton
  }
}));

export const DateFilter = () => {
  const {
    state: { dateFrom, dateTo, _update }
  } = useContext(filterContext);

  const getInitDateOfCurrentMonth = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return {
      firstDayOfCurrentMonth: convertDate(firstDay),
      lastDayOfCurrentMonth: convertDate(lastDay)
    };
  };

  const convertDate = date => {
    return (
      date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
    );
  };

  const initDate = getInitDateOfCurrentMonth();

  const [dFrom, setDFrom] = useState(
    dateFrom ? dateFrom : initDate.firstDayOfCurrentMonth
  );

  const [dTo, setDTo] = useState(
    dateTo ? dateTo : initDate.lastDayOfCurrentMonth
  );

  useEffect(() => {
    setDFrom(dateFrom.length ? dateFrom : initDate.firstDayOfCurrentMonth);
  }, [dateFrom, initDate.firstDayOfCurrentMonth, _update]);

  useEffect(() => {
    setDTo(dateTo.length ? dateTo : initDate.lastDayOfCurrentMonth);
  }, [dateTo, initDate.lastDayOfCurrentMonth, _update]);

  const getDateOfLastWeek = () => {
    var beforeOneWeek = new Date(
      new Date().getTime() - 60 * 60 * 24 * 7 * 1000
    );

    var beforeOneWeek2 = new Date(beforeOneWeek);
    var day = beforeOneWeek.getDay();
    var diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
    var lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
    var lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

    return {
      firstDayOfLastWeek: convertDate(lastMonday),
      lastDayOfLastWeek: convertDate(lastSunday)
    };
  };

  const getDateOfLastMonth = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

    return {
      firstDayOfLastMonth: convertDate(firstDay),
      lastDayOfLastMonth: convertDate(lastDay)
    };
  };

  const getDateOfCurrentWeek = () => {
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));

    return {
      firstDayOfCurrentWeek: firstday,
      lastDayOfCurrentWeek: lastday
    };
  };

  const getDateOfCurrentYear = () => {
    var year = new Date().getFullYear();
    var firstDay = new Date(year, 0, 1);
    var lastDay = new Date(year, 11, 31);

    return {
      firstDayOfCurrentYear: convertDate(firstDay),
      lastDayOfCurrentYear: convertDate(lastDay)
    };
  };

  const getDateOfCurrentMonth = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return {
      firstDayOfCurrentMonth: convertDate(firstDay),
      lastDayOfCurrentMonth: convertDate(lastDay)
    };
  };

  const getDateOfLastYear = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear() - 1, 0, 1);
    var lastDay = new Date(date.getFullYear() - 1, 11, 31);

    return {
      firstDayOfLastYear: convertDate(firstDay),
      lastDayOfLastYear: convertDate(lastDay)
    };
  };

  const selectDate = (e, time) => {
    e.preventDefault();
    e.stopPropagation();
    let dFrom = "";
    let dTo = "";

    switch (time) {
      case "today":
        dFrom = convertDate(new Date());
        dTo = convertDate(new Date());

        break;
      case "yesterday":
        const yesterday = new Date(Date.now() - 86400000);
        dFrom = convertDate(yesterday);
        dTo = convertDate(yesterday);

        break;
      case "last_week":
        const dLastWeek = getDateOfLastWeek();
        dFrom = dLastWeek.firstDayOfLastWeek;
        dTo = dLastWeek.lastDayOfLastWeek;

        break;
      case "last_month":
        const dLastMonth = getDateOfLastMonth();
        dFrom = dLastMonth.firstDayOfLastMonth;
        dTo = dLastMonth.lastDayOfLastMonth;

        break;
      case "last_year":
        const dLastYear = getDateOfLastYear();
        dFrom = dLastYear.firstDayOfLastYear;
        dTo = dLastYear.lastDayOfLastYear;
        break;
      case "current_week":
        const dCurrWeek = getDateOfCurrentWeek();
        dFrom = dCurrWeek.firstDayOfCurrentWeek;
        dTo = dCurrWeek.lastDayOfCurrentWeek;

        break;
      case "current_month":
        const dCurrentMonth = getDateOfCurrentMonth();
        dFrom = dCurrentMonth.firstDayOfCurrentMonth;
        dTo = dCurrentMonth.lastDayOfCurrentMonth;
        break;

      case "current_year":
        const dCurrentYear = getDateOfCurrentYear();
        dFrom = dCurrentYear.firstDayOfCurrentYear;
        dTo = dCurrentYear.lastDayOfCurrentYear;

        break;
      default:
        dFrom = "";
        dTo = "";
    }
    setDFrom(dFrom);
    setDTo(dTo);
  };

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Zakres dat</Typography>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={e => selectDate(e, "yesterday")}
            >
              WCZORAJ
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={e => selectDate(e, "last_week")}
            >
              OSTATNI TYDZIEŃ
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={e => selectDate(e, "last_month")}
            >
              OSTATNI MIESIĄC
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={e => selectDate(e, "last_year")}
            >
              OSTATNI ROK
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDate(e, "today")}
            >
              DZISIAJ
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDate(e, "current_week")}
            >
              OBECNY TYDZIEŃ
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDate(e, "current_month")}
            >
              OBECNY MIESIĄC
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={e => selectDate(e, "current_year")}
            >
              OBECNY ROK
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.labels}>
        <Grid item xs>
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            label="Data Od"
            value={dFrom}
            onChange={event => setDFrom(convertDate(event))}
            format="yyyy/MM/dd"
            name="dateFrom"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDFrom(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item className={classes.divider}>
          <br />
          <Divider />
        </Grid>
        <Grid item xs>
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            label="Data Do"
            value={dTo}
            onChange={event => setDTo(convertDate(event))}
            format="yyyy/MM/dd"
            name="dateTo"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.clear} position="end">
                  <HighlightOffRoundedIcon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDTo(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
