import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Indicator } from "../Indicator";
import NewUserDialog from "../Users/NewUserDialog";
import UserTabele from "../Users/UserTabele";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.headerHeight,
    flexGrow: 1,
    backgroundColor: theme.palette.gray,
    minHeight: `calc(100vh - ${theme.headerHeight})`,
    padding: theme.spacing(2),
    boxSizing: "border-box",
    "& .MuiTableCell-root": {
      fontSize: "13px"
    }
  },
  paper: theme.filterPaper
}));

export const GET_USERS = gql`
  {
    users {
      id
      name
      email
      isActive
      roles
      isDevStat
    }
  }
`;

export const UsersComponent = () => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const { loading, error, data } = useQuery(GET_USERS);

  if (loading) return <Indicator></Indicator>;
  if (error) return <>Błąd ładowania userów</>;
  return (
    <div className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Panel</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Dodaj nowego uzytkownika
          </Button>
        </Grid>

        <NewUserDialog open={openDialog} setOpen={setOpenDialog} />
        <Grid item xs={12}>
          <UserTabele data={data} />
        </Grid>
      </Grid>
    </div>
  );
};
