import React, {useContext, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {filterContextDevstat} from "../../../../_context/filterContextDevstat";
import {Grid, makeStyles, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "2px"
  }
}));

export const NoProductsFilter = () => {
  const classes = useStyles();

  const {
    state: {
      noCategory,
      noDesigners,
      noSeasons,
      noBasePriceNetto,
      noLevel2,
      noLevel3,
      noColor
    }
  } = useContext(filterContextDevstat);

  const [productNoCategory, setProductNoCategory] = useState(noCategory);
  const [productNoDesigner, setProductNoDesigner] = useState(noDesigners);
  const [productNoSeasons, setProductNoSeasons] = useState(noSeasons);
  const [productNoBasePriceNetto, setProductNoBasePriceNetto] = useState(
    noBasePriceNetto
  );
  const [productNoLevel2, setProductNoLevel2] = useState(noLevel2);
  const [productNoLevel3, setProductNoLevel3] = useState(noLevel3);
  const [productNoColor, setProductNoColor] = useState(noColor);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Brak</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={3} className={classes.marginBottom}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productNoCategory === 1 ? true : false}
                    onChange={e =>
                      setProductNoCategory(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label="Kategoria"
                name="noCategory"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productNoSeasons === 1 ? true : false}
                    onChange={e =>
                      setProductNoSeasons(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label="Sezon"
                name="noSeasons"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} className={classes.marginBottom}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productNoDesigner === 1 ? true : false}
                    onChange={e =>
                      setProductNoDesigner(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label="Projektant"
                name="noDesigners"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={productNoBasePriceNetto === 1 ? true : false}
                    onChange={e =>
                      setProductNoBasePriceNetto(
                        e.target.checked === true ? 1 : 0
                      )
                    }
                  />
                }
                label="Cena bazowa"
                name="noBasePriceNetto"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} className={classes.marginBottom}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productNoLevel2 === 1 ? true : false}
                    onChange={e =>
                      setProductNoLevel2(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label="Kat. poziom 2"
                name="noLevel2"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={productNoLevel3 === 1 ? true : false}
                    onChange={e =>
                      setProductNoLevel3(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label="Kat. poziom 3"
                name="noLevel3"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} className={classes.marginBottom}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={productNoColor === 1 ? true : false}
                    onChange={e =>
                      setProductNoColor(e.target.checked === true ? 1 : 0)
                    }
                  />
                }
                label="Kolor"
                name="noColor"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};
