export const DATE_FROM = "date_from";
export const DATE_TO = "date_to";
export const CLEAR_DATE = "clear_date";

export const PRICE_FROM = "price_from";
export const PRICE_TO = "price_to";
export const CLEAR_PRICE = "clear_date";

export const DISCOUNT_FROM = "discount_from";
export const DISCOUNT_TO = "discount_to";
export const CLEAR_DISCOUNT = "clear_discount";

export const IS_PAID = "is_paid";

export const IS_SALE = "is_sale";

export const URL_QUERY = "url_query";

export const PRODUCT_QUERY = "product_query";

export const LOAD_FILTERS = "load_filters";

export const UPDATE_SEASON = "updated_season_filter";
export const UPDATE_SYGNATURAS = "updated_sygnaturas_filter";

export const dateFrom = date => dispatch => {
  dispatch({ type: DATE_FROM, payload: date });
};

export const dateTo = date => dispatch => {
  dispatch({ type: DATE_TO, payload: date });
};

export const clearDate = () => dispatch => {
  dispatch({ type: CLEAR_DATE });
};

export const priceFrom = price => dispatch => {
  dispatch({ type: PRICE_FROM, payload: price });
};

export const priceTo = price => dispatch => {
  dispatch({ type: PRICE_TO, payload: price });
};

export const clearPrice = () => dispatch => {
  dispatch({ type: CLEAR_PRICE });
};

export const discountFrom = dis => dispatch => {
  dispatch({ type: DISCOUNT_FROM, payload: dis });
};

export const discountTo = dis => dispatch => {
  dispatch({ type: DISCOUNT_TO, payload: dis });
};

export const clearDiscount = () => dispatch => {
  dispatch({ type: CLEAR_DISCOUNT });
};

export const isPaid = paid => dispatch => {
  dispatch({ type: IS_PAID, payload: paid });
};

export const isSale = sale => dispatch => {
  dispatch({ type: IS_SALE, payload: sale });
};

export const urlQuery = query => dispatch => {
  dispatch({ type: URL_QUERY, payload: query });
};

export const productQuery = query => dispatch => {
  dispatch({ type: PRODUCT_QUERY, payload: query });
};

export const loadFilters = filters => dispatch => {
  dispatch({ type: LOAD_FILTERS, payload: filters });
};

export const updateSeasons = seasons => dispatch => {
  dispatch({ type: UPDATE_SEASON, payload: seasons });
};

export const updateSygnaturas = sygnatura => dispatch => {
  dispatch({ type: UPDATE_SYGNATURAS, payload: sygnatura });
};
