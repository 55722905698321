import React, {useContext, useState} from "react";
import {filterContextDevstat} from "../../../../_context/filterContextDevstat";
import {Grid, makeStyles, Paper, TextField, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: theme.filterPaper,
  inputField: {
    ...theme.inputField
  },
  marginBottom: {
    marginBottom: "10px"
  }
}));

export const NazwaFilter = () => {
  const classes = useStyles();

  const {
    state: { nazwa }
  } = useContext(filterContextDevstat);

  const [productNazwa, setProductNazwa] = useState(nazwa);

  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Nazwa</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={12} className={classes.marginBottom}>
          <TextField
            key="nazwa"
            label="Nazwa"
            value={productNazwa || ""}
            variant="outlined"
            onChange={event => setProductNazwa(event.target.value)}
            name="nazwa"
            className={classes.inputField}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
