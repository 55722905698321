import { useContext } from "react";
import ColumnsSortContext from "./ColumnSortContext";

const useColumns = () => {
  const { sortColumns } = useContext(ColumnsSortContext);

  const getColumnsSprzedaz = tableName => {
    const arr = [
      {
        name: "name",
        label: "Sygnatura",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].sprzedaz.name === "name"
              ? sortColumns[tableName].sprzedaz.direction
              : null
        }
      },
      {
        name: "qtyTotal",
        label: "Sprzedaże",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].sprzedaz.name === "qtyTotal"
              ? sortColumns[tableName].sprzedaz.direction
              : null
        }
      },
      {
        name: "percentRabat",
        label: "Średni rabat [%]",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].sprzedaz.name === "percentRabat"
              ? sortColumns[tableName].sprzedaz.direction
              : null,
          customBodyRender: value => {
            return parseFloat(value).toFixed(2);
          }
        }
      },
      {
        name: "priceNetto",
        label: "Suma netto [PLN]",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].sprzedaz.name === "priceNetto"
              ? sortColumns[tableName].sprzedaz.direction
              : null,
          customBodyRender: value => {
            return parseFloat(value).toFixed(2);
          }
        }
      },
      {
        name: "avgPriceNetto",
        label: "Średnia cena netto [PLN]",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].sprzedaz.name === "avgPriceNetto"
              ? sortColumns[tableName].sprzedaz.direction
              : null,
          customBodyRender: value => {
            return parseFloat(value).toFixed(2);
          }
        }
      }
    ];

    return arr;
  };

  const getColumnsZwroty = tableName => {
    const arr = [
      {
        name: "name",
        label: "Sygnatura",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].zwroty.name === "name"
              ? sortColumns[tableName].zwroty.direction
              : null
        }
      },
      {
        name: "qtyTotal",
        label: "Zwroty",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].zwroty.name === "qtyTotal"
              ? sortColumns[tableName].zwroty.direction
              : null
        }
      },
      {
        name: "percentRabat",
        label: "Średni rabat [%]",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].zwroty.name === "percentRabat"
              ? sortColumns[tableName].zwroty.direction
              : null,
          customBodyRender: value => {
            return parseFloat(value).toFixed(2);
          }
        }
      },
      {
        name: "priceNetto",
        label: "Suma netto [PLN]",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].zwroty.name === "priceNetto"
              ? sortColumns[tableName].zwroty.direction
              : null,
          customBodyRender: value => {
            return parseFloat(value).toFixed(2);
          }
        }
      },
      {
        name: "avgPriceNetto",
        label: "Średnia cena netto [PLN]",
        options: {
          filter: true,
          sort: true,
          sortDirection:
            sortColumns[tableName].zwroty.name === "avgPriceNetto"
              ? sortColumns[tableName].zwroty.direction
              : null,
          customBodyRender: value => {
            return parseFloat(value).toFixed(2);
          }
        }
      }
    ];

    return arr;
  };

  return { getColumnsSprzedaz, getColumnsZwroty };
};

export default useColumns;
