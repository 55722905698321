import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.lightGray
  },
}));

const SavedFilterItem = ({ item, isEditable, remove, load }) => {
  const classes = useStyles();

  return isEditable ? (
    <Chip
      label={item.name}
      size="small" 
      deleteIcon={<DeleteForeverRoundedIcon className={classes.icon}/>}
      onDelete={e => remove(item)}
    ></Chip>
  ) : (
    <Chip label={item.name} size="small" onClick={e => load(item)}></Chip>
  );
};

export default SavedFilterItem;
