import React from "react";
import MUIDataTable from "mui-datatables";
import { parseToFixed, thousandSeparator } from "../../../_helpers/separatorValue";

export const OrdersDateDetailSezon = ({ data }) => {
  const dataParsed = data.map(it => {
    return {
      ...it,
      qtySell: parseInt(it.qtySell, 10),
      qtyReturned: parseInt(it.qtyReturned, 10),
      qtyTotal: parseInt(it.qtyTotal, 10),
      percentRabat: parseToFixed(it.percentRabat, 2),
      avgPriceNetto: parseToFixed(it.avgPriceNetto),
      priceNetto: parseToFixed(it.priceNetto, 2),
      percentRabatReturned: parseToFixed(it.percentRabatReturned, 2),
      avgPriceBrutto: parseToFixed(it.avgPriceBrutto, 2),
      priceBrutto: parseToFixed(it.priceBrutto, 2),
      percentRabatSell: parseToFixed(it.percentRabatSell, 2)
    };
  });

  const option = {
    pagination: false,
    expandableRows: false,
    expandableRowsOnClick: false,
    print: false,
    disableToolbarSelect: true,
    selectableRowsHeader: false,
    selectableRows: "none",
    filter: false,
    download: true,
    textLabels: {
      toolbar: {
        search: "Szukaj",
        viewColumns: "Pokaż kolumny",
        filterTable: "Filtruj tabelę"
      },
      filter: {
        all: "Wszystkie",
        title: "Filtry",
        reset: "Wyczyść"
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleArea: "Pokaż/Schowaj kolumnę"
      }
    }
  };

  const columns = [
    {
      name: "sezon",
      label: "Sezon",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return value ? value : "BRAK SEZONU";
        }
      }
    },
    {
      name: "qtySell",
      label: "Sprzedane [szt.]",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "qtyReturned",
      label: "Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "qtyTotal",
      label: "Sprzedane-Zwrócone [szt.]",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "percentRabatSell",
      label: "Rabat [sprz./ zw. %]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <p value={value}>
              <b>{value}</b>
              {" | " + parseToFixed(data[tableMeta.rowIndex].percentRabatReturned, 2)}
            </p>
          );
        }
      }
    },
    {
      name: "percentRabat",
      label: "Średni rabat[%]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return <p value={value}>{value}</p>;
        }
      }
    },
    {
      name: "avgPriceNetto",
      label: "Średnia centa netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return <p value={value}>{thousandSeparator(value)}</p>;
        }
      }
    },
    {
      name: "priceNetto",
      label: "Suma netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return <p value={value}>{thousandSeparator(value)}</p>;
        }
      }
    },
    {
      name: "wartoscMagazynowaNetto",
      label: "Suma cena bazowa netto [PLN]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return (
            <p value={value}>{thousandSeparator(parseToFixed(value, 2))}</p>
          );
        }
      }
    },
    {
      name: "priceNettoPercentRabat",
      label: "Rabat suma sprzedazy/ suma cena bazowa[%]",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return (
            <p value={value}>{thousandSeparator(parseToFixed(value, 2))}</p>
          );
        }
      }
    }
  ];

  return (
    <>{<MUIDataTable data={dataParsed} columns={columns} options={option} />}</>
  );
};
