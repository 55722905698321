import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";

const REMOVE_TASK2KILL = gql`
  mutation deleteTask2Kill($id: ID!) {
    deleteTask2Kill(input: { id: $id }) {
      task2Kill {
        id
      }
    }
  }
`;

export const GET_TASK_2_KILL = gql`
  {
    task2Kills {
      id
      command
    }
  }
`;

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 100
  }
}));

function createData(id, command) {
  return {
    id,
    command
  };
}

export const Task2KillTabele = () => {
  const classes = useStyles();

  const [removeTask2Kill] = useMutation(REMOVE_TASK2KILL, {
    update(cache, { data }) {
      const { task2Kills } = cache.readQuery({ query: GET_TASK_2_KILL });
      cache.writeQuery({
        query: GET_TASK_2_KILL,
        data: {
          task2Kills: task2Kills.filter(
            it => it.id !== data.deleteTask2Kill.task2Kill.id
          )
        }
      });
    }
  });

  const { loading, error, data } = useQuery(GET_TASK_2_KILL);
  if (loading) return <>Loading</>;
  if (error) return <>Błąd ładowania taksów</>;

  const rows = data.task2Kills.map(it => createData(it.id, it.command));

  const deleteTask = item => {
    removeTask2Kill({
      variables: { id: item.id }
    });
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">Command</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.command}</TableCell>
              <TableCell align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={e => deleteTask(row)}
                  >
                    End
                  </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
