import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { Link, NavLink } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {},
  appBar: {
    backgroundColor: theme.palette.common.white
  },
  header: {
    boxShadow: "0 -6px 23px 0 rgba(146,146,146,0.15)",
    borderBottom: `1px solid ${theme.palette.border}`
  },
  headerTitle: {
    marginRight: "2vw",
    marginLeft: "5px"
  },
  toolbar: {
    minHeight: theme.headerHeight,
    height: theme.headerHeight,
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  menu: {
    height: "100%"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black"
  },
  menuItem: {
    marginLeft: 25,
    flexGrow: 1,
    color: "black",
    textTransform: "uppercase",
    display: "flex",
    height: "100%"
  },
  link: {
    textDecoration: "none",
    paddingTop: "12px",
    color: "black"
  },
  activeLink: {
    borderTop: "2px solid #000",
    paddingTop: "10px"
  },
  logout: {
    color: theme.palette.common.black
  },
  logoutIcon: {
    marginRight: "5px"
  }
}));

export const AppMenu = () => {
  const classes = useStyles();

  const { isDevStat } = useSelector(state => state.auth);
  const { name } = useSelector(state => state.auth);

  const [anchorEl, setAnchorEl] = React.useState({ value: null, name: null });

  const handleClick = (event, name) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl({ value: event.currentTarget, name: name });
  };

  const handleClose = () => {
    setAnchorEl({ value: null, name: null });
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        classes={{ root: classes.header }}
      >
        <Toolbar classes={{ root: classes.toolbar }}>
          <div>
            <img src={process.env.PUBLIC_URL + "/vitkac-logo.png"} alt="" />
          </div>
          <Typography variant="caption" className={classes.headerTitle}>
            Analizator sprzedaży
          </Typography>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            className={classes.menu}
          >
            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/bestseller"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                >
                  Produkty
                </NavLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/sale/order"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                >
                  Zamówienia
                </NavLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/sale/designers"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                >
                  Projektanci
                </NavLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/sale/lokalizacje"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                >
                  Lokalizacje
                </NavLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/sale/sygnatury"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                >
                  Sygnatury
                </NavLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/sale/kategorie"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                >
                  Kategorie
                </NavLink>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/realtime"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                >
                  RealTime
                </NavLink>
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h6" className={classes.menuItem}>
                <NavLink
                  to="/other"
                  activeClassName={classes.activeLink}
                  className={classes.link}
                  onClick={(e) => handleClick(e, 'other')}
                >
                  Inne
                </NavLink>
                <Menu
                  anchorEl={anchorEl.value}
                  keepMounted
                  open={Boolean(anchorEl.value) && anchorEl.name === "other"}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <NavLink
                      to="/other/monthly-income"
                      onClick={handleClose}
                      className={classes.link}
                    >
                      Zliczanie przychodu
                    </NavLink>
                  </MenuItem>
                </Menu>
              </Typography>
            </Grid>

            {isDevStat ? (
              <Grid item>
                <Typography variant="h6" className={classes.menuItem}>
                  <NavLink
                    to="/devices"
                    activeClassName={classes.activeLink}
                    className={classes.link}
                  >
                    Mobile
                  </NavLink>
                </Typography>
              </Grid>
            ) : null}

            {isDevStat ? (
              <Grid item>
                <Typography variant="h6" className={classes.menuItem}>
                  <NavLink
                    to="/devstat"
                    activeClassName={classes.activeLink}
                    className={classes.link}
                    onClick={e => handleClick(e, 'admin')}
                  >
                    Admin
                  </NavLink>
                  <Menu
                    anchorEl={anchorEl.value}
                    keepMounted
                    open={Boolean(anchorEl.value) && anchorEl.name === "admin"}
                    onClose={handleClose}
                  >
                    <MenuItem>
                      <NavLink
                        to="/devstat"
                        onClick={handleClose}
                        className={classes.link}
                      >
                        Devstat
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to="/devstat/log"
                        onClick={handleClose}
                        className={classes.link}
                      >
                        Logs
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to="/tasks"
                        onClick={handleClose}
                        className={classes.link}
                      >
                        Tasks
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to="/users"
                        onClick={handleClose}
                        className={classes.link}
                      >
                        Users
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to="/admin/devices"
                        onClick={handleClose}
                        className={classes.link}
                      >
                        User Devices
                      </NavLink>
                    </MenuItem>

                    <MenuItem>
                      <NavLink
                        to="/devstat/check/sales"
                        onClick={handleClose}
                        className={classes.link}
                      >
                        Check Sell
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Link to="/logout" style={{ textDecoration: "none" }}>
            <Button color="inherit" className={classes.logout}>
              <LogoutIcon className={classes.logoutIcon}></LogoutIcon>
              Wyloguj - {name}
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
};
