import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { GET_SAVED_FILTER } from "./SavedFilter";

const SAVE_FILTER = gql`
  mutation CreateSavedFilter($name: String!, $value: Iterable!) {
    createSavedFilter(input: { name: $name, value: $value }) {
      savedFilter {
        id
        name
        value
      }
    }
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const SaveFilterDialog = ({ open, setOpen, formValue }) => {
  const [name, setName] = useState("");

  const [saveFilter] = useMutation(SAVE_FILTER, {
    update(cache, { data }) {
      const { savedFilters } = cache.readQuery({ query: GET_SAVED_FILTER });
      cache.writeQuery({
        query: GET_SAVED_FILTER,
        data: {
          savedFilters: [...savedFilters, data.createSavedFilter.savedFilter]
        }
      });
    }
  });

  const handleClose = () => {
    setName("");
    setOpen(false);
  };

  const form = formValue();

  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();

    saveFilter({
      variables: {
        name: name,
        value: form
      }
    })
      .then(it => handleClose())
      .catch(err => console.log(err));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="form-dialog-title">Zapisz filtry</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="save-filter-dialog-name"
            label="Nazwa"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button onClick={e => handleSave(e)} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SaveFilterDialog;
