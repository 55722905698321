import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect, useState } from "react";
import { ActiveFilter } from "../../_components/Filters/_activeFilter/ActiveFilter";
import { filterContext } from "../../_context/filterContext";
import { getBestsellerList, getBestsellerListAsXlsx } from "../../_services/_bestsellerService";
import { Pagination } from "../Filters/Pagination";
import { Sort } from "../Filters/Sort";
import BestletterListItem from "./BestletterListItem";
import BestsellerListItemPlaceholder from "./BestsellerListItemPlaceholder";
import NoProductAlert from "../../_helpers/NoProductAlert";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "-4px"
  },
  grid: {
    "& .activeFilter": {
      paddingTop: 0
    }
  },
  paper: theme.bestsellerPaper,
  placeholderPaper: theme.placeholderPaper,
  noMargin: theme.noMargin,
  noMarginY: theme.noMarginY,
  noMarginRight: {
    ...theme.noMarginRight,
    ...theme.noMarginY
  },
  filterContainer: {
    paddingTop: "0!important"
  },
  container: {
    marginTop: "-17px"
  }
}));

const BestsellerList = ({ setView, IsPagination = true }) => {
  const classes = useStyles();
  const { state } = useContext(filterContext);
  const { roles } = useSelector(state => state.auth);
  const [bestsellerList, setBestsellerList] = useState([]);
  const [totalItems, setTotalItems] = useState(state.totalItems);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(state.limit);
  const limitOptions = [20, 40, 60, 80, 100];
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    getBestsellerList(state)
      .then(res => {
        setBestsellerList(res.data.results);
        setTotalItems(parseInt(res.data.pagination.totalItems, 10));
        setTotalPages(parseInt(res.data.pagination.totalPages, 10));
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });

    return () => {};
  }, [state]);

  const loading = [...Array(5)].map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.placeholderPaper} key={index}>
          <BestsellerListItemPlaceholder />
        </Paper>
      </Grid>
    );
  });

  const items = bestsellerList.map((it, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Paper className={classes.paper}>
          <BestletterListItem item={it} index={index}></BestletterListItem>
        </Paper>
      </Grid>
    );
  });

  const pagination =
    IsPagination === true ? (
      <Pagination
        totalItems={totalItems}
        limitOptions={limitOptions}
        totalPages={totalPages}
        currentLimit={limit}
        setLimit={setLimit}
        context={filterContext}
      ></Pagination>
    ) : null;

  const noProductAlert = <NoProductAlert setView={setView}></NoProductAlert>;

  const downloadXlsx = () => {
    getBestsellerListAsXlsx(state)
      .then(res => {
        const filename = 'produkty.xlsx'
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
  }

  return (
    <Grid container spacing={3} className={classes.grid}>
      {IsPagination ? (
        <Grid
          item
          xs={12}
          container
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Grid item xs={3}>
            <Typography variant="body2">
              {totalItems ? totalItems + " produktów" : "0 produktów"}
            </Typography>
          </Grid>
          <Grid item xs container justify="flex-end" spacing={3}>
            <Grid item>
              <Sort></Sort>
            </Grid>
            <Grid item>{pagination}</Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <ActiveFilter></ActiveFilter>
      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.container}>
          {isLoading ? loading : totalItems > 0
            ? <>
            {roles?.find(r => r === 'ROLE_EXCEL_EXPORT') ? (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={e => downloadXlsx(e)}
                >
                  Eksportuj dane
                </Button>
              </Grid>
            ) : null}
              {items}
            </>
             : noProductAlert}
        </Grid>
      </Grid>
      {IsPagination ? (
        <Grid
          item
          xs={12}
          container
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Grid item xs container justify="flex-end" spacing={3}>
            <Grid item>{totalItems > 0 ? pagination : <></>}</Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default BestsellerList;
