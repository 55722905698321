import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { filterContext } from "../../_context/filterContext";
import {
  getPreviousMonthDates,
  getPreviousYearDate,
  getPreviousYearDates
} from "../../_helpers/CalculateDates";
import { convertDate } from "../../_helpers/ConvertDate";
import { getSale } from "../../_services/_saleService";

const useStyles = makeStyles(theme => ({
  root: {
    height: 220,
    width: 410,
    backgroundColor: "white",
    border: "1px",
    borderRadius: "5px",
    mozBoxshadow: "0 0 5px #999",
    webkitBoxshadow: "0 0 5px #999",
    boxShadow: "0 0 5px #999",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    flex: 1,
    borderBottom: "2px solid #F0F0F0",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px"
  },
  content: {
    flex: 2,
    borderBottom: "2px solid #F0F0F0",
    display: "flex",
    flexDirection: "column",
    padding: "10px"
  },
  actions: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  buttonAction: {
    height: "25px"
  },
  dateLabels: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "10px",
    alignItems: "center"
  },
  separator: {
    width: "30px",
    height: "1px",
    backgroundColor: "#808080"
  }
}));

export const LAST_YEAR_TYPE = "last_year_type";
export const LAST_PERIOD_TYPE = "last_period_type";

const ComparePeriodTime = ({
  setIsActiveCompareTimeLabel,
  setIsActiveCompareTimeButton,
  setIsLoading,
  compareFunction
}) => {
  const classes = useStyles();
  const { state } = useContext(filterContext);
  const [dateFromToCompare, setDateFromToCompare] = useState(null);
  const [dateToToCompare, setDateToToCompare] = useState(null);
  const [compareType, setCompareType] = useState(null);

  const [previousTimeButton, setPreviousTimeButton] = useState(false);

  useEffect(() => {
    const isYear = state.groupBy[0].isActive;
    const isQuarter = state.groupBy[1].isActive;
    const isMonth = state.groupBy[2].isActive;
    const isDay = state.groupBy[3].isActive;

    if (isYear && isMonth && isDay && !isQuarter) setPreviousTimeButton(true);
    if (isYear && isMonth && !isDay && !isQuarter) setPreviousTimeButton(true);
  }, [state.groupBy]);

  const handleDateToCompareLastYear = () => {
    setCompareType(LAST_YEAR_TYPE);
    const dateFrom = state.dateFrom;
    const dateTo = state.dateTo;

    if (!dateFrom || !dateTo) {
      return;
    }

    setDateFromToCompare(getPreviousYearDate(dateFrom));
    setDateToToCompare(getPreviousYearDate(dateTo));
  };

  /* we check if the dates are a full month, if yes, we set the previous month, 
     if not, we subtract from the dates the difference of days between the dates */
  const handleDateToComparePreviousPeriod = () => {
    setCompareType(LAST_PERIOD_TYPE);
    const dateFrom = state.dateFrom;
    const dateTo = state.dateTo;

    if (!dateFrom || !dateTo) {
      return;
    }

    const dateFromMonth = new Date(dateFrom).getMonth();
    const dateToMonth = new Date(dateTo).getMonth();

    const isFirstDayOfMonth = isFirstDay(dateFrom);
    const isLastDayOfMonth = isLastDay(dateTo);

    if (
      dateFromMonth === dateToMonth &&
      isFirstDayOfMonth &&
      isLastDayOfMonth
    ) {
      const lastMonthDates = getPreviousMonthDates(dateFrom);

      setDateFromToCompare(lastMonthDates.firstDay);
      setDateToToCompare(lastMonthDates.lastDay);
      return;
    }

    const countDayBetween = getCountDayBeetweenDates(
      new Date(dateFrom),
      new Date(dateTo)
    );

    if (countDayBetween >= 365) {
      const lastYearDates = getPreviousYearDates(dateFrom);
      setDateFromToCompare(lastYearDates.firstDay);
      setDateToToCompare(lastYearDates.lastDay);
      return;
    }

    const previousPeriodDateFrom = getPreviousPeriod(dateFrom, countDayBetween);
    const previousPeriodDateTo = getPreviousPeriod(dateTo, countDayBetween);

    setDateFromToCompare(previousPeriodDateFrom);
    setDateToToCompare(previousPeriodDateTo);
  };

  const getPreviousPeriod = (date, dayCount) => {
    let dt = new Date(date);

    dt.setDate(dt.getDate() - (dayCount + 1));

    return convertDate(dt);
  };

  const getCountDayBeetweenDates = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  };

  const isLastDay = dt => {
    let test = new Date(dt);
    const month = test.getMonth();

    test.setDate(test.getDate() + 1);
    return test.getMonth() !== month;
  };

  const isFirstDay = dt => {
    let test = new Date(dt);
    const month = test.getMonth();

    test.setDate(test.getDate() - 1);
    return test.getMonth() !== month;
  };

  const handleAction = () => {
    setIsLoading(true);
    setIsActiveCompareTimeLabel(false);
    setIsActiveCompareTimeButton(true);

    const filters = {
      ...state,
      dateFrom: dateFromToCompare,
      dateTo: dateToToCompare,
      groupBy: state.groupBy
        .filter(it => it.isActive)
        .map(it => {
          return it.value;
        })
    };

    getSale(filters)
      .then(res => {
        compareFunction(res.data, compareType);

        return res;
      })
      .catch(err => console.error(err));
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>Porównaj</div>
      <div className={classes.content}>
        <div>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonAction}
            onClick={handleDateToCompareLastYear}
          >
            TEN SAM OKRES UBIEGŁEGO ROKU
          </Button>
        </div>

        {previousTimeButton ? (
          <div>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonAction}
              style={{ marginTop: 10, marginBottom: 10 }}
              onClick={handleDateToComparePreviousPeriod}
            >
              POPRZEDNI OKRES
            </Button>
          </div>
        ) : null}

        <div className={classes.dateLabels}>
          <TextField
            inputProps={{
              readOnly: true,
              disabled: false
            }}
            id="outlined-basic"
            label="Data od"
            variant="outlined"
            value={dateFromToCompare ? dateFromToCompare : ""}
          />
          <div className={classes.separator}></div>
          <TextField
            id="outlined-basic"
            label="Data do"
            variant="outlined"
            inputProps={{
              readOnly: true,
              disabled: false
            }}
            value={dateToToCompare ? dateToToCompare : ""}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonAction}
          onClick={handleAction}
          disabled={!state.dateFrom || !state.dateTo ? true : false}
        >
          ZASTOSUJ
        </Button>
        <Button
          color="primary"
          className={classes.buttonAction}
          onClick={() => setIsActiveCompareTimeLabel(false)}
        >
          ANULUJ
        </Button>
      </div>
    </div>
  );
};

export default ComparePeriodTime;
