export const getTotalData = data => {
  let newArr = [];

  var flattenedData = [
    data && data.online ? data.online.all.data : [],
    data && data.stationary ? data.stationary.all.data : [],
    data && data.other ? data.other.all.data : []
  ].reduce(function(a, b) {
    return a.concat(b);
  });

  flattenedData.map(it => {
    let find = newArr.find(dataObj => dataObj.hour === it.hour);

    if (find) {
      find.qtyTotal += it.qtyTotal;
      find.priceNetto += it.priceNetto;
    } else {
      var newObj = {
        qtyTotal: it.qtyTotal,
        priceNetto: it.priceNetto,
        hour: it.hour
      };
      newArr.push(newObj);
    }

    return it;
  });

  return newArr;
};
