import React, { useState, useEffect, useContext } from "react";
import { filterContext } from "../../../_context/filterContext";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { OrdersDateDetailRabat } from "./OrdersDateDetailRabat";
import { OrdersDateDetailSezon } from "./OrdersDateDetailSezon";
import {
  getSaleRabatDetailByDate,
  getSaleSeasonDetailByDate
} from "../../../_services/_saleService";
import TablePlaceholder from "../../../_components/TablePlaceholder";

const useStyles = makeStyles({
  typography: {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "bold"
  }
});

export const OrdersDateDetailComponent = ({ date }) => {
  const classes = useStyles();
  const [dataRabat, setDataRabat] = useState([]);
  const [dataSezon, setDataSezon] = useState([]);
  const { state } = useContext(filterContext);
  const [isLoadingRabat, setIsLoadingRabat] = useState(false);
  const [isLoadingSezon, setIsLoadingSezon] = useState(false);

  useEffect(() => {
    setIsLoadingRabat(true);
    setIsLoadingSezon(true);

    const filters = {
      ...state,
      groupBy: state.groupBy
        .filter(it => it.isActive)
        .map(it => {
          return it.value;
        })
    };

    getSaleRabatDetailByDate(filters, date)
      .then(res => {
        setDataRabat(res.data);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsLoadingRabat(false);
        window.scrollTo(0, 0);
      });

    getSaleSeasonDetailByDate(filters, date)
      .then(res => {
        setDataSezon(res.data);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsLoadingSezon(false);
        window.scrollTo(0, 0);
      });
  }, [date, state]);

  return (
    <>
      <div className={classes.typography}>
        <Typography variant="subtitle1">{date}</Typography>
      </div>
      <div>
        {isLoadingRabat ? (
          <TablePlaceholder />
        ) : Object.keys(dataRabat).length === 0 ? null : (
          <OrdersDateDetailRabat data={dataRabat}></OrdersDateDetailRabat>
        )}

        {isLoadingSezon ? (
          <TablePlaceholder />
        ) : Object.keys(dataSezon).length === 0 ? null : (
          <OrdersDateDetailSezon data={dataSezon}></OrdersDateDetailSezon>
        )}
      </div>
    </>
  );
};
