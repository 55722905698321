import React from "react";
import {Grid, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  nazwa: {
    fontWeight: "bold",
    color: "black"
  },
  text: {
    fontSize: "0.9em"
  }
}));

const DevstatKategoriaItem = ({ kategorie }) => {
  const classes = useStyles();
  let _key = 0;

  const result = kategorie.map(it => {
    _key += 1;
    return (
      <div key={_key}>
        <Grid item className={classes.nazwa}>
          {it.nazwa}
        </Grid>
        <Grid item className={classes.text}>idSklep: {it.id_sklep}</Grid>
        <Grid item className={classes.text}>idOdl: {it.id_odl}</Grid>
      </div>
    );
  });

  return <div className={classes.root}>{result}</div>;
};

export default DevstatKategoriaItem;
