import React, {useContext, useState} from "react";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import SelectedSygnaturySprzedazContext from "./SelectedSygnaturySprzedazContext";
import ColumnsSortContext from "../ColumnSortContext";

const RealTimeSummaryTableSprzedaze = ({
  data,
  columns,
  keyName,
  selectedSygnaturyIds,
  setSelectedSygnaturyIds
}) => {
  const { sortColumns, setSortColumns } = useContext(ColumnsSortContext);
  const { selectedS, setSelectedS } = useContext(
    SelectedSygnaturySprzedazContext
  );
  const [rowsSelected, setRowsSelected] = useState(selectedS[keyName]);

  const handleChangeSelected = val => {
    if (val.length > 1 || !val.length) {
      return selectAllRows(val);
    }

    const index = val[0].dataIndex;
    const item = data[index];

    //selected rows in table
    if (rowsSelected.indexOf(index) !== -1) {
      setRowsSelected(rowsSelected.filter(it => it !== index));

      let newObj = selectedS;
      newObj[keyName] = rowsSelected.filter(it => it !== index);

      setSelectedS(newObj);
    } else {
      setRowsSelected([...rowsSelected, index]);

      let newObj = selectedS;
      newObj[keyName] = [...rowsSelected, index];

      setSelectedS(newObj);
    }

    //selected sygnatury ids from all tables
    if (selectedSygnaturyIds.indexOf(item.sygnaturaId) !== -1) {
      setSelectedSygnaturyIds(
        selectedSygnaturyIds.filter(it => it !== item.sygnaturaId)
      );
    } else {
      setSelectedSygnaturyIds([...selectedSygnaturyIds, item.sygnaturaId]);
    }
  };

  const selectAllRows = arr => {
    if (!arr.length) {
      const sygnaturyIdsToRemove = [];
      selectedS[keyName].map(it => {
        sygnaturyIdsToRemove.push(data[it].sygnaturaId);

        return it;
      });

      const newArrSelectedSygnaturyIds = [];

      selectedSygnaturyIds.map(it => {
        const exist = sygnaturyIdsToRemove.indexOf(it) >= 0;

        if (!exist) {
          newArrSelectedSygnaturyIds.push(it);
        }

        return it;
      });

      let newObj = selectedS;
      newObj[keyName] = [];
      setSelectedS(newObj);
      setSelectedSygnaturyIds(newArrSelectedSygnaturyIds);
      setRowsSelected([]);
    } else {
      const sygnaturyIds = [];

      const idx = arr.map(it => {
        sygnaturyIds.push(data[it.index].sygnaturaId);
        return it.index;
      });

      let newObj = selectedS;
      newObj[keyName] = idx;
      setSelectedS(newObj);
      setSelectedSygnaturyIds([...selectedSygnaturyIds, ...sygnaturyIds]);
      setRowsSelected(idx);
    }
  };

  const changeColumnSortContext = (columnName, direction) => {
    let newSortColumns = sortColumns;
    let newDirection = false;

    if (direction === "ascending") {
      newDirection = "asc";
    } else {
      newDirection = "desc";
    }

    newSortColumns[keyName].sprzedaz.name = columnName;
    newSortColumns[keyName].sprzedaz.direction = newDirection;

    setSortColumns(newSortColumns);
  };

  const options = {
    download: false,
    print: false,
    rowsPerPage: 10,
    selectableRows: "multiple",
    disableToolbarSelect: true,
    rowsSelected: rowsSelected,
    selectableRowsHeader: true,
    sortFilterList: true,
    onRowsSelect: handleChangeSelected,
    onColumnSortChange: (changedColumn, direction) => {
      changeColumnSortContext(changedColumn, direction);
    }
  };

  return (
    <Paper>
      <MUIDataTable data={data} columns={columns} options={options} />
    </Paper>
  );
};

export default RealTimeSummaryTableSprzedaze;
