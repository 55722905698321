import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../../_context/filterContext";
import { getWartosci } from "../../../_services/_bestsellerService";
import TableComponent from "../components/TableComponent";
import Grid from "@material-ui/core/Grid";
import TablePlaceholder from "../../../_components/TablePlaceholder";

export const WartosciComponent = ({ switchValue, id }) => {
  const { state } = useContext(filterContext);
  const [wartosci, setWartosci] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getWartosci(switchValue ? state : { isPaid: 1 }, id)
      .then(res => {
        setWartosci(res.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state, id, switchValue]);

  const headers = [
    {
      id: "fullDate",
      numeric: false,
      disablePadding: true,
      label: "Data"
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane - Zwrócone [szt.]"
    },
    {
      id: "sell",
      numeric: true,
      disablePadding: false,
      label: "Sprzedane [szt.]"
    },
    {
      id: "returned",
      numeric: true,
      disablePadding: false,
      label: "Zwrócone [szt.]"
    },
    {
      id: "rabat",
      numeric: true,
      disablePadding: false,
      label: "Rabat [%]"
    },
    {
      id: "avg_p_netto",
      numeric: true,
      disablePadding: false,
      label: "Średnia cena netto [PLN]"
    },
    {
      id: "avg_p_brutto",
      numeric: true,
      disablePadding: false,
      label: "Średnia cena brutto [PLN]"
    },
    {
      id: "p_netto",
      numeric: true,
      disablePadding: false,
      label: "Łączna cena netto [PLN]"
    },
    {
      id: "p_brutto",
      numeric: true,
      disablePadding: false,
      label: "Łączna cena brutto [PLN]"
    }
  ];

  function createData(
    fullDate,
    total,
    sell,
    returned,
    rabat,
    avg_p_netto,
    avg_p_brutto,
    p_netto,
    p_brutto
  ) {
    return {
      fullDate,
      total,
      sell,
      returned,
      rabat,
      avg_p_netto,
      avg_p_brutto,
      p_netto,
      p_brutto
    };
  }

  const round = (n, k) => {
    const factor = Math.pow(10, k + 1);
    n = Math.round(Math.round(n * factor) / 10);
    return n / (factor / 10);
  };

  const rows = wartosci.map(it =>
    createData(
      it.fullDate,
      it.qtyTotal,
      it.qtySell,
      it.qtyReturned,
      round(it.percentRabat, 2),
      round(it.avgPriceNetto, 2),
      round(it.avgPriceBrutto, 2),
      round(it.priceNetto, 2),
      round(it.priceBrutto, 2)
    )
  );

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <TablePlaceholder />
      ) : (
        <TableComponent
          headCellsData={headers}
          rowsData={rows}
        ></TableComponent>
      )}
    </Grid>
  );
};
